import { FirebaseOptions } from 'firebase/app';
import { EnvSchemaTypes, env } from './env';

// The API Key here is ok to be included as plaintext.
// This key is automatically restricted to Firebase-related APIs
// https://firebase.google.com/docs/projects/api-keys
const firebaseSettings: Record<EnvSchemaTypes['REACT_APP_NODE_ENV'], FirebaseOptions> = {
  development: {
    apiKey: 'AIzaSyDqpv95m5r2Ssxc3l2IqTfDOfBmwC1W7X0',
    authDomain: 'localhost:8087',
    projectId: 'demo-project-id',
    storageBucket: 'rye-api-360318.appspot.com',
    messagingSenderId: '264758355089',
    appId: '1:264758355089:web:139fbe7615a97c91b30b30',
    measurementId: 'G-DG6QY2MJ5F',
  },
  staging: {
    apiKey: 'AIzaSyDqpv95m5r2Ssxc3l2IqTfDOfBmwC1W7X0',
    authDomain: 'test-playground-360318.firebaseapp.com',
    projectId: 'test-playground-360318',
    storageBucket: 'test-playground-360318.appspot.com',
    messagingSenderId: '208268837356',
    appId: '1:208268837356:web:7a40e178c7c981e84940c9',
  },
  production: {
    apiKey: 'AIzaSyCmqA9zUbsV6nr_HybU8N4ArCF-Yk2Zojk',
    authDomain: 'rye-api-360318.firebaseapp.com',
    projectId: 'rye-api-360318',
    storageBucket: 'rye-api-360318.appspot.com',
    messagingSenderId: '264758355089',
    appId: '1:264758355089:web:139fbe7615a97c91b30b30',
    measurementId: 'G-DG6QY2MJ5F',
  },
};

export const firebaseConfig = firebaseSettings[env.REACT_APP_NODE_ENV];
