import { useState } from 'react';
import { useMutation } from '@apollo/client';

import { REQUEST_PRODUCT_BY_URL, getRequestProductionByURLInput } from 'app/graphql/cart-api';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  fetchInventoryRequests,
  fetchProductRequestCount,
  selectInventoryProductLoading,
  toggleProductLoading,
} from 'app/store/inventoryRequests';
import FormRequestBlock from 'components/FormRequestBlock';
import { INPUT_PATTERN, trackSegmentEvent } from 'app/utils';
import { ACTION, SOURCE } from 'app/analytics';
import { selectUserInfo } from 'app/store/user';

const DEFAULT_INPUT_VALUE = '';

const ProductUrlRequest = () => {
  const dispatch = useAppDispatch();
  const formLoading = useAppSelector(selectInventoryProductLoading);
  const { isReadOnly } = useAppSelector(selectUserInfo) || {};

  const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUE);
  const [productRequestError, setProductRequestError] = useState<string>('');

  const [mutateFunction] = useMutation(REQUEST_PRODUCT_BY_URL);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (isReadOnly) return;

    const variables = getRequestProductionByURLInput(inputValue);

    if (variables) {
      dispatch(toggleProductLoading(true));

      // Actually send the request
      try {
        await mutateFunction({ variables });
      } catch (e: unknown) {
        if (e instanceof Error) {
          // Show a specific error for when users reach their limit
          if (e.message.includes('requestProductByURL limit reached')) {
            // Use local state because redux is overkill for this
            setProductRequestError(
              "You've reached your limit for requesting products. Please reach out to dev@rye.com for more information and to increase your limit.",
            );
          } else {
            setProductRequestError(
              'Something went wrong, please try again. Please reach out to dev@rye.com if the issue persists.',
            );
          }
        }
      }

      dispatch(fetchInventoryRequests());
      dispatch(fetchProductRequestCount());
      dispatch(toggleProductLoading(false));
      setInputValue(DEFAULT_INPUT_VALUE);
    } else {
      // @TODO add notification UI
      alert('Please provide correct product URL');
    }
    trackSegmentEvent(SOURCE.REQUEST_PRODUCT_URL_MODULE, ACTION.CLICK, 'request_button', {
      product_url: inputValue,
    });
  };

  return (
    <FormRequestBlock
      title="Add product URL"
      submitValue="Request"
      placeholder="Enter product URL"
      disabled={isReadOnly}
      text={<p>Enter an link to the product that you want to request access</p>}
      loading={formLoading}
      required={true}
      type="url"
      pattern={INPUT_PATTERN.URL}
      inputValue={inputValue}
      onChange={setInputValue}
      onSubmit={handleSubmit}
      error={productRequestError}
    />
  );
};

export default ProductUrlRequest;
