import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';

import { FirebaseRequestedAt } from 'app/firebase/collections/inventoryRequests';
import { UserModel } from 'app/firebase/collections/users';
import { ACTION, SOURCE } from './analytics';
import config, { API_KEY_PREFIX, IFRAME_HEADERS } from 'config/constants';
import { Marketplace } from './graphql/cart-api/types';

export const INPUT_PATTERN = {
  URL: '[Hh][Tt][Tt][Pp][Ss]?://.{1,}\\..{1,}',
  PERCENTS: '^[1-9][0-9]?$|^100$',
  DIGITS: '[d]',
};

export const generateApiKey = (): string => {
  return `${API_KEY_PREFIX}-${uuidv4().replaceAll('-', '').slice(0, 20)}`;
};

export const generateAuthApiToken = (apiKey: string): string => {
  return `Basic ${Buffer.from(apiKey + ':').toString('base64')}`;
};

export const generateAdminToken = (userToken: string): string => {
  return `Bearer ${userToken}`;
};

const generateSpreedlyToken = (envToken: string, secret: string): string => {
  return `Basic ${Buffer.from(`${envToken}:${secret}`).toString('base64')}`;
};

export const convertFirebaseDateToUTC = ({ seconds, nanoseconds }: FirebaseRequestedAt): number => {
  return new Date(seconds * 1000 + nanoseconds / 1000000).getTime();
};

export const localDateFormatter = (date: number): string => {
  const d = new Date(date);
  return d.toLocaleDateString();
};

export const localTimeFormatter = (date: number): string => {
  const d = new Date(date);
  return d.toLocaleTimeString();
};

export const identifyURLMarketplace = (url: string): Marketplace => {
  const _url = url.toLocaleLowerCase();
  return _url.includes('amazon') ? Marketplace.AMAZON : Marketplace.SHOPIFY;
};

export const trackHeapIdentifyEvent = (userInfoModel: UserModel) => {
  /* @ts-ignore */
  window?.heap?.identify(userInfoModel.email);
  /* @ts-ignore */
  window?.heap?.addUserProperties({
    ...userInfoModel,
  });
};

export const trackSegmentIdentifyEvent = (userInfoModel: UserModel) => {
  /* @ts-ignore */
  window?.analytics?.identify(userInfoModel.uid, {
    email: userInfoModel.email,
  });
};

export const trackSegmentPageEvent = (pageName: string) => {
  /* @ts-ignore */
  window?.analytics?.page(pageName);
};

export const trackSegmentEvent = (
  source: SOURCE,
  action: ACTION,
  noun: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...rest: { [key: string]: any }[]
) => {
  /* @ts-ignore */
  window?.analytics?.track(
    [source.valueOf(), action.valueOf(), noun].join('_'),
    filterUndefinedProps({
      ...rest,
      source: source.valueOf(),
      action: action.valueOf(),
      noun,
    }),
  );
};

export const filterUndefinedProps = (obj: object) => {
  return Object.keys(obj).reduce((result, key) => {
    const propKey = key as keyof typeof obj;
    if (obj[propKey] !== undefined && obj[propKey] !== null) {
      result[propKey] = obj[propKey];
    }
    return result;
  }, {});
};

export const getGraphQLQueryHeader = (apiKey: string, clientIp: string) => {
  const headers = {
    [IFRAME_HEADERS.AUTHORIZATION]: generateAuthApiToken(apiKey),
    [IFRAME_HEADERS.RYE_SHOPPER_IP]: clientIp,
  };

  return JSON.stringify(headers);
};

export const getSpreedlyHeader = (envToken: string, secret: string) => {
  const headers = {
    [IFRAME_HEADERS.AUTHORIZATION]: generateSpreedlyToken(envToken, secret),
  };

  return JSON.stringify(headers);
};

export const getClientIp = async (): Promise<string | undefined> => {
  try {
    const response = await fetch(`${config.external.ipService}?format=json`);
    if (!response.ok) {
      return undefined;
    }
    const ipObj: { ip?: string } = await response.json();
    return ipObj?.ip;
  } catch (err) {
    return undefined;
  }
};

const getGraphiQLHeadersImpl = async (apiKey: string): Promise<string> => {
  const clientIp = await getClientIp();
  const headersObj: { [key in IFRAME_HEADERS]?: string } = {
    [IFRAME_HEADERS.AUTHORIZATION]: generateAuthApiToken(apiKey),
    [IFRAME_HEADERS.RYE_DEBUG]: 'true',
    ...(clientIp && { [IFRAME_HEADERS.RYE_SHOPPER_IP]: clientIp }),
  };
  const jsonHeaders = JSON.stringify(headersObj, null, 2);
  return jsonHeaders;
};
export const getGraphiQLHeaders = _.memoize(getGraphiQLHeadersImpl);

// Generate a random secret key of length 16 bytes (128 bits)
// This will be used for HMAC signing for webhooks
export function generateHmacSecretKey() {
  return uuidv4().replaceAll('-', '');
}
