import { cloneElement, useEffect, useMemo, useState } from 'react';
// @ts-expect-error
import commandScore from 'command-score';
import countryLookup from 'country-code-lookup';
import { FieldErrors, useForm } from 'react-hook-form';
import { iso31662 } from 'iso-3166';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Check, Loader2, Lock } from 'lucide-react';

import { AccordionSingleProps } from '@radix-ui/react-accordion';

import { BuyerIdentityInput, CartErrorCode, Country } from 'app/graphql/generated/cart/graphql';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';

import { cn } from 'lib/utils';
import { Accordion, AccordionContent, AccordionItem } from 'components/ui/accordion';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from 'components/ui/card';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Separator } from 'components/ui/separator';

import { Cart } from '../graphql';
import { CartActions, UseCartErrors } from '../hooks/useCart';
import { Merchandise } from '../hooks/useProductImporter';

import { Combobox } from './Combobox';
import { Link } from 'react-router-dom';
import { Routes } from 'config/constants';

const COUNTRIES = countryLookup.countries.flatMap((it) => {
  const enumValue = Object.values(Country).find((countryEnumValue) => countryEnumValue === it.iso2);
  if (!enumValue) {
    return [];
  }

  return {
    label: it.country,
    value: enumValue,
  };
});

const formSchema = z.object({
  firstName: z.string().min(1, { message: 'Required' }),
  lastName: z.string().min(1, { message: 'Required' }),
  country: z.union([z.literal(''), z.nativeEnum(Country)]).refine((it) => it !== '', {
    message: 'Required',
  }),
  address1: z.string().min(5, { message: 'Invalid address' }).max(200, { message: 'Too long' }),
  address2: z.string().max(50, { message: 'Too long' }),
  city: z.string().min(2, { message: 'Invalid city' }),
  province: z.string(),
  postalCode: z.string(),
});
type FormValues = z.infer<typeof formSchema>;

interface ShippingDetailsFormProps {
  actions: CartActions;
  cart: Cart | null;
  errors: UseCartErrors;
  merchandise: Merchandise | null;

  /** Indicates whether the merchandise is currently being imported */
  isImporting: boolean;
  isSubmitting: boolean;
  isUpdating: boolean;
}

function mapFormToBuyerIdentity(
  email: string | null,
  values: FormValues,
): BuyerIdentityInput | null {
  if (!values.country) {
    return null;
  }
  return {
    address1: values.address1.length > 5 ? values.address1 : null,
    address2: values.address2,
    city: values.city.length > 2 ? values.city : null,
    email,
    firstName: values.firstName.length > 1 ? values.firstName : null,
    lastName: values.lastName.length > 1 ? values.lastName : null,
    postalCode: values.postalCode,
    provinceCode: tryMapProvinceToISO(values.country, values.province),
    countryCode: values.country,

    // TODO
    phone: '+1 (775) 634-4937',
  };
}

function mapCartErrorsToForm(cartErrors: UseCartErrors): FieldErrors<FormValues> {
  const errors: FieldErrors<FormValues> = {};
  for (const error of cartErrors) {
    if ('code' in error) {
      switch (error.code) {
        case CartErrorCode.BuyerIdentityInvalidPostalCode:
          errors['postalCode'] = { type: 'invalid', message: error.message };
          break;

        case CartErrorCode.BuyerIdentityInvalidCity:
          errors['city'] = { type: 'invalid', message: error.message };
          break;
        case CartErrorCode.BuyerIdentityInvalidCountry:
          errors['country'] = { type: 'invalid', message: error.message };

          break;

        case CartErrorCode.BuyerIdentityInvalidFirstName:
          errors['firstName'] = { type: 'invalid', message: error.message };
          break;

        case CartErrorCode.BuyerIdentityInvalidLastName:
          errors['lastName'] = { type: 'invalid', message: error.message };
          break;

        case CartErrorCode.BuyerIdentityInvalidProvince:
          errors['postalCode'] = { type: 'invalid', message: error.message };
          break;

        case CartErrorCode.BuyerIdentityInvalidAddress:
        case CartErrorCode.BuyerIdentityAddressAmbiguous:
          errors['address1'] = { type: 'invalid', message: error.message };
          break;
      }
    } else {
      // TODO GraphQLErrors
    }
  }

  return errors;
}

// Cart API expects an ISO 3166-2 code for the province; so do our best to try map
// the user-provided province to a province code.
function tryMapProvinceToISO(country: Country | '', province: string): string {
  const availableProvinces = iso31662.filter((it) => it.code.startsWith(country));

  let bestProvinceMatch;
  let bestScore = 0;
  for (const availableProvince of availableProvinces) {
    const score: number = commandScore(availableProvince.name, province);
    if (!bestProvinceMatch || score > bestScore) {
      bestProvinceMatch = availableProvince.code;
      bestScore = score;
    }
  }

  if (bestProvinceMatch) {
    const parts = bestProvinceMatch.split('-');
    // US-CA => CA
    if (parts.length > 1) {
      return parts[1];
    }

    return bestProvinceMatch;
  }

  return province;
}

export function ShippingDetailsForm({
  actions,
  cart,
  errors,
  merchandise,
  isImporting,
  isSubmitting,
  isUpdating,
}: ShippingDetailsFormProps) {
  const { email = '' } = useAppSelector(selectUserInfo) || {};
  const [orderId, setOrderId] = useState<string | null>(null);

  const form = useForm<FormValues>({
    errors: useMemo(() => mapCartErrorsToForm(errors), [errors]),
    resolver: zodResolver(formSchema),

    defaultValues: {
      firstName: '',
      lastName: '',
      country: undefined,
      address1: '',
      address2: '',
      city: '',
      province: '',
    },
  });

  function handleBlur(values: FormValues) {
    const buyerIdentity = mapFormToBuyerIdentity(email, values);
    if (buyerIdentity) {
      actions.updateCartBuyerIdentity(buyerIdentity);
    }
  }

  function reset() {
    // Just refresh the page for expediency
    window.location.reload();
  }

  return (
    <Card className="flex h-full flex-col shadow-xl">
      <Form {...form}>
        <form
          className="flex flex-1 flex-col"
          onBlur={(e) => {
            // Save buyer identity information as it gets entered
            if (e.target.tagName === 'INPUT') {
              handleBlur(form.getValues());
            }
          }}
          onSubmit={form.handleSubmit(async (values) => {
            const buyerIdentity = mapFormToBuyerIdentity(email, values);
            if (!buyerIdentity) {
              form.setError('country', { type: 'required', message: 'Country is required' });
              return;
            }

            const result = await actions.submitCart();
            const orderId = result.data?.submitCart?.cart?.stores?.[0]?.orderId;

            if (
              !result.data ||
              result.errors ||
              result.data?.submitCart?.errors.length ||
              !orderId
            ) {
              alert('Something went wrong while ordering. Please try again.');
            } else {
              setOrderId(orderId);
            }
          })}
        >
          <CardHeader>
            <CardTitle>Complete your order</CardTitle>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="grid grid-cols-2 gap-x-2 gap-y-4">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem className="col-span-1">
                    <FormLabel>First Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Michael" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem className="col-span-1">
                    <FormLabel>Last Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Ross" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Country</FormLabel>
                    <FormControl>
                      <Combobox
                        autoComplete="country country-code"
                        options={COUNTRIES.filter((it) =>
                          cart && cart.stores[0].shipsToCountries
                            ? cart.stores[0].shipsToCountries.includes(it.value)
                            : true,
                        )}
                        placeholder="United States"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="address1"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Street Address</FormLabel>
                    <FormControl>
                      <Input placeholder="123 Main St" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="address2"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Apartment, Suite, Etc.</FormLabel>
                    <FormControl>
                      <Input placeholder="Suite 1" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem className="col-span-1">
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <Input placeholder="New York" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="province"
                render={({ field }) => (
                  <FormItem className="col-span-1">
                    <FormLabel>State</FormLabel>
                    <FormControl>
                      <Input placeholder="New York" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="postalCode"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>ZIP / Postal Code</FormLabel>
                    <FormControl>
                      <Input placeholder="10036" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <Separator />

            <AccordionSpring
              type="single"
              value={cart ? 'cost' : undefined}
              collapsible
              className="w-full"
            >
              <AccordionItem value="cost">
                <AccordionContent>
                  {cart && (
                    <div className="flex flex-col gap-1">
                      <PriceLine className="font-bold" label="Total">
                        {renderPriceLine(cart, 'total')}
                      </PriceLine>
                      <PriceLine label="Product price">
                        {renderPriceLine(cart, 'subtotal', merchandise?.price?.displayValue)}
                      </PriceLine>
                      <PriceLine label="Delivery cost">
                        {renderPriceLine(cart, 'shipping')}
                      </PriceLine>
                      <PriceLine label="Taxes">{renderPriceLine(cart, 'tax')}</PriceLine>
                    </div>
                  )}
                </AccordionContent>
              </AccordionItem>
            </AccordionSpring>
          </CardContent>

          <div className="flex-1" />

          <CardFooter className="pb-4">
            <SubmitButton
              hasMerchandise={Boolean(merchandise)}
              isAvailable={merchandise?.isAvailable ?? true}
              isImporting={isImporting}
              isSubmitting={isSubmitting}
              isUpdatingTotal={isUpdating}
            />
          </CardFooter>
        </form>
      </Form>

      <AlertDialog open={Boolean(orderId)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="flex gap-2">
              Ordered! <Check />
            </AlertDialogTitle>
            <AlertDialogDescription>
              {form.watch('firstName')}&apos;s item is on the way!
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                reset();
              }}
            >
              Close
            </AlertDialogCancel>
            <AlertDialogAction asChild>
              <Link to={`${Routes.Orders}/${orderId}`}>View order</Link>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Card>
  );
}

function AccordionSpring({ value, ...props }: AccordionSingleProps) {
  const [appliedValue, setAppliedValue] = useState(value);
  useEffect(() => {
    setAppliedValue(value);
  }, [value]);
  return <Accordion {...props} value={appliedValue} />;
}

function renderPriceLine(
  cart: Cart,
  key: 'tax' | 'shipping' | 'subtotal' | 'total',
  fallback?: string,
): string {
  const displayValue = cart.cost?.[key]?.displayValue ?? fallback;

  if (!displayValue) {
    if (!cart.cost) {
      return 'Unavailable';
    }
    return 'Pending';
  }

  return displayValue;
}

interface PriceLineProps {
  children: string;
  className?: string;
  label: string;
}

function PriceLine({ children, className, label }: PriceLineProps) {
  return (
    <div className={cn('flex justify-between', className)}>
      <span>{label}</span>
      <span>{children}</span>
    </div>
  );
}

function SubmitButton({
  hasMerchandise,
  isAvailable,
  isImporting,
  isSubmitting,
  isUpdatingTotal,
}: {
  hasMerchandise: boolean;
  isAvailable: boolean;
  isImporting: boolean;
  isSubmitting: boolean;
  isUpdatingTotal: boolean;
}) {
  const icon =
    isUpdatingTotal || isSubmitting || isImporting ? (
      <Loader2 className="animate-spin" />
    ) : !isAvailable || !hasMerchandise ? (
      <Lock />
    ) : null;
  const label = isImporting
    ? 'Importing...'
    : hasMerchandise
    ? isAvailable
      ? isUpdatingTotal
        ? 'Updating Total...'
        : 'Order Now'
      : 'Out of Stock'
    : 'Import a product to checkout';

  return (
    <Button
      className="w-full"
      disabled={!hasMerchandise || isUpdatingTotal || isSubmitting || !isAvailable}
      size="lg"
      type="submit"
    >
      {icon && cloneElement(icon, { className: cn(icon.props.className, 'mr-2 h-4 w-4') })}
      {label}
    </Button>
  );
}
