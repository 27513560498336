/* eslint-disable @typescript-eslint/no-explicit-any */
import { AmazonBusinessType, AmazonToken } from '@rye-com/rye-data-layer';
import { CollectionId, FirebaseCollection } from '..';
import { UserModel } from './users';
import { where as firebaseWhere } from 'firebase/firestore';

// TODO: get this type from rye-data-layer
export type AmazonBusinessAccount = {
  token?: AmazonToken;
  businessType?: AmazonBusinessType;
  groupId?: string;
  email: string;
  pendingGroupAccess?: boolean;
  parentAmazonAccountEmail?: string;
};

class AmazonBusinessAccountsCollection extends FirebaseCollection {
  constructor() {
    super(CollectionId.Users);
  }

  fetchDefaultAmazonBusinessAccountByUid = async (userUid: string) => {
    const condition = firebaseWhere('uid', '==', userUid);
    const documents = await this.list<UserModel>({ condition });
    const documentsData = documents[0];

    return documentsData?.data?.amazonBusinessConfig;
  };

  fetchActiveAmazonBusinessAccountEmailIdByUid = async (userUid: string) => {
    const condition = firebaseWhere('uid', '==', userUid);
    const documents = await this.list<UserModel>({ condition });
    const documentsData = documents[0];

    // TODO: remove the any type once data layer is updated
    return (documentsData?.data?.amazonBusinessConfig as any)?.activeAmazonBusinessEmailId;
  };

  fetchAmazonBusinessAccountsByUid = async (userUid: string) => {
    const resp = await this.list<AmazonBusinessAccount>({
      collectionIdAddon: `/${userUid}/amazonBusinessAccounts`,
    });
    return resp.map((item) => item.data) || [];
  };

  updateActiveAmazonBusinessAccountByUid = async (userUid: string, email: string) => {
    const condition = firebaseWhere('uid', '==', userUid);
    const documents = await this.list<UserModel>({ condition });
    const document = documents[0];
    const amazonBusinessConfig = document.data.amazonBusinessConfig;

    await this.update(condition, {
      amazonBusinessConfig: {
        ...amazonBusinessConfig,
        activeAmazonBusinessEmailId: email,
      },
    });
  };
}

export const amazonBusinessAccountsCollection = new AmazonBusinessAccountsCollection();
