import { ExportColumns } from 'app/graphql/generated/admin/graphql';

export type ColumnMap = { [key in ExportColumns]: string };

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const billingItemsColumns: ColumnMap = {
  DATE: 'Date',
  ORDER_ID: 'Order Id',
  ITEM_NAMES: 'Item Names',
  CURRENCY: 'Currency',
  SUBTOTAL: 'Subtotal',
  SHIPPING: 'Shipping',
  MARGIN: 'Margin',
  DISCOUNT: 'Discount',
  TAX: 'Tax',
  TOTAL: 'Total',
  REFUND: 'Refund Total',
  PLATFORM_FEE: 'Platform Fee',
  FULFILLMENT_FEE: 'Fulfillment Fee',
  STORE_NAME: 'Store Name',
  STORE_URL: 'Store URL',
  THANK_YOU_PAGE: 'Thank you page',
  COMMISSION_STATUS: 'Merchant Commission Status',
  COMMISSION_TOTAL: 'Merchant Commission Total',
  COMMISSION_DEV: 'Merchant Commission Dev',
  COMMISSION_RYE: 'Merchant Commission Rye',
  COMMISSION_PAYER: 'Merchant Commission Payer',
  MARKUP_COMMISSION: 'Markup Commission',
  MARKUP_COMMISSION_DEV: 'Markup Commission Dev',
  MARKUP_COMMISSION_RYE: 'Markup Commission Rye',
  MARKUP_COMMISSION_PAYER: 'Markup Commission Payer',
  AFFILIATE_COMMISSION: 'Affiliate Commission',
  AFFILIATE_COMMISSION_PAYER: 'Affiliate Commission Payer',
  DISCOUNT_COMMISSION: 'Discount Commission',
  DISCOUNT_COMMISSION_DEV: 'Discount Commission Dev',
  DISCOUNT_COMMISSION_RYE: 'Discount Commission Rye',
  DISCOUNT_COMMISSION_PAYER: 'Discount Commission Payer',
  DISCOUNT_CODES: 'Discount Codes',
  CREDIT: 'Credit Issued',
};
