import { RyeAPIDetailsCard } from 'components/Orders/OrderDetailsCards/DetailsCard/ryeAPIDetailsCard';
import { StatusDetailsCard } from 'components/Orders/OrderDetailsCards/DetailsCard/statusDetailsCard';
import { PriceDetailsCard } from 'components/Orders/OrderDetailsCards/DetailsCard/priceDetailsCard';
import type {
  AmazonCheckoutRequestFirestore,
  CheckoutRequestFirestore,
} from '@rye-com/rye-data-layer';
import { isPermissionlessOrderDetails } from '@rye-com/rye-data-layer/dist/checkoutRequests/shopifyCheckoutRequest';
import './styles.scss';
import { getOrderStatus } from 'utils/orderStatus';
import { ShippingInfoDetailsCard } from 'components/Orders/OrderDetailsCards/DetailsCard/shippingInfoDetailsCard';
import { getFormattedAddress } from 'utils/formatters';
import { ShopifyItemCatalogCardTable } from './ItemCatalogCard/shopifyItemCatalogCardTable';

export type ShopifyCheckoutRequest = Exclude<
  CheckoutRequestFirestore,
  AmazonCheckoutRequestFirestore
>;

interface ShopifyOrderDetailsCardTableProps {
  orderDetails: ShopifyCheckoutRequest & {
    createdAtUTS: number;
  };
}

export const ShopifyOrderDetailsCardTable = ({
  orderDetails,
}: ShopifyOrderDetailsCardTableProps) => {
  return (
    <div className="card-table-layout">
      <div>
        <StatusDetailsCard
          orderStatus={getOrderStatus(orderDetails)}
          orderTracking={orderDetails.order?.fulfillments?.flatMap((x) => x.trackingDetails) ?? []}
        />
        <ShippingInfoDetailsCard
          name={`${orderDetails.request.shippingAddress.firstName} ${orderDetails.request.shippingAddress.lastName}`}
          streetAddress={`${orderDetails.request.shippingAddress.address1}, ${orderDetails.request.shippingAddress.address2}`}
          location={`${getFormattedAddress(
            orderDetails.request.shippingAddress.city,
            orderDetails.request.shippingAddress.province,
          )}`}
          country={orderDetails.request.shippingAddress.country}
        />
        <RyeAPIDetailsCard
          createdOn={`${new Date(orderDetails.createdAtUTS).toLocaleString()}`}
          orderName={
            orderDetails.order && isPermissionlessOrderDetails(orderDetails.order)
              ? orderDetails.order.orderName
              : 'N/A'
          }
          location={`${getFormattedAddress(
            orderDetails.request.shippingAddress.province,
            orderDetails.request.shippingAddress.country,
          )}`}
          marketplace={orderDetails.marketplace}
        />
      </div>
      <div className="card-table-price-details">
        <ShopifyItemCatalogCardTable products={orderDetails.request.products ?? []} />
        {orderDetails.order?.totalCents && (
          <PriceDetailsCard
            shippingCents={orderDetails.order.shippingCents}
            subtotalCents={orderDetails.order.subtotalCents}
            taxCents={orderDetails.order.taxCents}
            totalCents={orderDetails.order.totalCents}
            currency={orderDetails.order.currency}
          />
        )}
      </div>
    </div>
  );
};
