import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import type { RootState, AppThunk } from 'app/store';
import {
  inventoryRequestsCollection,
  InventoryRequestsStoreModel,
  InventoryRequestsModel,
} from 'app/firebase/collections/inventoryRequests';
import { convertFirebaseDateToUTC } from 'app/utils';

/**
 * Reducers
 */
export interface Pagination {
  selected: number;
  total: number;
}

type InventoryRequestsState = {
  loadingHistory: boolean;
  loadingProduct: boolean;
  loadingShop: boolean;
  list: InventoryRequestsStoreModel[];
  storeRequestCount?: number;
  productRequestCount?: number;
  page: Pagination;
};

export const ITEMS_PER_PAGE = 10;
const REQUEST_ITEMS_LIMIT_IN_STORE = 1000;

const initialState: InventoryRequestsState = {
  loadingHistory: false,
  loadingProduct: false,
  loadingShop: false,
  list: [],
  page: {
    selected: 0,
    total: 0,
  },
};

const inventoryRequestsSlice = createSlice({
  name: 'inventoryRequests',
  initialState,
  reducers: {
    clean: (): InventoryRequestsState => {
      return { ...initialState };
    },
    loadHistory: (state, action: PayloadAction<boolean>): InventoryRequestsState => {
      return {
        ...state,
        loadingHistory: action.payload,
      };
    },
    loadProduct: (state, action: PayloadAction<boolean>): InventoryRequestsState => {
      return {
        ...state,
        loadingProduct: action.payload,
      };
    },
    loadShop: (state, action: PayloadAction<boolean>): InventoryRequestsState => {
      return {
        ...state,
        loadingShop: action.payload,
      };
    },
    setHistory: (
      state,
      action: PayloadAction<InventoryRequestsStoreModel[]>,
    ): InventoryRequestsState => {
      const list = [...action.payload].slice(0, REQUEST_ITEMS_LIMIT_IN_STORE);

      return {
        ...state,
        loadingHistory: false,
        loadingProduct: false,
        loadingShop: false,
        list,
        page: {
          ...state.page,
          total: Math.ceil(list.length / ITEMS_PER_PAGE),
        },
      };
    },
    setStoreRequestCount: (state, action: PayloadAction<number>): InventoryRequestsState => {
      const count = action.payload;

      return {
        ...state,
        storeRequestCount: count,
      };
    },
    setProductRequestCount: (state, action: PayloadAction<number>): InventoryRequestsState => {
      const count = action.payload;

      return {
        ...state,
        productRequestCount: count,
      };
    },
    setPage: (state, action: PayloadAction<number>): InventoryRequestsState => {
      const selected = action.payload;
      return {
        ...state,
        page: {
          ...state.page,
          selected,
        },
      };
    },
  },
});

/**
 * Actions
 */
const {
  clean,
  loadProduct,
  loadShop,
  loadHistory,
  setHistory,
  setPage,
  setStoreRequestCount,
  setProductRequestCount,
} = inventoryRequestsSlice.actions;

export const cleanInventoryRequests = clean;

export const fetchInventoryRequests =
  (uid?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const userUid = uid || state.user.uid;
    const userId = state.user.documentId;

    if (userId && userUid) {
      !state.inventoryRequests.loadingHistory && dispatch(loadHistory(true));
      const listById = await inventoryRequestsCollection.fetchHistoryByUid(userId);
      const listByUid = await inventoryRequestsCollection.fetchHistoryByUid(userUid);
      const resultList = [...listById, ...listByUid].map(convertToStoreModel);
      const uniqList = uniqBy(resultList, (e) => e.id);
      const sortedList = uniqList.sort((a, b) => b.requestedAtUTS - a.requestedAtUTS);
      dispatch(setHistory(sortedList));
    }
  };

export const fetchStoreRequestCount =
  (uid?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const userUid = uid || state.user.uid;
    const userId = state.user.documentId;
    if (userId && userUid) {
      const count = await inventoryRequestsCollection.getStoreRequestsCount(userUid);
      dispatch(setStoreRequestCount(count));
    }
  };

export const fetchProductRequestCount =
  (uid?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const userUid = uid || state.user.uid;
    const userId = state.user.documentId;
    if (userId && userUid) {
      const count = await inventoryRequestsCollection.getProductRequestsCount(userUid);
      dispatch(setProductRequestCount(count));
    }
  };

export const toggleProductLoading =
  (status: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { loadingHistory, loadingProduct } = state.inventoryRequests;
    loadingHistory !== status && dispatch(loadHistory(status));
    loadingProduct !== status && dispatch(loadProduct(status));
  };

export const toggleShopLoading =
  (status: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { loadingHistory, loadingShop } = state.inventoryRequests;
    loadingHistory !== status && dispatch(loadHistory(status));
    loadingShop !== status && dispatch(loadShop(status));
  };

export const selectPage =
  (pageNumber: number): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { total, selected } = state.inventoryRequests.page;
    if (pageNumber !== selected && pageNumber >= 0 && pageNumber <= total) {
      dispatch(setPage(pageNumber));
    }
  };

/**
 * Selectors
 */
export const selectInventoryRequests = (state: RootState) => state.inventoryRequests.list;
export const selectStoreRequestCount = (state: RootState) =>
  state.inventoryRequests.storeRequestCount;
export const selectProductRequestCount = (state: RootState) =>
  state.inventoryRequests.productRequestCount;
export const selectInventoryRequestsLoading = (state: RootState) =>
  state.inventoryRequests.loadingHistory;
export const selectInventoryProductLoading = (state: RootState) =>
  state.inventoryRequests.loadingProduct;
export const selectInventoryShopLoading = (state: RootState) => state.inventoryRequests.loadingShop;
export const selectPageInfo = (state: RootState) => state.inventoryRequests.page;

export default inventoryRequestsSlice.reducer;

/**
 * Utils
 */
const convertToStoreModel = (item: InventoryRequestsModel): InventoryRequestsStoreModel => ({
  ...item,
  requestedAt: {
    seconds: item.requestedAt.seconds,
    nanoseconds: item.requestedAt.nanoseconds,
  },
  requestedAtUTS: convertFirebaseDateToUTC(item.requestedAt),
});
