import { useEffect, useState } from 'react';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import FormRequestBlock from 'components/FormRequestBlock';
import { useAppSelector } from 'app/store';
import { selectUserInfo, selectUserInfoLoading } from 'app/store/user';
import { getGraphQLQueryHeader, trackSegmentEvent } from 'app/utils';
import { selectClientIp } from 'app/store/clientIp';
import config from 'config/constants';
import FormInput from 'components/FormInput';

import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import './style.scss';
import { ACTION, SOURCE } from 'app/analytics';
import { env } from 'config/env';
import { ENV_TITLE_CASE } from 'config/constants';

const ApiKey = () => {
  const { apiKey = '' } = useAppSelector(selectUserInfo) || {};
  const isUserInfoLoading = useAppSelector(selectUserInfoLoading);
  const clientIp = useAppSelector(selectClientIp) || '<< Insert IP address here >>';
  const [formLoading, setFormLoading] = useState(!apiKey);
  const [isCopied, setIsCopied] = useState(false);
  const graphQLQueryHeader = getGraphQLQueryHeader(apiKey, clientIp);

  const handleOnClickCopy = () => {
    navigator.clipboard.writeText(graphQLQueryHeader);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
    trackSegmentEvent(SOURCE.API_KEY_MODULE, ACTION.CLICK, 'copy_api_key_button');
  };

  const handleOnSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    setFormLoading(!apiKey && isUserInfoLoading);
  }, [apiKey, isUserInfoLoading]);

  return (
    <FormRequestBlock
      title={`${ENV_TITLE_CASE} API Key Headers`}
      text={
        <>
          <p>
            These headers are required for authenticating GraphQL requests to Rye's {ENV_TITLE_CASE}{' '}
            API.
          </p>
          <p>
            To learn how to get started, check out the{' '}
            <a href={config.external.doc.quickStart} target="_blank" rel="noreferrer noopener">
              Quick Start Guide
            </a>
            , or the guide on how to{' '}
            <a
              href="https://docs.rye.com/place-test-orders"
              target="_blank"
              rel="noreferrer noopener"
            >
              place test orders{env.REACT_APP_NODE_ENV === 'production' ? ' in Staging' : ''}
            </a>
            .
          </p>
          <p>
            The GraphQL endpoint for the {env.REACT_APP_NODE_ENV} environment is:{' '}
            <code>{config.external.gql}</code>.
          </p>
        </>
      }
      loading={formLoading}
      onSubmit={handleOnSubmit}
    >
      <div className="api-key-form">
        <KeyIcon className="api-key-icon" title="API Key Header" />
        <FormInput
          id="heap-input-api-key"
          className="api-key-input"
          type="text"
          readOnly={true}
          value={graphQLQueryHeader}
        />
        <button title="Copy GraphQL header" className="api-key-copy" onClick={handleOnClickCopy}>
          {isCopied ? <CheckIcon /> : <CopyIcon />}
        </button>
      </div>
    </FormRequestBlock>
  );
};

export default ApiKey;
