import './styles.scss';
import { ShipmentStatus } from 'utils/orderStatus';
import type { AmazonOrder, AmazonShipment } from '@rye-com/rye-data-layer';
import { useMemo } from 'react';
import { TrackingInfo } from 'components/Orders/Tracking/TrackingInfo';

interface AmazonStatusDetailsCardValue {
  amazonOrders: AmazonOrder[];
}

export const AmazonShipmentDetailsCard = (props: AmazonStatusDetailsCardValue) => {
  const shipments = useMemo(() => {
    return props.amazonOrders.reduce((acc: AmazonShipment[], curr) => {
      if (curr.shipments) {
        return [...acc, ...curr.shipments];
      }
      return acc;
    }, []);
  }, [props.amazonOrders]);

  const getShipmentStatus = (shipment: AmazonShipment) => {
    let status: string | null = shipment.shipmentStatus;
    if (shipment.deliveryInformation.deliveryStatus === 'DELIVERED') {
      status = shipment.deliveryInformation.deliveryStatus;
    }

    switch (status) {
      case 'DELIVERED':
        return ShipmentStatus.Delivered;
      case 'SHIPPED':
        return ShipmentStatus.Shipped;
      default:
        return ShipmentStatus.NotShipped;
    }
  };

  const getShipmentStatusClass = (status: ShipmentStatus) => {
    switch (status) {
      case 'Delivered':
        return 'success';
      case 'Shipped':
        return 'neutral';
      default:
        return 'failed';
    }
  };

  return (
    <table className="shipment-table">
      <tbody>
        <tr className="shipment-header-row">
          <th>Shipments</th>
          <th>Tracking number</th>
          <th>Shipment status</th>
        </tr>
        {shipments.map((shipment, index) => {
          const shipmentStatus = getShipmentStatus(shipment);
          return (
            <tr key={shipment.carrierTracking ?? '' + index}>
              <td>Shipment {index + 1}</td>
              <td>
                <TrackingInfo
                  tracking={{
                    trackingNumber: shipment.carrierTracking ?? 'Not Available',
                    courierUrl: shipment.carrierTrackingUrl,
                    courierName: shipment.carrierName,
                  }}
                />
              </td>
              <td>
                <span className={`status-label ${getShipmentStatusClass(shipmentStatus)}`}>
                  {shipmentStatus}
                </span>
              </td>
            </tr>
          );
        })}
        {shipments.length === 0 && (
          <tr>
            <td className="no-shipments">No Shipments</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
