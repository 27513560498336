import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { generateAdminToken, generateAuthApiToken, getClientIp } from 'app/utils';
import config, { IFRAME_HEADERS } from 'config/constants';

const httpLink = createHttpLink({
  uri: config.external.gql,
});

const adminHttpLink = createHttpLink({
  uri: config.external.adminGql,
});

class GQLClient {
  authToken: string | undefined | null;
  userToken: string | undefined | null;

  #client: ApolloClient<NormalizedCacheObject>;
  #clientIp: string | null;

  constructor() {
    this.authToken = null;
    this.userToken = null;

    this.#client = this.set();
    this.#clientIp = null;
  }

  get() {
    return this.#client;
  }

  set(apiKey?: string | null, userToken?: string | null) {
    this.authToken = apiKey ? generateAuthApiToken(apiKey) : null;
    this.userToken = userToken ? generateAdminToken(userToken) : null;

    const authLink = setContext(async (_, { headers }) => {
      const clientIp = await this.#getClientIp();

      return {
        headers: {
          ...headers,
          authorization: this.authToken || 'undefined',
          [IFRAME_HEADERS.RYE_SHOPPER_IP]: clientIp,
        },
      };
    });
    const authAdminLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: this.userToken || 'undefined',
        },
      };
    });

    const client = new ApolloClient({
      link: ApolloLink.split(
        (operation) => operation.getContext().clientName === 'admin',
        authAdminLink.concat(adminHttpLink), // if above
        authLink.concat(httpLink),
      ),
      cache: new InMemoryCache(),
    });
    this.#client = client;
    return client;
  }

  reset() {
    if (this.#client) {
      this.#client.resetStore();
    }
  }

  async #getClientIp() {
    if (this.#clientIp) {
      return this.#clientIp;
    }

    const clientIp = await getClientIp();
    if (clientIp) {
      this.#clientIp = clientIp;
      return clientIp;
    }

    return '127.0.0.1';
  }
}

export const graphQLClient = new GQLClient();

const ApolloGQLProvider = ({ children }: { children: JSX.Element }) => {
  return <ApolloProvider client={graphQLClient.get()}>{children}</ApolloProvider>;
};

export default ApolloGQLProvider;
