import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  gap: 10px;
`;

export const MerchantList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(284px, 1fr));
  gap: 16px;
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  margin: 10vw 0;
`;
export const MerchantInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Image = styled.img`
  width: 300px;
`;
