import { useApolloClient } from '@apollo/client';
import type { CheckoutRequestFirestore } from '@rye-com/rye-data-layer';
import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useHypertune } from 'generated/hypertune.react';

import { useAppDispatch, useAppSelector } from 'app/store';
import {
  Pagination,
  CheckoutRequestFirestoreStoreModel,
  selectCheckoutRequests,
  selectCheckoutRequestsLoading,
  selectPageInfo,
  setVendorByProductId,
  selectVendors,
  VendorsByRequestId,
  updateVendorByProductId,
  fetchCheckoutRequests,
} from 'app/store/checkoutRequests';
import { ITEMS_PER_PAGE, selectPage } from 'app/store/checkoutRequests';
import { NoOrdersPage } from 'components/Orders/noOrders';
import PreloaderIcon, { RyeLogoSizeCLass } from 'components/Preloader';
import { Routes } from 'config/constants';
import { getFormattedCurrency } from 'utils/formatters';
import { OrderStatus, getOrderStatus, getOrderStatusLabelColor } from 'utils/orderStatus';
import {
  getMarketplaceProductVendorInputById,
  REQUEST_MARKETPLACE_PRODUCT_VENDOR_BY_ID,
} from 'app/graphql/cart-api';
import { TrackingInfo } from 'components/Orders/Tracking/TrackingInfo';

import './style.scss';
import { selectUserInfo } from 'app/store/user';
import Dropdown from 'components/Dropdown';
import { createWebhook } from 'app/webhooks/util';
import { WebhookType } from 'app/webhooks/types';
import { dispatchWebhook } from 'app/webhooks/dispatchWebhooks';
import FormButton from 'components/FormButton';
import { env } from 'config/env';
import { Button } from 'components/ui/button';

const TABLE_HEADERS = [
  'Order Id',
  'Created on',
  'Brand',
  'Revenue',
  'Total',
  'Tracking',
  'Order Status',
];

if (env.REACT_APP_NODE_ENV !== 'production') {
  TABLE_HEADERS.push('Test webhooks');
}

const getPageInfo = (
  list: CheckoutRequestFirestoreStoreModel[],
  { total, selected }: Pagination,
) => {
  const pages = list.length > ITEMS_PER_PAGE ? Array.from(Array(total).keys()) : [];
  const from = ITEMS_PER_PAGE * selected;
  const to = from + ITEMS_PER_PAGE;
  const items = list.slice(from, to);
  return { pages, items };
};

const getTrackingDetails = (data: CheckoutRequestFirestore) => {
  if (data.marketplace === 'shopify') {
    const firstTrackingInfo = data.order?.fulfillments?.find((x) => x.trackingDetails.length)
      ?.trackingDetails[0];

    if (firstTrackingInfo) {
      return (
        <TrackingInfo
          tracking={{
            trackingNumber: firstTrackingInfo.trackingNumber,
            courierUrl: firstTrackingInfo.courierUrl,
            courierName: firstTrackingInfo.courierName,
          }}
        />
      );
    }
  } else if (
    data.marketplace === 'amazon' &&
    data.order?.amazonOrders &&
    data.order.amazonOrders.length > 0
  ) {
    const courierUrl = data.order.amazonOrders[0]?.shipments?.[0]?.carrierTrackingUrl;
    const trackingNumber = data.order.amazonOrders[0]?.shipments?.[0]?.carrierTracking ?? '';
    const courierName = data.order.amazonOrders[0]?.shipments?.[0]?.carrierName;
    return <TrackingInfo tracking={{ trackingNumber, courierUrl, courierName }} />;
  }

  // If the order has been cancelled, it doesn't make sense to say the tracking info isn't available yet
  const orderStatus = getOrderStatus(data);
  const trackingFallbackMessage =
    orderStatus === OrderStatus.OrderCancelled ? 'N/A' : 'Not yet available';

  return trackingFallbackMessage;
};

export const OrderTable = () => {
  const dispatch = useAppDispatch();
  const hypertune = useHypertune();

  const isRequestsLoading = useAppSelector(selectCheckoutRequestsLoading);
  const list = useAppSelector(selectCheckoutRequests);
  const pageInfo = useAppSelector(selectPageInfo);
  const vendors = useAppSelector(selectVendors);
  const user = useAppSelector(selectUserInfo);

  useEffect(() => {
    dispatch(fetchCheckoutRequests(user?.uid));
  }, [user]);

  const { pages, items } = useMemo(() => getPageInfo(list, pageInfo), [list, pageInfo]);

  const handlePageSelect = (pageNumber: number) => {
    dispatch(selectPage(pageNumber));
  };

  // Populate with vendors info only for requested items
  useRequestVendorsInfo(items, vendors);

  // Show preloading on initial data loading
  if (isRequestsLoading) {
    return (
      <div className="order-preloader">
        <PreloaderIcon />
      </div>
    );
  }

  // Show empty state is no data present
  if (!isRequestsLoading && list.length === 0) {
    return <NoOrdersPage />;
  }

  // Draw table
  return (
    <div className="order-main-div">
      <div className="order-search-header">
        <h1>Your Orders</h1>
        <div className="flex-1" />
        {hypertune.viewCreateOrder({ fallback: false }) && (
          <Button asChild>
            <Link to={Routes.CreateOrder}>Create order</Link>
          </Button>
        )}
      </div>

      <div className="order-table-container">
        <table className="order-table">
          <thead>
            <tr className="order-table-row">
              {TABLE_HEADERS.map((title, index) => (
                <th key={index} className="order-table-header">
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {items.map((row) => (
              <TableTdRow key={row.id + uuidv4()} {...row} vendors={vendors} />
            ))}
          </tbody>
        </table>

        {pages.length ? (
          <div className="table-page-wrap">
            <div className="table-page-scroll">
              <ul className="table-page">
                {pages.map((num) => (
                  <li
                    key={`table-page-${num}`}
                    onClick={() => handlePageSelect(num)}
                    className={
                      num === pageInfo.selected ? 'table-page-item selected' : 'table-page-item'
                    }
                  >
                    {num + 1}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const TableTdRow = (
  props: CheckoutRequestFirestoreStoreModel & { vendors: VendorsByRequestId },
) => {
  const { id: checkoutRequestId, createdAtUTS, order, vendors } = props;
  const orderURL = `${Routes.Orders}/${checkoutRequestId}`;
  const createdAtLocalDate = new Date(createdAtUTS).toLocaleString();
  const currency = order?.currency;

  const subtotal = order?.subtotalCents ? order?.subtotalCents / 100 : null;
  const subtotalFormatted = subtotal && getFormattedCurrency(currency, Number(subtotal));

  const total = order?.totalCents ? order?.totalCents / 100 : null;
  const totalFormatted = total && getFormattedCurrency(currency, Number(total));

  const orderStatus = getOrderStatus(props);
  const orderStatusMixin = getOrderStatusLabelColor(orderStatus);

  const vendor = vendors[checkoutRequestId] || null;
  const isVendorLoading = !vendor || (vendor.loading && !vendor.title);

  const user = useAppSelector(selectUserInfo);
  const webhookUrl = user?.webhookURL;
  const developerId = user?.uid;
  const hmacSecretKey = user?.hmacSecretKey ?? '';
  const [webhookValue, setWebhookValue] = useState<WebhookType>(WebhookType.TrackingObtained);

  return (
    <tr className="order-table-row" key={checkoutRequestId}>
      <td>
        <Link to={orderURL}>{checkoutRequestId}</Link>
      </td>
      <td>{createdAtLocalDate}</td>
      <td className="td-preloader">
        {isVendorLoading ? <PreloaderIcon size={RyeLogoSizeCLass.micro} /> : vendor?.title}
      </td>
      <td>{subtotalFormatted}</td>
      <td>{totalFormatted}</td>
      <td>{getTrackingDetails(props)}</td>
      <td>
        <div className={`order-center-label ${orderStatusMixin}`}>{orderStatus}</div>
      </td>
      {/* Do not display webhooks for production for now. We will enable it once we want to resend
      webhooks to developers. */}
      {env.REACT_APP_NODE_ENV !== 'production' && (
        <td>
          <div className="webhooks">
            <Dropdown
              options={Object.keys(WebhookType).map((key) => ({
                label: key,
                value: WebhookType[key as keyof typeof WebhookType],
              }))}
              value={webhookValue}
              onSelect={(value: string) => setWebhookValue(value as WebhookType)}
            />

            {developerId && (
              <FormButton
                onClick={() =>
                  dispatchWebhook({
                    webhookURL: webhookUrl ?? '',
                    payload: createWebhook(webhookValue, props, developerId),
                    developerId,
                    hmacSecretKey,
                  })
                }
                className={webhookUrl ? 'webhooks-send-button' : 'webhooks-send-button-disabled'}
                disabled={!webhookUrl}
              >
                Send
                {!webhookUrl && (
                  <div className="webhooks-send-button-disabled-text">
                    Add URL domain for webhooks.
                  </div>
                )}
              </FormButton>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

const useRequestVendorsInfo = (
  items: CheckoutRequestFirestoreStoreModel[] = [],
  vendors: VendorsByRequestId = {},
) => {
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const runQuery = async (productId: string, marketplace: string) => {
    return await client.query({
      query: REQUEST_MARKETPLACE_PRODUCT_VENDOR_BY_ID,
      variables: getMarketplaceProductVendorInputById(productId, marketplace),
    });
  };

  useEffect(() => {
    if (items.length) {
      items.forEach(async (item) => {
        let productId: string | undefined;
        const { request, id: checkoutRequestId } = item;
        if (request.products && request.products?.length > 0) {
          productId = request.products[0]?.productId;
        }

        let title; // Default empty vendor/brand title;
        const vendor = vendors[checkoutRequestId];

        dispatch(setVendorByProductId(checkoutRequestId));
        if (productId && !vendor) {
          try {
            const result = await runQuery(productId, item.marketplace);
            title = result?.data?.productByID?.vendor;
          } catch (error) {
            Sentry.captureException(error);
          }
        }
        dispatch(updateVendorByProductId(checkoutRequestId, title));
      });
    }
  }, [items]);
};
