import { useCallback, useLayoutEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (this: any, ...args: any[]) => any;

export function useEvent<TFunction extends AnyFunction>(callback: TFunction): TFunction {
  const holder = useRef<TFunction>(callback);
  useLayoutEffect(() => {
    holder.current = callback;
  }, [callback]);

  return useCallback(
    function (...params) {
      return holder.current.apply(this, params);
    } as TFunction,
    [holder],
  );
}
