import React from 'react';
import { useAppSelector } from 'app/store';
import { selectOnBoardingPresentationStatus, selectUserInfo } from 'app/store/user';
import { selectTheme } from 'app/store/theme';
import PreloaderIcon, { RyeLogoSizeCLass } from 'components/Preloader';
import { ReactComponent as GreetingIcon } from 'assets/icons/greeting.svg';

interface LoaderWrapperProps {
  children: React.ReactNode;
}

export const LoaderWrapper = ({ children }: LoaderWrapperProps) => {
  const userInfo = useAppSelector(selectUserInfo);
  const userName =
    userInfo?.firstName || userInfo?.displayName || userInfo?.displayName || 'Anonymous Beaver';
  const theme = useAppSelector(selectTheme);
  const onBoardingPresentationStatus = useAppSelector(selectOnBoardingPresentationStatus);

  const show = userInfo && theme;

  return (
    <>
      {show ? (
        <>{children}</>
      ) : (
        <div className="dashboard-preloader">
          <PreloaderIcon size={RyeLogoSizeCLass.small} />
        </div>
      )}

      <>
        {show &&
        typeof onBoardingPresentationStatus !== 'undefined' &&
        !onBoardingPresentationStatus ? (
          <div
            className={onBoardingPresentationStatus ? 'dashboard-cover-hidden' : 'dashboard-cover'}
          >
            <GreetingIcon />
            <span>Welcome, {userName}!</span>
          </div>
        ) : (
          <>{null}</>
        )}
      </>
    </>
  );
};
