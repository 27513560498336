import { CheckoutRequestFirestoreStoreModel } from 'app/store/checkoutRequests';
import { RyeWebhook, WebhookType } from './types';
import { v4 as uuidv4 } from 'uuid';
import cryptoJs from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';

type WebhookPayloadRequirements<T extends RyeWebhook> = {
  id: string;
  requestId: string;
  developerId: string;
  type: WebhookType;
  data: T['data'];
};

function createWebhookPayload<T extends RyeWebhook>({
  id,
  requestId,
  developerId,
  type,
  data,
}: WebhookPayloadRequirements<T>): T {
  return {
    id,
    requestId,
    developerId,
    createdAt: new Date().toISOString(),
    type,
    data,
  } as T;
}

function createCommonPayload(
  request: CheckoutRequestFirestoreStoreModel,
  developerId: string,
): WebhookCommonPayload {
  return {
    id: uuidv4(),
    requestId: request.id,
    developerId,
  };
}

export function createWebhook(
  webhookType: WebhookType,
  checkoutRequest: CheckoutRequestFirestoreStoreModel,
  developerId: string,
): RyeWebhook {
  const marketplace = checkoutRequest.marketplace;
  const commonPayload = createCommonPayload(checkoutRequest, developerId);

  switch (webhookType) {
    case WebhookType.PaymentFailed:
      return createPaymentFailedWebhook(commonPayload, marketplace);

    case WebhookType.PaymentSucceeded:
      return createPaymentSuccessWebhook(commonPayload, checkoutRequest);

    case WebhookType.PaymentRefunded:
      return createPaymentRefundedWebhook(commonPayload);

    case WebhookType.OrderSubmissionStarted:
      return createOrderSubmissionTypeWebhook(webhookType, commonPayload, marketplace);

    case WebhookType.OrderSubmissionSucceeded:
      return createOrderSubmissionTypeWebhook(webhookType, commonPayload, marketplace);

    case WebhookType.OrderCancelRequested:
      return createOrderCancelRequestedWebhook(commonPayload, checkoutRequest);

    case WebhookType.OrderCancelStarted:
      return createOrderCancelStartedWebhook(commonPayload, marketplace);

    case WebhookType.OrderCancelSucceeded:
      return createOrderCancelSucceededWebhook(commonPayload, marketplace);

    case WebhookType.OrderCancelFailed:
      return createOrderCancelFailedWebhook(commonPayload, marketplace);

    case WebhookType.OrderPlaced:
      return createOrderPlacedWebhook(commonPayload, checkoutRequest);

    case WebhookType.OrderFailed:
      return createOrderFailedWebhook(commonPayload, checkoutRequest);

    case WebhookType.TrackingObtained:
      return createTrackingObtainedWebhook(commonPayload, checkoutRequest);

    case WebhookType.ReturnRequested:
      return createReturnRequestedWebhook(commonPayload, marketplace);

    case WebhookType.ReturnApproved:
      return createReturnApprovedWebhook(commonPayload, marketplace);

    case WebhookType.ReturnClosed:
      return createReturnClosedWebhook(commonPayload, marketplace);

    case WebhookType.ReturnDeclined:
      return createReturnDeclinedWebhook(commonPayload, marketplace);

    case WebhookType.ReturnCancelled:
      return createReturnCancelledWebhook(commonPayload, marketplace);

    case WebhookType.ShopifyProductUpdated:
      return createShopifyProductUpdatedWebhook(commonPayload);

    default:
      // Getting inconsistent return error even though all types are exhausted
      // Should never reach here
      throw new Error(`Unsupported webhook type: ${webhookType}`);
  }
}

type WebhookCommonPayload = {
  id: string;
  requestId: string;
  developerId: string;
};

type Marketplace = 'amazon' | 'shopify';

function createPaymentFailedWebhook(commonPayload: WebhookCommonPayload, marketplace: Marketplace) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.PaymentFailed,
    data: { marketplace },
  });
}

function createPaymentSuccessWebhook(
  commonPayload: WebhookCommonPayload,
  checkoutRequest: CheckoutRequestFirestoreStoreModel,
) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.PaymentSucceeded,
    data: {
      marketplace: checkoutRequest.marketplace,
      amount: checkoutRequest.order?.totalCents ?? 0 / 100,
    },
  });
}

function createPaymentRefundedWebhook(commonPayload: WebhookCommonPayload) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.PaymentRefunded,
    data: {},
  });
}

function createOrderSubmissionTypeWebhook(
  webhookType: WebhookType.OrderSubmissionStarted | WebhookType.OrderSubmissionSucceeded,
  commonPayload: WebhookCommonPayload,
  marketplace: Marketplace,
) {
  return createWebhookPayload({
    ...commonPayload,
    type: webhookType,
    data: { marketplace },
  });
}

function createOrderCancelStartedWebhook(
  commonPayload: WebhookCommonPayload,
  marketplace: Marketplace,
) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.OrderCancelStarted,
    data: {
      marketplace,
      startedAt: new Date().toISOString(),
    },
  });
}

function createOrderCancelSucceededWebhook(
  commonPayload: WebhookCommonPayload,
  marketplace: Marketplace,
) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.OrderCancelSucceeded,
    data: {
      marketplace,
      cancelledAt: new Date().toISOString(),
    },
  });
}

function createOrderCancelFailedWebhook(
  commonPayload: WebhookCommonPayload,
  marketplace: Marketplace,
) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.OrderCancelFailed,
    data: {
      marketplace,
      reason: 'Order already shipped',
    },
  });
}

function createOrderPlacedWebhook(
  commonPayload: WebhookCommonPayload,
  checkoutRequest: CheckoutRequestFirestoreStoreModel,
) {
  const orderData = {
    currency: checkoutRequest.order?.currency ?? 'USD',
    shippingCents: checkoutRequest.order?.shippingCents ?? 0,
    subtotalCents: checkoutRequest.order?.subtotalCents ?? 0,
    taxCents: checkoutRequest.order?.taxCents ?? 0,
    totalCents: checkoutRequest.order?.totalCents ?? 0,
    discountCents: 0,
  };

  const marketplace = checkoutRequest.marketplace;

  if (marketplace === 'shopify') {
    return createWebhookPayload({
      ...commonPayload,
      type: WebhookType.OrderPlaced,
      data: {
        marketplace,
        order: orderData,
      },
    });
  } else {
    return createWebhookPayload({
      ...commonPayload,
      type: WebhookType.OrderPlaced,
      data: {
        marketplace,
        order: {
          ...orderData,
          amazonOrders: checkoutRequest.order?.amazonOrders ?? [],
        },
      },
    });
  }
}

function createOrderFailedWebhook(
  commonPayload: WebhookCommonPayload,
  checkoutRequest: CheckoutRequestFirestoreStoreModel,
) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.OrderFailed,
    data: {
      marketplace: checkoutRequest.marketplace,
      refund: {
        id: `refund_id_${uuidv4()}`,
        object: 'refund',
        amount: checkoutRequest.order?.totalCents ?? 0 / 100,
        created: 1675936800,
        currency: checkoutRequest.order?.currency ?? 'USD',
        receipt_number: `receipt_number_${uuidv4()}`,
        status: 'succeeded',
      },
      reasonCode: 'INTERNAL_ERROR',
      reason: 'An internal error occurred.',
    },
  });
}

function createTrackingObtainedWebhook(
  commonPayload: WebhookCommonPayload,
  checkoutRequest: CheckoutRequestFirestoreStoreModel,
) {
  const trackingNumber = uuidv4().slice(0, 10);
  const marketplace = checkoutRequest.marketplace;

  if (marketplace === 'shopify') {
    return createWebhookPayload({
      ...commonPayload,
      type: WebhookType.TrackingObtained,
      data: {
        marketplace,
        order: {
          fulfillments: [
            {
              products: checkoutRequest.order?.fulfillments?.[0]?.products ?? [],
              trackingDetails: [
                {
                  trackingNumber,
                  courierUrl: `https://www.ups.com/WebTracking?loc=en_US&requester=ST&trackNums=${trackingNumber}`,
                  courierName: 'UPS',
                },
              ],
            },
          ],
        },
      },
    });
  } else {
    const amazonOrders = (checkoutRequest.order?.amazonOrders ?? []).map((amazonOrder) => {
      return {
        ...amazonOrder,
        trackingNumber,
        courierUrl: `https://www.ups.com/WebTracking?loc=en_US&requester=ST&trackNums=${trackingNumber}`,
        tracking_url: `https://www.ups.com/WebTracking?loc=en_US&requester=ST&trackNums=${trackingNumber}`,
        courierName: 'UPS',
        shipments: [
          {
            carrierTracking: trackingNumber,
            carrierTrackingUrl: `https://www.ups.com/WebTracking?loc=en_US&requester=ST&trackNums=${trackingNumber}`,
            carrierName: 'UPS',
            deliveryInformation: {
              deliveryStatus: 'NOT_AVAILABLE',
              expectedDeliveryDate: 'Not available',
            },
            shipmentDate: 'Not available',
            shipmentQuantity: 1,
            shipmentStatus: 'SHIPPED',
          },
        ],
      };
    });

    return createWebhookPayload({
      ...commonPayload,
      type: WebhookType.TrackingObtained,
      data: {
        marketplace,
        order: {
          currency: checkoutRequest.order?.currency ?? 'USD',
          shippingCents: checkoutRequest.order?.shippingCents ?? 0,
          subtotalCents: checkoutRequest.order?.subtotalCents ?? 0,
          taxCents: checkoutRequest.order?.taxCents ?? 0,
          totalCents: checkoutRequest.order?.totalCents ?? 0,
          discountCents: 0,
          amazonOrders,
        },
      },
    });
  }
}

function createOrderCancelRequestedWebhook(
  commonPayload: WebhookCommonPayload,
  checkoutRequest: CheckoutRequestFirestoreStoreModel,
) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.OrderCancelRequested,
    data: {
      marketplace: checkoutRequest.marketplace,
      requestedAt: new Date().toISOString(),
      marketplaceOrderId: checkoutRequest.id,
    },
  });
}

function createReturnRequestedWebhook(
  commonPayload: WebhookCommonPayload,
  marketplace: Marketplace,
) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.ReturnRequested,
    data: {
      marketplace,
      requestedAt: new Date().toISOString(),
    },
  });
}

function createReturnApprovedWebhook(
  commonPayload: WebhookCommonPayload,
  marketplace: Marketplace,
) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.ReturnApproved,
    data: {
      marketplace,
    },
  });
}

function createReturnClosedWebhook(commonPayload: WebhookCommonPayload, marketplace: Marketplace) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.ReturnClosed,
    data: {
      marketplace,
    },
  });
}

function createReturnDeclinedWebhook(
  commonPayload: WebhookCommonPayload,
  marketplace: Marketplace,
) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.ReturnDeclined,
    data: {
      marketplace,
      declinedAt: new Date().toISOString(),
    },
  });
}

function createReturnCancelledWebhook(
  commonPayload: WebhookCommonPayload,
  marketplace: Marketplace,
) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.ReturnCancelled,
    data: {
      marketplace,
      cancelledAt: new Date().toISOString(),
    },
  });
}

function createShopifyProductUpdatedWebhook(commonPayload: WebhookCommonPayload) {
  return createWebhookPayload({
    ...commonPayload,
    type: WebhookType.ShopifyProductUpdated,
    data: {
      marketplace: 'shopify',
      product: {
        id: 123456789,
        variants: [
          {
            id: 1234567890,
            quantityAvailable: 1,
          },
        ],
      },
    },
  });
}

// Helper function to generate HMAC signature for webhook security
export function generateHmacSignature(payload: string, secretKey: string): string {
  const hmac = cryptoJs.HmacSHA256(payload, secretKey);

  return Base64.stringify(hmac);
}
