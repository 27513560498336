import { MerchandiseCard } from './MerchandiseCard';

export function EmptyState() {
  return (
    <MerchandiseCard className="bg-striped items-center justify-center">
      <div className="flex flex-col items-center gap-1 text-center">
        <h3 className="text-2xl font-bold tracking-tight">No selected product</h3>
        <p className="text-sm text-muted-foreground">
          Paste in a product URL above to get started.
        </p>
      </div>
    </MerchandiseCard>
  );
}
