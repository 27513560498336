import { BillingItemType, VolumeTier } from 'app/graphql/generated/admin/graphql';
import {
  Breakdown,
  BreakdownDivider,
  BreakdownItem,
  BreakdownItemContent,
  Container,
} from './styles';

type Props = {
  volumeTiers: VolumeTier[];
  amount: number;
  type: BillingItemType;
};
export const VolumeTierCard = ({ volumeTiers, amount, type }: Props) => {
  const currentTierIndex = volumeTiers.findIndex((tier) => tier.fee === amount);
  const currentTier = volumeTiers[currentTierIndex];
  if (currentTierIndex < 0) {
    return <></>;
  }

  const getVolumeText = () => {
    const nextTier = volumeTiers[currentTierIndex + 1];
    if (nextTier) {
      return ` ${currentTier.volume} ≤ volume < ${new Intl.NumberFormat('en-US', {}).format(
        nextTier.volume,
      )}`;
    }
    return `≥ ${currentTier.volume}`;
  };

  return (
    <Container className="rounded-lg border">
      <Breakdown>
        <BreakdownItem>
          <BreakdownItemContent>
            <div>Tier</div>
            <BreakdownDivider />
            <div>Tier {currentTierIndex + 1}</div>
          </BreakdownItemContent>
        </BreakdownItem>
        <BreakdownItem>
          <BreakdownItemContent>
            <div>Volume</div>
            <BreakdownDivider />
            <div>{getVolumeText()}</div>
          </BreakdownItemContent>
        </BreakdownItem>
        <BreakdownItem>
          <BreakdownItemContent>
            <div>{type === BillingItemType.PlatformFeeRefund ? 'Refunded Amount' : 'Fee'} </div>
            <BreakdownDivider />
            <div>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(amount / 100)}
            </div>
          </BreakdownItemContent>
        </BreakdownItem>
      </Breakdown>
    </Container>
  );
};
