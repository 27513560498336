import AccordionItem, { AccordionItemProps, StepName } from 'components/AccordionItem';
import { useAppDispatch, useAppSelector } from 'app/store';
import { useEffect } from 'react';
import { onBoardingPresentationCompleted, selectUserInfo } from 'app/store/user';
import { ReactComponent as GreetingIcon } from 'assets/icons/greeting.svg';
import { ReactComponent as ResourceDocsImg } from 'assets/images/resource-docs.svg';
import config from 'config/constants';

import './style.scss';
import useAuth from 'hooks/useAuth';

const renderAccordionItems = (accordionItemsData: AccordionItemProps[]): JSX.Element[] => {
  return accordionItemsData.map((data) => (
    <AccordionItem
      key={data.stepName}
      stepName={data.stepName}
      title={data.title}
      subtitle={data.subtitle}
      isOpened={data.isOpened}
      duplicateButton={data.duplicateButton}
    >
      {data.children}
    </AccordionItem>
  ));
};

const Page = () => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(selectUserInfo);
  const { user } = useAuth();

  const userName =
    userInfo?.firstName || userInfo?.displayName || user?.displayName || 'Anonymous Beaver';
  const playAnimation = userInfo?.onBoardingPresentationComplete;

  const accordionItemsData: AccordionItemProps[] = [
    // {
    //   stepName: StepName.Tutorial,
    //   title: ['Step 1', 'Play with the Rye tutorial'],
    //   isOpened: true,
    //   subtitle: 'Try out the Rye API to make a purchase from Shopify or Amazon',
    //   duplicateButton: true,
    //   children: (
    //     <a
    //       className="dashboard-step explore-tutorial"
    //       href={config.external.doc.tutorial}
    //       target="_blank"
    //     >
    //       <FormButton contrast={true} className="explore-tutorial">
    //         Explore tutorial <LinkIcon className="icon" />
    //       </FormButton>
    //     </a>
    //   ),
    // },
    {
      stepName: StepName.ResourceDocs,
      title: ['Read our docs'],
      isOpened: true,
      children: (
        <div className="dashboard-step read-docs">
          <a href={config.external.doc.home} target="_blank">
            <ResourceDocsImg />
          </a>
          <div className="dashboard-step-text">
            <p>
              Learn how to use the Rye API. See common GraphQL queries, and how the API functions.
            </p>
            <a
              className="dev-info-link"
              href={config.external.doc.home}
              target="_blank"
              rel="noreferrer"
            >
              See docs
            </a>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (typeof userInfo?.onBoardingPresentationComplete !== 'undefined') {
      setTimeout(() => dispatch(onBoardingPresentationCompleted()), 1500);
    }
  }, []);

  return (
    <div
      className={
        typeof playAnimation !== 'undefined' && !playAnimation
          ? 'dashboard dashboard-greeting'
          : 'dashboard'
      }
    >
      <div className="dashboard-content space-y-8">
        <div className="dashboard-title flex items-center">
          <GreetingIcon />
          <span>Welcome, {userName}!</span>
        </div>
        <p className="dashboard-subtitle">
          Rye API unlocks search, monetization, checkout, tracking & inventory for your business.
        </p>
        <div className="show-accordion-items">{renderAccordionItems(accordionItemsData)}</div>
      </div>
    </div>
  );
};

export default Page;
