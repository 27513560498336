import { getFormattedCurrency } from 'utils/formatters';
import './styles.scss';

interface PriceDetailsCardValue {
  subtotalCents: number;
  shippingCents: number;
  taxCents: number;
  totalCents: number;
  currency: string;
}

export const PriceDetailsCard = (value: PriceDetailsCardValue) => {
  return (
    <div className="details-price-card">
      <div className="details-price-row">
        <div className="details-price-row-title">Subtotal</div>
        <div className="details-price-row-data">
          {getFormattedCurrency(value.currency, value.subtotalCents / 100)}
        </div>
      </div>
      <div className="details-price-row">
        <div className="details-price-row-title">Shipping</div>
        <div className="details-price-row-data">
          {getFormattedCurrency(value.currency, value.shippingCents / 100)}
        </div>
      </div>
      <div className="details-price-row">
        <div className="details-price-row-title">Tax</div>
        <div className="details-price-row-data">
          {getFormattedCurrency(value.currency, value.taxCents / 100)}
        </div>
      </div>
      <div className="details-price-row">
        <div className="details-price-row-title">
          <b>Total (USD)</b>
        </div>
        <div className="details-price-row-data">
          <b>{getFormattedCurrency(value.currency, value.totalCents / 100)}</b>
        </div>
      </div>
    </div>
  );
};
