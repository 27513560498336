import { createNextState } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CheckoutRequestFirestoreModel, usersCollection } from 'app/firebase/collections/users';
import type { RootState, AppThunk } from 'app/store';
import { convertFirebaseDateToUTC } from 'app/utils';

/**
 * Reducers
 */

export type CheckoutRequestFirestoreStoreModel = CheckoutRequestFirestoreModel & {
  createdAtUTS: number;
  refund?: {
    createdAtUTS: number;
  };
};

export interface Pagination {
  selected: number;
  total: number;
}

interface VendorModel {
  title: string | null;
  loading: boolean;
}

export interface VendorsByRequestId {
  [productId: string]: VendorModel;
}

type CheckoutRequestsState = {
  loadingRequests?: boolean;
  detail?: CheckoutRequestFirestoreStoreModel;
  list: CheckoutRequestFirestoreStoreModel[];
  vendors: VendorsByRequestId;
  page: Pagination;
};

export const ITEMS_PER_PAGE = 10;

const initialState: CheckoutRequestsState = {
  loadingRequests: undefined,
  detail: undefined,
  list: [],
  vendors: {},
  page: {
    selected: 0,
    total: 0,
  },
};

const checkoutRequestsSlice = createSlice({
  name: 'checkoutRequests',
  initialState,
  reducers: {
    clean: (): CheckoutRequestsState => {
      return { ...initialState };
    },

    loadRequests: (state, action: PayloadAction<boolean>): CheckoutRequestsState => {
      return {
        ...state,
        loadingRequests: action.payload,
      };
    },

    setRequests: (
      state,
      action: PayloadAction<CheckoutRequestFirestoreStoreModel[]>,
    ): CheckoutRequestsState => {
      const list = action.payload;

      return createNextState(state, (draft) => {
        draft.list = list;
        draft.page.total = Math.ceil(list.length / ITEMS_PER_PAGE);
        draft.loadingRequests = false;
      });
    },

    setRequest: (
      state,
      action: PayloadAction<CheckoutRequestFirestoreStoreModel>,
    ): CheckoutRequestsState => {
      return createNextState(state, (draft) => {
        draft.detail = action.payload;
        draft.loadingRequests = false;
      });
    },

    setPage: (state, action: PayloadAction<number>): CheckoutRequestsState => {
      const selectedPageNumber = action.payload;

      return createNextState(state, (draft) => {
        draft.page.selected = selectedPageNumber;
      });
    },

    replaceVendor: (
      state,
      action: PayloadAction<{ productId: string; loading: boolean; title: string | null }>,
    ): CheckoutRequestsState => {
      const { productId, loading, title } = action.payload;
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [productId]: {
            title,
            loading,
          },
        },
      };
    },
  },
});

/**
 * Actions
 */
const { clean, loadRequests, setRequests, setRequest, setPage, replaceVendor } =
  checkoutRequestsSlice.actions;

export const cleanCheckoutRequests = clean;

export const fetchCheckoutRequests =
  (uid?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const userUid = uid || state.user.documentId;
    const isLoading = selectCheckoutRequestsLoading(state);
    const isFirstTimeLoading = selectCheckoutRequestsLoadingFirstTime(state);

    if (userUid && (!isLoading || isFirstTimeLoading)) {
      dispatch(loadRequests(true));

      const responseList = await usersCollection.fetchCheckoutRequestsByUid(userUid);
      const requestsList = responseList.map(convertToStoreModel);

      dispatch(setRequests(requestsList));
      dispatch(loadRequests(false));
    }
  };

export const fetchCheckoutRequestById =
  (orderId: string, uid?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const userUid = uid || state.user.documentId;
    const isLoading = selectCheckoutRequestsLoading(state);
    const isFirstTimeLoading = selectCheckoutRequestsLoadingFirstTime(state);

    if (userUid && (!isLoading || isFirstTimeLoading)) {
      dispatch(loadRequests(true));

      const response = await usersCollection.fetchCheckoutRequestByOrderId(userUid, orderId);
      if (!response) {
        dispatch(loadRequests(false));
        return;
      }
      const request = convertToStoreModel(response);
      dispatch(setRequest(request));
      dispatch(loadRequests(false));
    } else {
      dispatch(loadRequests(false));
    }
  };

export const selectPage =
  (pageNumber: number): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { total, selected } = state.checkoutRequests.page;

    if (pageNumber !== selected && pageNumber >= 0 && pageNumber < total) {
      dispatch(setPage(pageNumber));
    }
  };

export const setVendorByProductId =
  (productId: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const vendor = state.checkoutRequests.vendors[productId];

    if (!vendor) {
      dispatch(replaceVendor({ productId, loading: true, title: null }));
    }
  };

export const updateVendorByProductId =
  (productId: string, title: string | null = null): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const vendor = state.checkoutRequests.vendors[productId];

    if (vendor && vendor.loading) {
      dispatch(replaceVendor({ productId, loading: false, title }));
    }
  };

/**
 * Selectors
 */
export const selectCheckoutRequests = (state: RootState) => {
  return state.checkoutRequests.list;
};

export const selectCheckoutRequest = (state: RootState) => {
  return state.checkoutRequests.detail;
};

const selectCheckoutRequestsLoadingFirstTime = (state: RootState) =>
  state.checkoutRequests.loadingRequests === undefined;
const selectVendorsLoading = (state: RootState) => {
  const { vendors } = state.checkoutRequests;
  return Object.keys(vendors).some((id) => vendors[id].loading);
};
export const selectCheckoutRequestsLoading = (state: RootState) =>
  state.checkoutRequests.loadingRequests ||
  selectCheckoutRequestsLoadingFirstTime(state) ||
  selectVendorsLoading(state);
export const selectPageInfo = (state: RootState) => state.checkoutRequests.page;
export const selectVendors = (state: RootState) => state.checkoutRequests.vendors;

/**
 * Utils
 */
const convertToStoreModel = ({
  createdAt,
  // Skipped this intentionally, to prevent React warning about Firebase date format
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  autoCancelTask,
  refund,
  ...item
}: CheckoutRequestFirestoreModel): CheckoutRequestFirestoreStoreModel => {
  return {
    ...item,
    createdAtUTS: convertFirebaseDateToUTC(createdAt),
    createdAt: {
      seconds: createdAt.seconds,
      nanoseconds: createdAt.nanoseconds,
    },
    refund: refund && {
      createdAtUTS: refund && convertFirebaseDateToUTC(refund.createdAt),
      createdAt: {
        seconds: refund.createdAt.seconds,
        nanoseconds: refund.createdAt.nanoseconds,
      },
    },
  } as CheckoutRequestFirestoreStoreModel;
};

export default checkoutRequestsSlice.reducer;
