import { Link } from 'react-router-dom';

import Logo from 'assets/icons/light.png';
import DarkLogo from 'assets/icons/dark.png';
import config, { Routes } from 'config/constants';

import './style.scss';
import useTheme from 'hooks/useTheme';
import { ThemeMode } from 'providers/ThemeProvider';

const RyeLogo = ({ to = config.routeDefaultHome }: { to?: Routes }) => {
  const { theme } = useTheme();

  return (
    <Link id={'heap-logo-top-link-rye-com'} className="logo-wrapper" to={to}>
      <img className="logo-image" src={theme === ThemeMode.Light ? Logo : DarkLogo} />
      <span className="logo-text">CONSOLE</span>
    </Link>
  );
};

export default RyeLogo;
