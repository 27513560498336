import { useQuery } from '@apollo/client/react';
import { DEVELOPERS } from 'app/graphql';
import { Developer } from 'app/graphql/generated/admin/graphql';
import { localStorageAdminAccount } from 'app/localstorage';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import { Button } from 'components/ui/button';
import { Card, CardContent } from 'components/ui/card';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { ScrollArea } from 'components/ui/scrolll-area';
import { Skeleton } from 'components/ui/skeleton';
import useAuth from 'hooks/useAuth';

import { useEffect, useMemo, useState } from 'react';

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onLogin?: () => void;
};

export type OriginalAccount = {
  token?: string | null;
  id?: string | null;
};

export const DevelopersDialog = ({ open, onOpenChange, onLogin }: Props) => {
  const { adminToken, uid, apiKey } = useAppSelector(selectUserInfo) || {};

  const adminAccount = localStorageAdminAccount.get<OriginalAccount>();
  const [filter, setFilter] = useState('');

  const { assumeDeveloperIdentity } = useAuth();
  const { data, refetch, loading } = useQuery(DEVELOPERS, {
    skip: !adminToken,
    context: {
      clientName: 'admin',
    },
  });

  const handleLogin = (developer: Developer) => async () => {
    let storedToken: OriginalAccount | null = adminAccount;
    if (!storedToken) {
      storedToken = {
        id: uid,
        token: adminToken,
      };
      localStorageAdminAccount.add(storedToken);
    }

    await assumeDeveloperIdentity(developer.id, apiKey, adminToken);
    onOpenChange(false);
    onLogin?.();
  };

  const developers = useMemo(() => {
    // Filter developers by display name or email
    const devsFilteredByDisplayName =
      data?.developers?.developers?.filter((developer) => {
        return developer.displayName?.toLowerCase().includes(filter.toLowerCase());
      }) ?? [];
    const devsFilteredByEmail =
      data?.developers?.developers?.filter((developer) => {
        return developer.email?.toLowerCase().includes(filter.toLowerCase());
      }) ?? [];

    // Merge the results
    const allValues = [...devsFilteredByDisplayName, ...devsFilteredByEmail];

    // Create a map from the array to use for dedupe
    const mapValues = new Map(allValues.map((developer) => [developer.id, developer]));

    // return dedupe array
    return [...mapValues.values()];
  }, [data, filter]);

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilter(value);
  };

  useEffect(() => {
    if (adminToken) {
      refetch();
    }
  }, [adminToken]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent style={{ borderColor: '#333' }}>
        <DialogHeader>
          <DialogTitle>Assume developer's identity</DialogTitle>
          <DialogDescription>
            Select the developer account you want to view in the console
          </DialogDescription>
          <Input
            placeholder="Search developers..."
            className="justify-self-end"
            value={filter}
            disabled={loading}
            onChange={handleFilter}
          />
        </DialogHeader>
        <Card>
          <CardContent className="p-0">
            <ScrollArea className="grid h-72 gap-6 rounded-md">
              {loading
                ? new Array(4).fill(1).map((_, index) => (
                    <div className="flex items-center justify-between space-x-4 p-4" key={index}>
                      <div className="flex items-center space-x-4">
                        <Skeleton className={`h-[48px] w-[48px] rounded-full`} />
                        <div className="space-y-2">
                          <Skeleton className={`h-[10px] w-[120px] `} />
                          <Skeleton className={`h-[10px] w-[80px]`} />
                        </div>
                      </div>
                      <Skeleton className={`h-[40px] w-[72px] rounded-md`} />
                    </div>
                  ))
                : developers.map((developer) => (
                    <div
                      className="flex items-center justify-between space-x-4 p-4"
                      key={developer.id}
                    >
                      <div className="flex items-center space-x-4">
                        <Avatar>
                          <AvatarImage src={developer.photoURL ?? undefined} />
                          <AvatarFallback>
                            {developer.displayName?.[0]?.toUpperCase() ||
                              developer.email?.[0]?.toUpperCase() ||
                              'U'}
                          </AvatarFallback>
                        </Avatar>
                        <div>
                          <p className="text-sm font-medium leading-none">
                            {developer.displayName || developer.email || 'User'}
                          </p>
                          {developer.displayName && (
                            <p className="text-sm text-muted-foreground">{developer.email}</p>
                          )}
                        </div>
                      </div>
                      <Button className="ml-auto" onClick={handleLogin(developer)}>
                        Log in
                      </Button>
                    </div>
                  ))}
            </ScrollArea>
          </CardContent>
        </Card>
        <DialogFooter>
          <Button onClick={() => onOpenChange(false)}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
