import { useState } from 'react';

import { cn } from 'lib/utils';
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from 'components/ui/carousel';

import { Merchandise } from '../hooks/useProductImporter';
import { Skeleton } from 'components/ui/skeleton';

interface MerchandiseCarouselProps {
  images: Merchandise['images'];
}

export function MerchandiseCarousel({ images }: MerchandiseCarouselProps) {
  const [main, setMain] = useState<CarouselApi>();
  const [child, setChild] = useState<CarouselApi>();

  const initialImageIndex = images.findIndex((it) => it.isPrimary);
  const [imageIndex, setImageIndex] = useState(images.findIndex((it) => it.isPrimary));

  return (
    <div className="space-y-2">
      <Carousel
        className="h-72 w-72 rounded-lg shadow-sm"
        opts={{
          startIndex: initialImageIndex,
        }}
        setApi={setMain}
      >
        <CarouselContent>
          {images.map((image) => (
            <CarouselItem key={image.url}>
              <img className="h-72 w-72" src={image.url} alt="" />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
      <Carousel
        className="h-[84px] w-72 rounded-lg shadow-sm"
        opts={{
          containScroll: false,
          startIndex: initialImageIndex,
        }}
        setApi={setChild}
      >
        <CarouselContent>
          {images.map((image, index) => (
            <CarouselItem key={image.url} className="basis-1/3">
              <img
                alt=""
                className={cn(
                  'h-[84px] w-[84px] cursor-pointer transition-all',
                  imageIndex === index
                    ? 'border border-primary opacity-100'
                    : 'opacity-30 hover:opacity-80',
                )}
                src={image.url}
                onClick={() => {
                  main?.scrollTo(index);
                  child?.scrollTo(index);
                  setImageIndex(index);
                }}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
}

export function MerchandiseCarouselLoading() {
  return (
    <div className="space-y-2">
      <Skeleton className="h-72 w-72 rounded-lg shadow-sm" />
      <div className="flex h-[84px] w-72 flex-row gap-1 rounded-lg shadow-sm">
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton key={index} className="h-[84px] w-[84px] basis-1/3" />
        ))}
      </div>
    </div>
  );
}
