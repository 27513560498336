import { createSlice } from '@reduxjs/toolkit';
import type { RootState, AppThunk } from 'app/store';
import { localStorageColorTheme } from 'app/localstorage';
import { ThemeMode } from 'providers/ThemeProvider';

/**
 * Reducers
 */
type ColorTheme = {
  theme: ThemeMode;
};

const getTheme = (): ThemeMode => {
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const theme = localStorageColorTheme.get();
  if (theme) {
    return theme === ThemeMode.Dark ? ThemeMode.Dark : ThemeMode.Light;
  } else {
    const newTheme = defaultDark ? ThemeMode.Dark : ThemeMode.Light;
    localStorageColorTheme.replace(newTheme);
    return newTheme;
  }
};

const initialTheme = getTheme();

const initialState: ColorTheme = {
  theme: initialTheme,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    updateState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

/**
 * Actions
 */
const { updateState } = themeSlice.actions;

export const switchThemeInStore = (): AppThunk => async (dispatch) => {
  const currentTheme = localStorageColorTheme.get();
  const theme = currentTheme === ThemeMode.Dark ? ThemeMode.Light : ThemeMode.Dark;
  localStorageColorTheme.replace(theme);
  dispatch(updateState({ theme }));
};

export const selectTheme = (state: RootState) => state.theme.theme;

export default themeSlice.reducer;
