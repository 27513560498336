/**
 * Ensures the given website has a protocol. If the given website does not,
 * then `https://` is applied as a default.
 */
export function addProtocolIfNotPresent(website: string): string {
  website = website.trim();
  try {
    new URL(website);
    return website;
  } catch {
    try {
      new URL(`https://${website}`);
      return `https://${website}`;
    } catch {
      // swallow
    }
  }

  return website;
}
