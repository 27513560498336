'use client';

import { DotsHorizontalIcon } from '@radix-ui/react-icons';

import { Button } from 'components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';

export interface RowAction {
  label: string;
  id: string;
}

interface DataTableRowActionsProps {
  rowActions: RowAction[];
  onActionSelect?: () => void;
}

export function DataTableRowActions({ rowActions, onActionSelect }: DataTableRowActionsProps) {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onActionSelect?.();
  };

  const disableDefault = (event: React.MouseEvent<HTMLElement | SVGElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger onClick={disableDefault}>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          onClick={disableDefault}
        >
          <DotsHorizontalIcon className="h-4 w-4" onClick={disableDefault} />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        {rowActions.map((action) => (
          <DropdownMenuItem key={action.id} onClick={handleClick}>
            {action.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
