import { ReactComponent as CubeIcon } from 'assets/icons/cube.svg';

import './style.scss';

interface InfoProps {
  children?: React.ReactNode;
  title: string;
  text: string;
  linkTitle: string;
  linkPath?: string;
  onHandleClick?: () => void;
}

const DevInfoBlock = ({ children, title, text, linkPath, linkTitle, onHandleClick }: InfoProps) => {
  return (
    <div className="dev-info">
      <div className="dev-info-icon">{children || <CubeIcon />}</div>
      <h2 className="dev-info-title">{title}</h2>

      <p className="dev-info-text">{text}</p>

      {linkPath ? (
        <a
          onClick={onHandleClick}
          className="dev-info-link"
          href={linkPath}
          target="_blank"
          rel="noreferrer"
        >
          {linkTitle}
        </a>
      ) : (
        <span className="dev-info-link placeholder">{linkTitle}</span>
      )}
    </div>
  );
};

export default DevInfoBlock;
