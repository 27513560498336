import { env, getHypertuneEnv } from 'config/env';
import { HypertuneProvider } from 'generated/hypertune.react';
import useAuth from 'hooks/useAuth';

export default function AppHypertuneProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  return (
    <HypertuneProvider
      createSourceOptions={{
        token: env.REACT_APP_HYPERTUNE_TOKEN,
      }}
      rootArgs={{
        context: {
          environment: getHypertuneEnv(),
          user: { id: user?.uid ?? '', email: user?.email ?? '' },
        },
      }}
    >
      {children}
    </HypertuneProvider>
  );
}
