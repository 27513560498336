import { OrderTracking } from 'utils/orderStatus';

interface OrderTrackingProps {
  tracking: OrderTracking;
}

export const TrackingInfo = ({ tracking }: OrderTrackingProps) => {
  const trackingDisplayInformation = tracking.courierName
    ? `${tracking.courierName} - ${tracking.trackingNumber}`
    : tracking.trackingNumber ?? 'Not available';
  if (tracking.courierUrl) {
    return (
      <a href={tracking.courierUrl} target="_blank" rel="noreferrer">
        {trackingDisplayInformation}
      </a>
    );
  } else {
    return <>{trackingDisplayInformation}</>;
  }
};
