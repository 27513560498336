import { useAppDispatch, useAppSelector } from 'app/store';
import {
  fetchCheckoutRequestById,
  selectCheckoutRequest,
  selectCheckoutRequestsLoading,
} from 'app/store/checkoutRequests';
import { ShopifyOrderDetailsCardTable } from 'components/Orders/OrderDetailsCards/OrderDetailsCardTable/ShopifyOrderDetailsCardTable/shopifyOrderDetailsCardTable';
import { AmazonOrderDetailsCardTable } from 'components/Orders/OrderDetailsCards/OrderDetailsCardTable/AmazonOrderDetailsCardTable/amazonOrderDetailsCardTable';
import { useEffect } from 'react';
import { selectUserInfo } from 'app/store/user';
import PreloaderIcon from 'components/Preloader';

interface OrderDetailsCardTableProps {
  orderId: string;
}

export const OrderDetailsCardTable = ({ orderId }: OrderDetailsCardTableProps) => {
  const orderDetails = useAppSelector(selectCheckoutRequest);
  const isRequestsLoading = useAppSelector(selectCheckoutRequestsLoading);
  const user = useAppSelector(selectUserInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCheckoutRequestById(orderId, user?.uid));
  }, [user, orderId]);

  // Show preloading on initial data loading
  if (isRequestsLoading) {
    return (
      <div className="order-preloader">
        <PreloaderIcon />
      </div>
    );
  }

  switch (orderDetails?.marketplace) {
    case 'shopify':
      return <ShopifyOrderDetailsCardTable orderDetails={orderDetails} />;
    case 'amazon':
      return <AmazonOrderDetailsCardTable orderDetails={orderDetails} />;
    default:
      return null;
  }
};
