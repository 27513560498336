import 'graphiql/graphiql.min.css';
import { useEffect, useState } from 'react';

import { GraphiQL } from 'graphiql';
import type { Fetcher } from '@graphiql/toolkit';

import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import PreloaderIcon, { RyeLogoSizeCLass } from 'components/Preloader';

import config from 'config/constants';
import { getGraphiQLHeaders } from 'app/utils';

import './style.scss';

const DEFAULT_QUERY = `
query {
  productsByDomainV2(
    input: {domain: "alo-yoga.myshopify.com"}
    pagination: {offset: 0, limit: 10}
  ) {
    id
    title
  }
}
`.trim();

const Page = () => {
  const { apiKey = '' } = useAppSelector(selectUserInfo) || {};
  const [headers, setHeaders] = useState('');

  useEffect(() => {
    if (apiKey) {
      getGraphiQLHeaders(apiKey).then((headersString) => setHeaders(headersString));
    }
  }, [apiKey]);

  const fetcher: Fetcher = async (graphQLParams, options) => {
    // const headersString = await getIframeEncodedHeadersMemo(apiKey);
    // const headers = JSON.parse(decodeURIComponent(headersString));

    const data = await fetch(config.external.gql, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...options?.headers,
      },
      body: JSON.stringify(graphQLParams),
    });
    return data.json().catch(() => data.text());
  };

  return (
    <>
      {apiKey ? (
        <div>
          <GraphiQL
            defaultQuery={DEFAULT_QUERY}
            defaultEditorToolsVisibility="headers"
            disableTabs
            fetcher={fetcher}
            headers={headers}
            showPersistHeadersSettings={false}
            onEditHeaders={setHeaders}
          />
        </div>
      ) : (
        <div className="graphiql-container is-loading">
          <PreloaderIcon size={RyeLogoSizeCLass.small} />
        </div>
      )}
    </>
  );
};

export default Page;
