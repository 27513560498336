import type {
  CheckoutRequestFirestore,
  ShopifyCheckoutRequestFirestore,
} from '@rye-com/rye-data-layer';

import { RyeAPIDetailsCard } from 'components/Orders/OrderDetailsCards/DetailsCard/ryeAPIDetailsCard';
import { ShippingInfoDetailsCard } from 'components/Orders/OrderDetailsCards/DetailsCard/shippingInfoDetailsCard';
import { getFormattedAddress } from 'utils/formatters';
import { PriceDetailsCard } from 'components/Orders/OrderDetailsCards/DetailsCard/priceDetailsCard';
import { AmazonItemCatalogCardTable } from 'components/Orders/OrderDetailsCards/OrderDetailsCardTable/AmazonOrderDetailsCardTable/ItemCatalogCard/amazonItemCatalogCardTable';
import { getAmazonTrackingNumbers, getOrderStatus } from 'utils/orderStatus';
import { StatusDetailsCard } from 'components/Orders/OrderDetailsCards/DetailsCard/statusDetailsCard';

import './styles.scss';
import { AmazonShipmentDetailsCard } from '../../DetailsCard/amazonShipmentDetailCard';

export type AmazonCheckoutRequest = Exclude<
  CheckoutRequestFirestore,
  ShopifyCheckoutRequestFirestore
>;

interface AmazonOrderDetailsCardTableProps {
  orderDetails: AmazonCheckoutRequest & {
    createdAtUTS: number;
  };
}

export const AmazonOrderDetailsCardTable = ({ orderDetails }: AmazonOrderDetailsCardTableProps) => (
  <div className="card-table-layout">
    <div>
      {orderDetails.order?.amazonGroupId === undefined && (
        <StatusDetailsCard
          orderStatus={getOrderStatus(orderDetails)}
          orderTracking={getAmazonTrackingNumbers(orderDetails.order?.amazonOrders ?? [])}
        />
      )}
      <ShippingInfoDetailsCard
        name={`${orderDetails.request.shippingAddress.firstName} ${orderDetails.request.shippingAddress.lastName}`}
        streetAddress={`${orderDetails.request.shippingAddress.address1}, ${orderDetails.request.shippingAddress.address2}`}
        location={`${getFormattedAddress(
          orderDetails.request.shippingAddress.city,
          orderDetails.request.shippingAddress.province,
        )}`}
        country={orderDetails.request.shippingAddress.country}
      />
      <RyeAPIDetailsCard
        createdOn={`${new Date(orderDetails.createdAtUTS).toLocaleString()}`}
        location={`${getFormattedAddress(
          orderDetails.request.shippingAddress.province,
          orderDetails.request.shippingAddress.country,
        )}`}
        marketplace={orderDetails.marketplace}
      />
    </div>

    <div className="card-table-price-details">
      {orderDetails.order?.amazonGroupId !== undefined && (
        <AmazonShipmentDetailsCard amazonOrders={orderDetails.order?.amazonOrders ?? []} />
      )}
      <AmazonItemCatalogCardTable amazonProducts={orderDetails.request.products ?? []} />
      {orderDetails.order && (
        <PriceDetailsCard
          shippingCents={Number(orderDetails.order.shippingCents)}
          subtotalCents={Number(orderDetails.order.subtotalCents)}
          taxCents={Number(orderDetails.order.taxCents)}
          totalCents={Number(orderDetails.order.totalCents)}
          currency={orderDetails.order.currency ?? 'USD'}
        />
      )}
    </div>
  </div>
);
