import type { AmazonOrderRequestProduct } from '@rye-com/rye-data-layer';
import { AmazonItemCatalogCard } from 'components/Orders/OrderDetailsCards/OrderDetailsCardTable/AmazonOrderDetailsCardTable/ItemCatalogCard/amazonItemCatalogCard';

interface ItemCatalogCardValue {
  amazonProducts: AmazonOrderRequestProduct[];
}

export const AmazonItemCatalogCardTable = (props: ItemCatalogCardValue) => {
  return (
    <table className="item-catalog-table">
      <tbody>
        <tr className="item-catalog-table-header-row">
          <th>Item</th>
          <th>Product</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
        {props.amazonProducts.map((amazonProduct) => (
          <AmazonItemCatalogCard
            key={amazonProduct.productId}
            quantityMap={props.amazonProducts}
            productId={amazonProduct.productId}
            price={amazonProduct.price}
          />
        ))}
      </tbody>
    </table>
  );
};
