import { ShopifyCheckoutRequest } from 'components/Orders/OrderDetailsCards/OrderDetailsCardTable/ShopifyOrderDetailsCardTable/shopifyOrderDetailsCardTable';
import type { AmazonOrder, CheckoutRequestFirestore } from '@rye-com/rye-data-layer';
import { AmazonCheckoutRequest } from 'components/Orders/OrderDetailsCards/OrderDetailsCardTable/AmazonOrderDetailsCardTable/amazonOrderDetailsCardTable';

export type OrderTracking = {
  trackingNumber: string | undefined;
  courierUrl: string | undefined;
  courierName: string | undefined;
};

export enum OrderStatus {
  OrderReceived = 'Order received',
  OrderDelivered = 'Order delivered',
  PartiallyDelivered = 'Order partially delivered',
  OrderPlaced = 'Order placed',
  OrderCancelled = 'Order cancelled',
  TrackingObtained = 'Tracking obtained',
  PartialTrackingObtained = 'Partial tracking obtained',
  OrderCancellationStarted = 'Order cancellation in progress',
  OrderCancellationRequested = 'Order cancellation requested',
  OrderPartiallyCancelled = 'Order partially cancelled',
}

export enum ShipmentStatus {
  Delivered = 'Delivered',
  Shipped = 'Shipped',
  NotShipped = 'Not Shipped',
}

enum ManualFulfillmentStatus {
  Archive = 'archive',
  Started = 'started',
  Tracking = 'tracking',
}

const getShopifyOrderStatus = (data: ShopifyCheckoutRequest) => {
  // Manual fulfillment
  if (
    data.manualFulfillment?.status === ManualFulfillmentStatus.Archive &&
    !data.refund &&
    !data.order?.totalCents
  ) {
    return OrderStatus.OrderCancelled;
  } else if (data.manualFulfillment?.status === ManualFulfillmentStatus.Started) {
    return OrderStatus.OrderReceived;
  } else if (data.manualFulfillment?.status === ManualFulfillmentStatus.Tracking) {
    return OrderStatus.OrderPlaced;
  }

  // Regular flow
  if (data.refund) {
    return OrderStatus.OrderCancelled;
  } else if (data.order) {
    if (data.order.fulfillments.some((x) => x.trackingDetails.length)) {
      return OrderStatus.TrackingObtained;
    } else if (data.order.totalCents) {
      return OrderStatus.OrderPlaced;
    }
  }

  return OrderStatus.OrderReceived;
};

const getAmazonOrderStatus = (data: AmazonCheckoutRequest) => {
  // Manual fulfillment
  if (
    data.manualFulfillment?.status === ManualFulfillmentStatus.Archive &&
    !data.refund &&
    !data.order?.totalCents
  ) {
    return OrderStatus.OrderCancelled;
  } else if (data.manualFulfillment?.status === ManualFulfillmentStatus.Started) {
    return OrderStatus.OrderReceived;
  } else if (data.manualFulfillment?.status === ManualFulfillmentStatus.Tracking) {
    return OrderStatus.OrderPlaced;
  }

  const amazonOrders = data.order?.amazonOrders;

  const allCancelled = amazonOrders?.every(
    (order) => order.cancellation?.status === 'ORDER_CANCEL_SUCCEEDED',
  );

  const someCancelled = amazonOrders?.every(
    (order) => order.cancellation?.status === 'ORDER_CANCEL_SUCCEEDED',
  );

  const someCancelInprogress = amazonOrders?.some(
    (order) => order.cancellation?.status === 'ORDER_CANCEL_STARTED',
  );

  const someCancelFailed = amazonOrders?.every(
    (order) => order.cancellation?.status === 'ORDER_CANCEL_FAILED',
  );

  const someCancelRequested = amazonOrders?.every(
    (order) => order.cancellation?.status === 'ORDER_CANCEL_REQUESTED',
  );

  // Regular flow
  if (data.refund || allCancelled) {
    return OrderStatus.OrderCancelled;
  } else if (someCancelInprogress) {
    return OrderStatus.OrderCancellationStarted;
  } else if (someCancelFailed && someCancelled) {
    return OrderStatus.OrderPartiallyCancelled;
  } else if (someCancelRequested) {
    return OrderStatus.OrderCancellationRequested;
  } else if (data.order) {
    if (data.order.amazonOrders) {
      const trackingObtained = data.order.amazonOrders.find((order) => {
        return order.shipments?.length;
      });
      const partialTrackingObtained = data.order.amazonOrders.find((order) => {
        return !order.shipments?.length;
      });

      const delivered = data.order.amazonOrders.every((order) => {
        return order.shipments?.every((shipment) => {
          return shipment.deliveryInformation.deliveryStatus === 'DELIVERED';
        });
      });

      const someDelivered = data.order.amazonOrders.every((order) => {
        const isSomeDelivered = order.shipments?.some((shipment) => {
          return shipment.deliveryInformation.deliveryStatus === 'DELIVERED';
        });

        const isSomeNotDelivered = order.shipments?.some((shipment) => {
          return (
            shipment.deliveryInformation.deliveryStatus === 'NOT_DELIVERED' ||
            shipment.deliveryInformation.deliveryStatus === 'NOT_AVAILABLE'
          );
        });

        return isSomeDelivered && isSomeNotDelivered;
      });

      if (delivered) {
        return OrderStatus.OrderDelivered;
      }

      if (someDelivered) {
        return OrderStatus.PartiallyDelivered;
      }

      if (trackingObtained) {
        return partialTrackingObtained
          ? OrderStatus.PartialTrackingObtained
          : OrderStatus.TrackingObtained;
      } else if (data.order.totalCents) {
        return OrderStatus.OrderPlaced;
      }
    }
  }

  return OrderStatus.OrderReceived;
};

export const getAmazonTrackingNumbers = (data: AmazonOrder[]) => {
  const trackingNumbers: OrderTracking[] = [];
  data.forEach((order) => {
    if (order.shipments?.length) {
      trackingNumbers.push(
        ...order.shipments.map((shipment) => ({
          trackingNumber: shipment.carrierTracking ?? '',
          courierUrl: shipment.carrierTrackingUrl ?? '',
          courierName: shipment.carrierName ?? '',
        })),
      );
    }
  });

  return trackingNumbers;
};

export const getOrderStatusLabelColor = (orderStatus: string) => {
  switch (orderStatus) {
    case OrderStatus.OrderCancelled:
      return 'details-label fail';
    default:
      return 'details-label success';
  }
};

export const getOrderStatus = (data: CheckoutRequestFirestore) => {
  if (data.marketplace === 'shopify') {
    return getShopifyOrderStatus(data);
  } else if (data.marketplace === 'amazon') {
    return getAmazonOrderStatus(data);
  }
  // Add more logic for marketplaces as required

  return '';
};
