import { ThemeMode } from 'providers/ThemeProvider';
import styled from 'styled-components';

export const AddPaymentMethod = styled.div`
  color: var(--contrast);
  cursor: pointer;
  &:hover {
    color: var(--contrast);
    text-decoration: underline;
  }
  display: flex;
  div {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;

export const PaymentContent = styled.div`
  padding: 32px 0 16px;
`;

export const Warning = styled.div`
  color: #a41111;
  padding: 16px 0 0;
`;

export const SubmitButton = styled.button<{ thememode: ThemeMode }>`
  background-color: ${({ thememode }) => (thememode === 'light' ? 'rgba(0,0,0,1)' : '#3d3e4c')};
  color: ${({ thememode }) => (thememode === 'light' ? '#ffffff' : '#ffffff')};
  border-radius: 8px;
  width: 160px;
  padding: 12px 0;
  cursor: pointer;
  &:hover {
    background-color: ${({ thememode }) => (thememode === 'light' ? 'rgba(0,0,0,0.8)' : '#4b4d5e')};
  }
`;

export const NameInput = styled.input``;
