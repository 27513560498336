import { GraphQLErrors } from '@apollo/client/errors';
import { FetchResult, useMutation } from '@apollo/client';
import { useState } from 'react';

import {
  AmazonOfferError,
  AmazonStoreError,
  CartError,
  CreateOrder_SubmitCartMutation,
  ShopifyOfferError,
  ShopifyStoreError,
  Store,
} from 'app/graphql/generated/cart/graphql';
import { CREATE_CART, SUBMIT_CART, UPDATE_BUYER_IDENTITY } from 'app/graphql/cart-api/index';
import { BuyerIdentityInput, Cart, CartItemsInput } from '../graphql';

export type UseCartErrors =
  | Array<CartError | AmazonStoreError | ShopifyStoreError | ShopifyOfferError | AmazonOfferError>
  | GraphQLErrors;

export interface CartState {
  cart: Cart | null;
  errors: UseCartErrors;

  isUpdating: boolean;
  isSubmitting: boolean;
}

export interface CartActions {
  createCart: (items: CartItemsInput) => Promise<void>;
  updateCartBuyerIdentity: (buyerIdentity: BuyerIdentityInput) => Promise<void>;
  // eslint-disable-next-line camelcase
  submitCart: () => Promise<FetchResult<CreateOrder_SubmitCartMutation>>;
}

type UseCartResult = [CartState, CartActions];

export function useCart(): UseCartResult {
  const [cart, setCart] = useState<Cart | null>(null);
  const [errors, setErrors] = useState<UseCartErrors>([]);

  const [createCart, { loading: isCreating }] = useMutation(CREATE_CART);
  const [updateBuyerIdentity, { loading: isUpdatingBuyerIdentity }] =
    useMutation(UPDATE_BUYER_IDENTITY);
  const [submitCart, { loading: isSubmitting }] = useMutation(SUBMIT_CART);

  const isUpdating = isCreating || isUpdatingBuyerIdentity;

  const actions: CartActions = {
    async createCart(items) {
      await createCart({
        variables: {
          input: {
            items,
            cartSettings: {
              amazonSettings: {
                hidePriceOnPackage: true,
              },
            },
          },
        },
      }).then((res) => {
        if (res.data) {
          setCart(res.data.createCart.cart);
          setErrors([
            ...res.data.createCart.errors,
            ...res.data.createCart.cart.stores.flatMap((store: Store) => store.errors),
            ...res.data.createCart.cart.stores.flatMap((store: Store) => store.offer?.errors ?? []),
          ]);
        } else if (res.errors) {
          setCart(null);
          setErrors(res.errors);
        }
      });
    },
    async updateCartBuyerIdentity(buyerIdentity) {
      if (cart) {
        await updateBuyerIdentity({
          variables: {
            input: {
              id: cart.id,
              buyerIdentity,
            },
          },
        }).then((res) => {
          if (res.data) {
            setCart(res.data.updateCartBuyerIdentity.cart);
            setErrors([
              ...res.data.updateCartBuyerIdentity.errors,
              ...res.data.updateCartBuyerIdentity.cart.stores.flatMap(
                (store: Store) => store.errors,
              ),
              ...res.data.updateCartBuyerIdentity.cart.stores.flatMap(
                (store: Store) => store.offer?.errors ?? [],
              ),
            ]);
          } else if (res.errors) {
            setCart(null);
            setErrors(res.errors);
          }
        });
      }
    },
    async submitCart() {
      if (cart) {
        const selectedShippingOptions = cart.stores.map((store) => ({
          store: store.store,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          shippingId: store.offer!.shippingMethods[0].id,
        }));

        return submitCart({
          variables: {
            input: { id: cart.id, selectedShippingOptions, token: 'free-payment' },
          },
        });
      }

      throw new Error('No cart! Panic.');
    },
  };

  return [{ cart, errors, isSubmitting, isUpdating }, actions];
}
