import styled from 'styled-components';

export const SectionContent = styled.div`
  display: block;
  width: 100%;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-top: 40px;
`;

export const Section = styled.section`
  display: block;
  width: 100%;
`;

export const SectionHeader = styled.header`
  margin-bottom: 16px;
`;
