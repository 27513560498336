import { DOMAIN_NAME } from 'config';
// import './style.scss';
import './style.css';

export const AffiliateDisclosurePage = () => {
  return (
    <div className="disclosure">
      <h2>
        <strong>Affiliate Disclaimer</strong>
      </h2>
      <span>
        <h3>WEBSITE DISCLAIMER</h3>
        <p>
          The information provided by Operator Labs Worldwide, Inc. ("we," "us," or "our") on
          https://{DOMAIN_NAME} (the "Site") and our mobile application is for general informational
          purposes only. All information on the Site and our mobile application is provided in good
          faith, however we make no representation or warranty of any kind, express or implied,
          regarding the accuracy, adequacy, validity, reliability, availability, or completeness of
          any information on the Site or our mobile application.
        </p>
        <p>
          UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY
          KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON
          ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND
          OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE
          APPLICATION IS SOLELY AT YOUR OWN RISK.
        </p>
      </span>
      <span>
        <h3>EXTERNAL LINKS DISCLAIMER</h3>
        <p>
          The Site and our mobile application may contain (or you may be sent through the Site or
          our mobile application) links to other websites or content belonging to or originating
          from third parties or links to websites and features in banners or other advertising. Such
          external links are not investigated, monitored, or checked for accuracy, adequacy,
          validity, reliability, availability, or completeness by us.
        </p>
        <p>
          WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR
          RELIABILITY OF ANY INFORMATION OFFERED BY THIRDPARTY WEBSITES LINKED THROUGH THE SITE OR
          ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY
          TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY
          PROVIDERS OF PRODUCTS OR SERVICES.
        </p>
      </span>
      <span>
        <h3>PROFESSIONAL DISCLAIMER</h3>
        <p>
          The Site cannot and does not contain medical/health advice. The medical/health information
          is provided for general informational and educational purposes only and is not a
          substitute for professional advice. Accordingly, before taking any actions based upon such
          information, we encourage you to consult with the appropriate professionals. We do not
          provide any kind of medical/health advice.
        </p>
        <p>
          THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THE SITE OR OUR MOBILE APPLICATION IS
          SOLELY AT YOUR OWN RISK.
        </p>
      </span>
      <span>
        <h3>AFFILIATES DISCLAIMER</h3>
        <p>
          The Site and our mobile application may contain links to affiliate websites, and we
          receive an affiliate commission for any purchases made by you on the affiliate website
          using such links. Our affiliates include the following:
        </p>
        <ul>
          <li>Awin (Affiliate Window)</li>
          <li>AvantLink</li>
          <li>CJ Affiliate by Conversant</li>
          <li>Clickbank</li>
          <li>eBay Partner Network</li>
          <li>FlexOffers</li>
          <li>iDevAffiliate</li>
          <li>MaxBounty</li>
          <li>Rakuten Affiliate Network</li>
          <li>RevenueWire</li>
          <li>ShareASale</li>
        </ul>
        <p>
          We are a participant in the Amazon Services LLC Associates Program, an affiliate
          advertising program designed to provide a means for us to earn advertising fees by linking
          to Amazon.com and affiliated websites.
        </p>
      </span>
      <span>
        <h3>TESTIMONIALS DISCLAIMER</h3>
        <p>
          The Site may contain testimonials by users of our products and/or services. These
          testimonials reflect the real-life experiences and opinions of such users. However, the
          experiences are personal to those particular users, and may not necessarily be
          representative of all users of our products and/or services. We do not claim, and you
          should not assume, that all users will have the same experiences. YOUR INDIVIDUAL RESULTS
          MAY VARY.
        </p>
        <p>
          The testimonials on the Site are submitted in various forms such as text, audio and/or
          video, and are reviewed by us before being posted. They appear on the Site verbatim as
          given by the users, except for the correction of grammar or typing errors. Some
          testimonials may have been shortened for the sake of brevity where the full testimonial
          contained extraneous information not relevant to the general public. The views and
          opinions contained in the testimonials belong solely to the individual user and do not
          reflect our views and opinions. Store owners are affiliates of the Rye
        </p>
        <p>
          Store Builder. Affiliates receive 100% of all commissions they generate as long as they
          meet a minimum amount as specified in the FAQs. The testimonials on the Site are not
          intended, nor should they be construed, as claims that our products and/or services can be
          used to diagnose, treat, mitigate, cure, prevent, or otherwise be used for any disease or
          medical condition. No testimonials have been clinically proven or evaluated.
        </p>
      </span>
    </div>
  );
};
