import './styles.scss';

interface RyeAPIDetailsCardValue {
  createdOn: string;
  orderName?: string;
  location: string;
  brand?: string;
  marketplace: string;
}

export const RyeAPIDetailsCard = (value: RyeAPIDetailsCardValue) => {
  return (
    <div className="details-card">
      <div className="details-card-header">Rye API details</div>
      <div className="details-row">
        <div className="details-row-title">Created on</div>
        <div className="details-row-data">{value.createdOn}</div>
      </div>
      {value.orderName && (
        <div className="details-row">
          <div className="details-row-title">Order name</div>
          <div className="details-row-data">{value.orderName}</div>
        </div>
      )}
      <div className="details-row">
        <div className="details-row-title">Location</div>
        <div className="details-row-data">{value.location}</div>
      </div>
      {value.brand && (
        <div className="details-row">
          <div className="details-row-title">Brand</div>
          <div className="details-row-data">{value.brand}</div>
        </div>
      )}
      <div className="details-row">
        <div className="details-row-title">Marketplace</div>
        <div className="details-row-data">{value.marketplace}</div>
      </div>
    </div>
  );
};
