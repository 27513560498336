import { OrderTracking } from 'utils/orderStatus';
import { TrackingInfo } from 'components/Orders/Tracking/TrackingInfo';

import './styles.scss';

interface OrderTrackingProps {
  orderTracking: OrderTracking[];
}

export const TrackingInfoList = ({ orderTracking }: OrderTrackingProps) => {
  return (
    <ul className="tracking-info-list">
      {orderTracking.map((tracking, index) => (
        <li key={`${tracking.trackingNumber}_${index}`}>
          <TrackingInfo tracking={tracking} />
        </li>
      ))}
    </ul>
  );
};
