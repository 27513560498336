import { useEffect } from 'react';
import { selectTheme, switchThemeInStore } from 'app/store/theme';
import { useAppDispatch, useAppSelector } from 'app/store';
import { useTheme as useShadCNTheme } from 'providers/ThemeProvider';

const useTheme = () => {
  const theme = useAppSelector(selectTheme);
  const { setTheme } = useShadCNTheme();

  const dispatch = useAppDispatch();

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
    setTheme(theme);
  }, [theme]);

  const switchTheme = () => {
    setTheme(theme);
    return dispatch(switchThemeInStore());
  };

  return { theme, switchTheme };
};

export default useTheme;
