import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import authAPI from 'app/auth';
import Layout from 'components/Layout';
import { defaultLoginRedirectFlow, useQuery } from 'routes';
import { trackSegmentPageEvent } from 'app/utils';

interface PrivateRouteProps {
  children: JSX.Element;
  fitted?: boolean;
  noLayout?: boolean;
}

const Loader = () => null;

export const PrivateRoute = ({
  children,
  fitted,
  noLayout,
}: PrivateRouteProps): JSX.Element | null => {
  const [user, loading] = useAuthState(authAPI.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const queryRedirect = useQuery().get('redirect');

  trackSegmentPageEvent(location.pathname);

  useEffect(() => {
    const fallback = () => {
      if (!user && queryRedirect) {
        navigate(`/login?redirect=${queryRedirect}`);
        return;
      } else if (!user) {
        let redirect = `?redirect=${location.pathname}`;
        if (location.pathname === '/login' || location.pathname === '/dashboard') {
          redirect = '';
        }
        navigate(`/login${redirect}`);
        return;
      }
    };

    defaultLoginRedirectFlow(navigate, loading, user, queryRedirect, fallback);
  }, [user, loading, navigate, queryRedirect]);

  return loading || queryRedirect ? (
    <Loader />
  ) : noLayout ? (
    <>{children}</>
  ) : (
    <>
      <Layout fitted={fitted}>{children}</Layout>
    </>
  );
};
