'use client';

import { ColumnDef } from '@tanstack/react-table';
import {
  BillingItemType,
  BillingSetting,
  Maybe,
  VolumeTier,
} from 'app/graphql/generated/admin/graphql';

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type ActivityItem = {
  id: number;
  amount: string;
  date: string;
  payer: string;
  receiver: string;
  type: string;
  orderId: string;
  billingSetting: BillingSetting;
  amountCents: number;
  volumeTiers?: Maybe<VolumeTier[]>;
  typeEnum: BillingItemType;
};

export const columns: ColumnDef<ActivityItem>[] = [
  {
    accessorKey: 'id',
    header: 'Id',
  },
  {
    accessorKey: 'date',
    header: 'Date',
  },
  {
    accessorKey: 'orderId',
    header: 'Order Id',
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
  },
  {
    accessorKey: 'payer',
    header: 'Payer',
  },
  {
    accessorKey: 'receiver',
    header: 'Receiver',
  },
  {
    accessorKey: 'type',
    header: 'Type',
  },
];
