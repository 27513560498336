import { Check, ChevronDown } from 'lucide-react';
// @ts-expect-error
import commandScore from 'command-score';

import { cn } from 'lib/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/ui/command';
import { ChangeEvent, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Button } from 'components/ui/button';

interface Option {
  label: string;
  value: string;
}

interface ComboboxProps {
  autoComplete?: string;
  disabled?: boolean;
  // emptyMessage?: string;
  name?: string;
  options: Option[];
  placeholder?: string;
  value: string;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
}

export function Combobox({
  autoComplete,
  disabled,
  name,
  options,
  placeholder,
  value,
  onChange,
}: ComboboxProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      {autoComplete && (
        <input
          aria-hidden
          autoComplete={autoComplete}
          disabled={disabled}
          name={name}
          type="text"
          tabIndex={-1}
          // `hidden` attribute prevents Chrome autofill
          className="fixed scale-0"
          onChange={(event) => {
            const rawValue = event.target.value;
            const option = options.find(
              (it) =>
                it.label.toLowerCase() === rawValue.toLowerCase() ||
                it.value.toLowerCase() === rawValue.toLowerCase(),
            );
            if (option) {
              onChange({
                // @ts-expect-error
                target: {
                  value: option.value,
                },
              });
            }
          }}
        />
      )}

      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant="outline"
          role="combobox"
          aria-expanded={isOpen}
          tabIndex={0}
          className={cn(
            'w-full justify-between px-3 py-2 font-normal',
            !value && 'text-muted-foreground opacity-80',
          )}
        >
          {value ? options.find((it) => it.value === value)?.label : placeholder}
          <ChevronDown
            className={cn('h-4 w-4 transition-transform', isOpen ? 'rotate-180' : 'rotate-0')}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="pointer-events-auto w-full p-0">
        <Command
          disablePointerSelection={false}
          filter={(value, search) => {
            if (!search) {
              return 1;
            }

            const country = options.find((it) => it.value === value);
            if (country) {
              return Math.max(
                commandScore(country.label, search),
                commandScore(country.value, search),
              );
            }

            return commandScore(value, search);
          }}
        >
          <CommandInput placeholder="Search country..." />
          <CommandList>
            <CommandEmpty>No countries found</CommandEmpty>
            <CommandGroup>
              {options.map((country) => (
                <CommandItem
                  className={value === country.value ? 'font-semibold' : undefined}
                  key={country.value}
                  value={country.value}
                  disabled={false}
                  onSelect={(currentValue) => {
                    onChange({
                      // @ts-expect-error
                      target: {
                        value: currentValue,
                      },
                    });
                    setIsOpen(false);
                  }}
                >
                  {country.label}

                  <span className="ml-auto">
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        value === country.value ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                  </span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
