import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';

import {
  Currency,
  Image,
  Marketplace,
  Price,
  CreateOrder_ProductByIdQuery,
} from 'app/graphql/generated/cart/graphql';
import { identifyURLMarketplace } from 'app/utils';
import { useEvent } from 'hooks/useEvent';

import { RyeProduct, RyeProductVariant } from '../graphql';
import { CREATE_ORDER_REQUEST_PRODUCT_BY_URL, GET_PRODUCT_BY_ID } from 'app/graphql';

interface UseProductImporterProps {
  onImported: (data: Merchandise) => void;
}

export interface Merchandise {
  id: string;
  title: string;
  description: string;
  images: Array<Image & { isPrimary: boolean }>;
  isAvailable: boolean;
  marketplace: Marketplace;
  price: Price | null;
  url: string;
  vendor: string | null;
}

export type UseProductImporterResult = ReturnType<typeof useProductImporter>;

export function useProductImporter(props: UseProductImporterProps) {
  const [error, setError] = useState('');
  const [url, setUrl] = useState('');
  const [variantId, setVariantId] = useState<string | null>(null);

  const [requestProduct, { loading: isRequesting }] = useMutation(
    CREATE_ORDER_REQUEST_PRODUCT_BY_URL,
  );
  const [fetchProduct, { data: product, loading: isFetching, startPolling, stopPolling }] =
    useLazyQuery(GET_PRODUCT_BY_ID);

  const isProductFetched = computeIsProductFetched(product);

  const onImported = useEvent(() => {
    if (!product?.productByID) {
      return;
    }

    props.onImported(
      mapRyeProductToMerchandise(
        product.productByID,
        url,
        ensureVariantId(variantId, product.productByID),
      ),
    );
  });

  const isLoading = isRequesting || isFetching || Boolean(product && !isProductFetched);

  useEffect(() => {
    if (isProductFetched) {
      stopPolling();
      onImported();
    }
  }, [isProductFetched, stopPolling, onImported]);

  async function importFromUrl(url: string) {
    setError('');
    setUrl(url);

    try {
      url = ensureProtocol(url);
    } catch (error) {
      setError((error as Error).message);
      return;
    }

    const marketplace = identifyURLMarketplace(url) as unknown as Marketplace;

    let variantId: string | null = null;
    if (marketplace === Marketplace.Shopify) {
      variantId = tryParseVariantId(url);
    }

    setVariantId(variantId);

    try {
      const response = await requestProduct({
        variables: {
          input: {
            marketplace,
            url,
          },
        },
      });
      if (response.errors && response.errors.length) {
        setError(`Error requesting product: ${JSON.stringify(response.errors[0].message)}`);
        return;
      }

      const productId = response.data?.requestProductByURL?.productID;
      if (!productId) {
        setError('Unrecognized product URL. This tool only supports Amazon and Shopify.');
        return;
      }

      fetchProduct({
        variables: { input: { id: productId, marketplace } },
      });
      startPolling(1_000);
    } catch (error) {
      setError((error as Error).message);
    }
  }

  const onSubmit = () => importFromUrl(url);

  return {
    error,
    input: { value: url, onChange: setUrl },
    isLoading,
    merchandise:
      isProductFetched && product?.productByID
        ? mapRyeProductToMerchandise(
            product.productByID,
            url,
            ensureVariantId(variantId, product.productByID),
          )
        : null,
    importFromUrl,
    onSubmit,
  };
}

function computeIsAvailable(product: RyeProduct, variant: RyeProductVariant | undefined): boolean {
  if (variant) {
    return 'isAvailable' in variant ? variant.isAvailable : product.isAvailable;
  } else {
    return product.isAvailable;
  }
}

// eslint-disable-next-line camelcase
function computeIsProductFetched(query: CreateOrder_ProductByIdQuery | undefined): boolean {
  const product = query?.productByID;
  if (!product) {
    return false;
  }

  if (product.__typename === 'ShopifyProduct') {
    return true;
  }

  // Workaround for Amazon products taking forever to load
  // https://rye-api.slack.com/archives/C06UA4XGU13/p1717788849170089
  return product.variants.length > 0 || product.images.length > 0;
}

class ProductURLError extends Error {}

function ensureProtocol(urlString: string): string {
  try {
    const url = new URL(urlString);
    if (url.protocol === 'http:' || url.protocol === 'https:') {
      return urlString;
    } else {
      throw new ProductURLError('Product URL must start with https://');
    }
  } catch (error) {
    if (error instanceof ProductURLError) {
      throw error;
    }

    try {
      new URL(`https://${urlString}`);
      return `https://${urlString}`;
    } catch {
      throw new ProductURLError('Please enter a valid product URL (https://...)');
    }
  }
}

function ensureVariantId(variantId: string | null, product: RyeProduct): string | null {
  if (product.marketplace !== Marketplace.Shopify) {
    return null;
  }
  return variantId ?? product.variants[0].id;
}

function mapRyeProductToMerchandise(
  product: RyeProduct,
  url: string,
  variantId: string | null,
): Merchandise {
  // Pretty horrible type case here, but it's necessary to avoid having the useless `AmazonVariant`
  // type cause problems for us down the line.
  const variant = product.variants.find((v) => v.id === variantId) as
    | Extract<RyeProduct['variants'][number], { __typename?: 'ShopifyVariant' }>
    | undefined;

  // Find primary image
  const images = product.images.map((image) => ({ ...image, isPrimary: false }));
  let primaryImageIndex = 0;
  if (variant?.image) {
    const variantImageIndex = images.findIndex((image) => image.url === variant.image.url);
    if (variantImageIndex >= 0) {
      primaryImageIndex = variantImageIndex;
    }
  }
  if (primaryImageIndex >= 0 && primaryImageIndex < images.length) {
    images[primaryImageIndex].isPrimary = true;
  }

  return {
    id: variantId ?? product.id,
    title: variant?.name ?? product.title,
    description: product.description.trim(),
    images,
    marketplace: product.marketplace,
    isAvailable: computeIsAvailable(product, variant),
    price: makePrice(product.price?.currency ?? Currency.Usd, variant?.priceCents ?? product.price),
    url,
    vendor: product.vendor,
  };
}

function makePrice(currency: Currency, priceOrCents: Price | number | null | undefined) {
  if (priceOrCents == null) {
    return null;
  }
  if (typeof priceOrCents !== 'number') {
    return priceOrCents;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumFractionDigits: 2,
  });

  return {
    currency,
    displayValue: formatter.format(priceOrCents / 100),
    value: priceOrCents,
  };
}

function tryParseVariantId(urlString: string): string | null {
  try {
    const url = new URL(urlString);
    return url.searchParams.get('variant');
  } catch {
    return null;
  }
}
