import { useState } from 'react';

import config, { PromoNotification } from 'config/constants';

type DisabledNotificationsInLS = { [key: string]: boolean };

const LOCAL_STORAGE_KEY = 'notifications_disabled';

const getItemFromLS = () => {
  const resp = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  return (resp && JSON.parse(resp)) || {};
};

// eslint-disable-next-line
const setItemToLS = (valueToStore: Object) => {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(valueToStore));
};

const getNotificationsToDisplay = (notifications: DisabledNotificationsInLS) => {
  const listToDisplay = config.promoNotifications
    .map((notification) => {
      const optOut = notifications[notification.id];
      return !optOut && notification;
    })
    .filter((n) => n) as PromoNotification[];
  return listToDisplay;
};

const getNotificationsInfo = () => {
  const notificationsFromLS = getItemFromLS();
  const notificationsToDisplay = getNotificationsToDisplay(notificationsFromLS);
  const unreadCount = notificationsToDisplay.length;
  return { notificationsFromLS, notificationsToDisplay, unreadCount };
};

const useNotifications = () => {
  const { notificationsFromLS, notificationsToDisplay, unreadCount } = getNotificationsInfo();
  const [notifications, setNotifications] = useState(notificationsFromLS);

  const disableNotification = (id: string) => {
    const newNotifications = { ...notifications, [id]: Date.now() };
    setItemToLS(newNotifications);
    setNotifications(newNotifications);
  };

  return {
    notificationsToDisplay,
    disableNotification,
    unreadCount,
  };
};

export default useNotifications;
