import { Container, EmptyState, Image, MerchantList } from './styles';
import { useAppSelector } from 'app/store';
import { MERCHANTS } from 'app/graphql';
import { useEffect, useMemo, useRef, useState } from 'react';
import { selectUserInfo } from 'app/store/user';
import { MerchantItem } from './components/MerchantItem';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Input } from 'components/ui/input';
import EmptyStateImage from 'assets/images/partners.png';
import { useQuery } from '@apollo/client';
import { generateAdminToken } from 'app/utils';
import { Skeleton } from 'components/ui/skeleton';

const Page = () => {
  const { adminToken } = useAppSelector(selectUserInfo) || {};
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const loadingRef = useRef<ReturnType<typeof setTimeout>>();
  const { data, loading: queryLoading } = useQuery(MERCHANTS, {
    context: {
      clientName: 'admin',
      headers: { authorization: generateAdminToken(adminToken ?? 'test') },
    },
    skip: !adminToken,
  });

  // Show loading state for 1.5 seconds for smoother ui transition
  useEffect(() => {
    if (queryLoading) {
      setLoading(queryLoading);
    }
    loadingRef.current = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(loadingRef.current);
    };
  }, [queryLoading]);

  const merchants = useMemo(() => {
    return (
      data?.merchantsByDeveloper.merchants.filter((merchant) => {
        return merchant.name.toLowerCase().includes(filter.toLowerCase());
      }) ?? []
    );
  }, [data, filter]);

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilter(value);
  };

  const isLoading = loading || queryLoading;

  return (
    <Container>
      <Card>
        <CardHeader className="flex-auto flex-row justify-between">
          <div className="space-y-1">
            <CardTitle> Merchants</CardTitle>
            <CardDescription>List of merchants onboarded via Sync API</CardDescription>
          </div>
          <Input
            placeholder="Search merchant partners..."
            className="w-80 justify-self-end"
            value={filter}
            onChange={handleFilter}
          />
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <MerchantList>
              {new Array(8).fill(1).map(() => (
                <Skeleton className="h-[284px] w-[284px]" />
              ))}
            </MerchantList>
          ) : (
            <MerchantList>
              {merchants.map((merchant) => (
                <MerchantItem {...merchant} />
              ))}
            </MerchantList>
          )}
          {merchants.length == 0 && !isLoading && (
            <EmptyState>
              <>
                <Image src={EmptyStateImage} />
                <CardTitle>
                  {merchants.length === 0 && !filter
                    ? 'No merchant partnerships yet'
                    : 'No merchants found...'}
                </CardTitle>
              </>
            </EmptyState>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default Page;
