import { OAUTH_REDIRECT_URI, RYE_AUTH_URL } from './constants';

export const ryeOAuth = async (code: string, developerId: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new URLSearchParams();
  urlencoded.append('code', code);
  urlencoded.append('redirectUri', OAUTH_REDIRECT_URI);
  urlencoded.append('developerId', developerId);

  const res = await fetch(`${RYE_AUTH_URL}/amazon/oauth/refresh_token`, {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
  });

  const json = await res.text();
  return { json };
};

// TODO: Remove this in favor of a revoke oauth endpoint on the server side
export const checkAmazonAuth = async (developerId: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const res = await fetch(`${RYE_AUTH_URL}/amazon/oauth/check_refresh_token`, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ developerId }),
  });

  const revoked = await res.text();
  return { revoked } as { revoked: 'ACTIVE' | 'REVOKED' };
};

export const requestAccountAccess = async (
  developerId: string,
  email: string,
  isAdditionalAccount: boolean,
) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const res = await fetch(`${RYE_AUTH_URL}/amazon/request_account_access`, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ developerId, email, isAdditionalAccount }),
  });

  return await res.text();
};
