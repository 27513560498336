'use client';

import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  PaginationState,
  Row,
  Updater,
  useReactTable,
} from '@tanstack/react-table';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { DataTablePagination } from './data-table-pagination';
import { DataTableToolbar, FacetOptions, Facets } from './data-table-toolbar';
import { useMemo, useState } from 'react';
import { Skeleton } from './skeleton';
import { DataTableRowActions, RowAction } from './data-table-row-actions';

interface DataTableProps<TData, TValue> {
  columns: (ColumnDef<TData, TValue> & { accessorKey?: string })[];
  data: TData[];
  pageSize?: number;
  onRowClicked?: (row: TData) => void;
  pageIndex?: number;
  pageCount?: number;
  onPaginationChange?: (data: PaginationState) => void;
  onColumnFiltersChange?: (data: ColumnFiltersState) => void;
  onRowActionClick?: (data: Row<TData>) => void;
  hiddenFacets?: string[];
  facetOptions?: FacetOptions;
  loading?: boolean;
  emptyState?: React.ReactNode;
  hideToolbar?: boolean;
  toolbarItems?: React.ReactNode;
  manualPagination?: boolean;
  rowActions?: RowAction[];
}

export function DataTable<TData, TValue>({
  columns,
  data,
  onRowClicked,
  pageSize,
  pageIndex = 0,
  pageCount = 1,
  hiddenFacets,
  facetOptions,
  loading,
  emptyState,
  onPaginationChange,
  onColumnFiltersChange,
  hideToolbar,
  toolbarItems,
  manualPagination = false,
  rowActions,
  onRowActionClick,
}: DataTableProps<TData, TValue>) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const manualPaginationSettings = manualPagination
    ? {
        pageCount,
        manualPagination: true,
        state: {
          pagination: {
            pageIndex,
            pageSize: pageSize ?? 10,
          },
          columnFilters,
        },
        onPaginationChange: (updater: Updater<PaginationState>) => {
          const newPagination =
            typeof updater === 'function'
              ? updater({ pageIndex, pageSize: pageSize ?? 10 })
              : updater;

          onPaginationChange?.(newPagination);
        },
      }
    : {};

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    ...manualPaginationSettings,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnFiltersChange: (updater) => {
      const filters = typeof updater === 'function' ? updater(columnFilters) : updater;
      setColumnFilters(filters);
      onColumnFiltersChange?.(filters);
    },
  });

  const facets = useMemo(() => {
    return columns
      .map(
        (column) =>
          ({
            label: column.header?.toString() ?? '',
            value: column.id ?? column.accessorKey ?? '',
          } satisfies Facets),
      )
      .filter((column) => !hiddenFacets?.includes(column.value));
  }, [columns]);

  const handleActionSelect = (row: Row<TData>) => () => {
    onRowActionClick?.(row);
  };

  return (
    <div className="space-y-4">
      {!hideToolbar && (
        <DataTableToolbar
          table={table}
          disableFiltering={true}
          facets={facets}
          facetOptions={facetOptions}
          toolbarItems={toolbarItems}
        />
      )}
      <div className="rounded-md border">
        <Table className="border-0">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="hover:bg-white">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="border-0 text-inherit">
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, index) => (
                <TableRow
                  key={row.id + index}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={() => onRowClicked?.(row.getAllCells()[0].row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="border-0 text-left">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                  {rowActions?.length && (
                    <TableCell className="border-0 text-left">
                      <DataTableRowActions
                        rowActions={rowActions}
                        onActionSelect={handleActionSelect(row)}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : loading ? (
              new Array((pageSize ?? 20) / 2).fill(1).map((_, index) => (
                <TableRow key={'data-row-' + index} className="hover:bg-white">
                  {table.getFlatHeaders().map((cells, index) => (
                    <TableCell key={cells.id} className="border-0 text-left">
                      <Skeleton className={`h-4 w-[${24 + index}px]`} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow className="hover:bg-white">
                <TableCell colSpan={columns.length} className="h-24 border-0 text-center">
                  {emptyState ? emptyState : 'No data available'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="mt-[24px]">
        <DataTablePagination table={table} />
      </div>
    </div>
  );
}
