import { filterUndefinedProps } from 'app/utils';
import { MutableRefObject, useRef } from 'react';

import './style.scss';

type CombinedRef = MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>;

interface FormInputProps {
  inputRef?: CombinedRef;
  id?: string; // for heap analytics mostly
  value?: string | number;
  defaultValue?: string | number;
  className?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  type: 'text' | 'email' | 'url' | 'number' | 'tel' | 'password';
  min?: number;
  max?: number;
  inputmode?: 'numeric';
  pattern?: string;
  required?: boolean;
  size?: number;
  maxLength?: number;
  autocomplete?: boolean;
  allowWhitespace?: boolean;
  onChange?: (value: string) => void;
  onBlur?: VoidFunction;
  multiline?: boolean;
  rows?: number;
}

const FormInput = (props: FormInputProps) => {
  const { allowWhitespace = false, ...inputProps } = props;
  const defaultInputRef = useRef<HTMLInputElement | null>(null);
  const defaultTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const ref = props.multiline
    ? props.inputRef || defaultTextareaRef
    : props.inputRef || defaultInputRef;
  const classNameList = ['from-input', inputProps.className].filter((i) => i).join(' ');
  const filteredProps = filterUndefinedProps(inputProps) as FormInputProps;
  const InputOrTextarea = props.multiline ? 'textarea' : 'input';
  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = e.target.value || '';
    if (!allowWhitespace) {
      value = value.trim();
    }
    inputProps?.onChange && inputProps.onChange(value);
  };
  return (
    <InputOrTextarea
      {...filteredProps}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={ref as any}
      rows={props.rows || 1} // Default to 1 rows if not specified
      autoComplete={props.autocomplete ? 'on' : 'off'}
      className={classNameList}
      onChange={onChange}
    />
  );
};

export default FormInput;
