import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '.';
import {
  AmazonBusinessAccount,
  amazonBusinessAccountsCollection,
} from 'app/firebase/collections/amazonBusinessAccounts';

/**
 * Reducers
 */
type AmazonBusinessAccountsState = {
  amazonBusinessAccounts: AmazonBusinessAccount[];
  activeAmazonBusinessAccountEmailId: string | undefined;
  defaultAmazonBusinessAccount: AmazonBusinessAccount | undefined;
};

const initialState: AmazonBusinessAccountsState = {
  amazonBusinessAccounts: [],
  activeAmazonBusinessAccountEmailId: undefined,
  defaultAmazonBusinessAccount: undefined,
};

const amazonBusinessAccountsSlice = createSlice({
  name: 'amazonBusinessAccounts',
  initialState,
  reducers: {
    loadAmazonBusinessAccounts: (state, action) => {
      state.amazonBusinessAccounts = action.payload;
    },
    loadActiveAmazonBusinessAccountEmailId: (state, action) => {
      state.activeAmazonBusinessAccountEmailId = action.payload;
    },
    loadDefaultAmazonBusinessAccount: (state, action) => {
      state.defaultAmazonBusinessAccount = action.payload;
    },
  },
});

/**
 * Actions
 */
const {
  loadAmazonBusinessAccounts,
  loadActiveAmazonBusinessAccountEmailId,
  loadDefaultAmazonBusinessAccount,
} = amazonBusinessAccountsSlice.actions;

export const fetchAmazonBusinessAccounts =
  (uid?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const userUid = uid || state.user.uid;
    const userId = state.user.documentId;

    if (userId && userUid) {
      const amazonBusinessAccounts =
        await amazonBusinessAccountsCollection.fetchAmazonBusinessAccountsByUid(userUid);
      dispatch(loadAmazonBusinessAccounts(amazonBusinessAccounts));
    }
  };

export const fetchActiveAmazonBusinessAccount =
  (uid?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const userUid = uid || state.user.uid;
    const userId = state.user.documentId;
    if (userId && userUid) {
      const activeAmazonBusinessAccountEmailId =
        await amazonBusinessAccountsCollection.fetchActiveAmazonBusinessAccountEmailIdByUid(
          userUid,
        );
      dispatch(loadActiveAmazonBusinessAccountEmailId(activeAmazonBusinessAccountEmailId));
    }
  };

export const fetchDefaultAmazonBusinessAccount =
  (uid?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const userUid = uid || state.user.uid;
    const userId = state.user.documentId;

    if (userId && userUid) {
      const activeAmazonBusinessAccountEmailId =
        await amazonBusinessAccountsCollection.fetchDefaultAmazonBusinessAccountByUid(userUid);
      dispatch(loadDefaultAmazonBusinessAccount(activeAmazonBusinessAccountEmailId));
    }
  };

/**
 * Selectors
 */
export const selectAmazonBusinessAccounts = (state: RootState) =>
  state.amazonBusinessAccounts.amazonBusinessAccounts;
export const selectActiveAmazonBusinessAccountEmailId = (state: RootState) =>
  state.amazonBusinessAccounts.activeAmazonBusinessAccountEmailId;
export const selectDefaultAmazonBusinessAccount = (state: RootState) =>
  state.amazonBusinessAccounts.defaultAmazonBusinessAccount;

export default amazonBusinessAccountsSlice.reducer;
