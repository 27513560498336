export enum SOURCE {
  LOGIN_FORM = 'login_form',
  SIGNUP_FORM = 'signup_form',

  CONSOLE_MENU = 'console_menu',

  API_KEY_MODULE = 'api_key_module',
  SPREEDLY_CONFIG_MODULE = 'spreedly_config_module',
  SCHEDULE_MODULE = 'schedule_module',
  WEBHOOK_MODULE = 'webhook_module',
  MARGIN_AMOUNT_MODULE = 'margin_amount_module',
  REQUEST_PRODUCT_URL_MODULE = 'request_product_url_module',
  REQUEST_STORE_URL_MODULE = 'request_store_url_module',
  DEVELOPER_RESOURCES_MODULE = 'developer_resources_module',
  JWT_MODULE = 'jwt_module',
}

export enum ACTION {
  CLICK = 'click',
  ERROR = 'error',
}
