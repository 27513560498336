import { ChevronLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';

import { Button } from 'components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';

import { Marketplace, OfferErrorCode, StoreErrorCode } from 'app/graphql/generated/cart/graphql';
import { Routes } from 'config/constants';

import { useCart } from './hooks/useCart';
import { Merchandise, useProductImporter } from './hooks/useProductImporter';
import { useRecentlyViewedProducts } from './hooks/useRecentlyViewedProducts';

import { ShippingDetailsForm } from './components/ShippingDetailsForm';
import { MerchandiseDetails } from './components/MerchandiseDetails';
import { ProductImporter } from './components/ProductImporter';
import { EmptyState } from './components/EmptyState';
import { RecentlyViewedProductsCard } from './components/RecentlyViewedProductsCard';

import { CartItemsInput } from './graphql';

export default function CreateOrderPage() {
  const [{ cart, errors: cartErrors, isSubmitting, isUpdating }, cartActions] = useCart();
  const [recentMerchandise, setRecentMerchandise] = useRecentlyViewedProducts();

  const importer = useProductImporter({
    onImported: (merchandise) => {
      const cartItems = mapProductToCartItem(merchandise);
      cartActions.createCart(cartItems);

      if (recentMerchandise) {
        const existingIndex = recentMerchandise.findIndex((it) => it.id === merchandise.id);
        if (existingIndex >= 0) {
          // Shift the existing merchandise to the front of the list
          const nextRecentMerchandise = [...recentMerchandise];
          nextRecentMerchandise.splice(existingIndex, 1);
          nextRecentMerchandise.unshift(merchandise);
          setRecentMerchandise(nextRecentMerchandise);
        } else {
          setRecentMerchandise([merchandise, ...recentMerchandise].slice(0, 10));
        }
      } else {
        setRecentMerchandise([merchandise]);
      }
    },
  });

  const merchandise = useMemo(
    () =>
      importer.merchandise
        ? {
            ...importer.merchandise,
            isAvailable:
              !cartErrors.some(
                (error) =>
                  'code' in error &&
                  (error.code === StoreErrorCode.ProductNotAvailable ||
                    error.code === StoreErrorCode.AddProductsFailed ||
                    error.code === StoreErrorCode.QuantityExceeded ||
                    error.code === OfferErrorCode.ProductsEmpty),
              ) && importer.merchandise.isAvailable,
          }
        : null,
    [importer.merchandise, cartErrors],
  );

  return (
    <div className="container mx-auto space-y-8">
      <Card className="shadow-md">
        <CardHeader>
          <CardTitle className="flex items-center gap-1">
            <Button asChild size="icon" variant="ghost">
              <Link to={Routes.Orders}>
                <ChevronLeft />
              </Link>
            </Button>{' '}
            Order Product
          </CardTitle>
        </CardHeader>

        <CardContent>
          <div className="flex flex-row gap-x-16">
            <div className="flex flex-1 flex-col gap-8">
              <ProductImporter {...importer} merchandise={merchandise} />

              {merchandise || importer.isLoading ? (
                <MerchandiseDetails merchandise={merchandise} />
              ) : (
                <EmptyState />
              )}
            </div>
            <div className="basis-1/3">
              <ShippingDetailsForm
                actions={cartActions}
                cart={cart}
                errors={cartErrors}
                merchandise={merchandise}
                isImporting={importer.isLoading}
                isUpdating={isUpdating}
                isSubmitting={isSubmitting}
              />
            </div>
          </div>
        </CardContent>
      </Card>

      <RecentlyViewedProductsCard importFromUrl={importer.importFromUrl} />
    </div>
  );
}

function mapProductToCartItem(merchandise: Merchandise): CartItemsInput {
  switch (merchandise.marketplace) {
    case Marketplace.Amazon:
      return {
        amazonCartItemsInput: [
          {
            productId: merchandise.id,
            quantity: 1,
          },
        ],
      };

    case Marketplace.Shopify: {
      return {
        shopifyCartItemsInput: [
          {
            quantity: 1,
            variantId: merchandise.id,
          },
        ],
      };
    }

    default:
      throw new Error('??');
  }
}
