import InfoBlock from 'components/InfoBlock';
import config, { PRODUCT_CSV_URL } from 'config/constants';
import { ReactComponent as BookIcon } from 'assets/icons/book.svg';
import { ReactComponent as RocketIcon } from 'assets/icons/rocket-launch-icon.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';

import './style.scss';
import { trackSegmentEvent } from 'app/utils';
import { ACTION, SOURCE } from 'app/analytics';
import { UnorderedListOutlined } from '@ant-design/icons';

const Page = () => {
  return (
    <>
      <div className="info-blocks">
        <div className="info-blocks-content">
          <InfoBlock
            title="Developer Documentation"
            onHandleClick={() =>
              trackSegmentEvent(SOURCE.DEVELOPER_RESOURCES_MODULE, ACTION.CLICK, 'dev_docs_link')
            }
            text="Learn how to use the Rye API. See common GraphQL queries, and how the API functions"
            linkPath={config.external.doc.home}
            linkTitle="Get started"
          />

          <InfoBlock
            title="Tutorial"
            text="An interactive tutorial with live code and step-by-step instructions on how to use the API"
            linkTitle="Play with the Rye Tutorial"
            onHandleClick={() =>
              trackSegmentEvent(SOURCE.DEVELOPER_RESOURCES_MODULE, ACTION.CLICK, 'tutorial_link')
            }
            linkPath={config.external.doc.tutorial}
            children={<BookIcon />}
          />

          <InfoBlock
            title="Seed Data"
            text="Download a CSV of products available on Rye. Use this data to get started building your own app on top of the Rye API"
            linkTitle="Download product CSV"
            onHandleClick={() =>
              trackSegmentEvent(
                SOURCE.DEVELOPER_RESOURCES_MODULE,
                ACTION.CLICK,
                'product_seed_data_download_link',
              )
            }
            linkPath={PRODUCT_CSV_URL}
            children={<RocketIcon />}
          />

          <InfoBlock
            title="Contact Us"
            text="We're here to help! Schedule a call with the Rye team to discuss your project and how we can help you get started"
            linkTitle="Schedule a call"
            onHandleClick={() =>
              trackSegmentEvent(
                SOURCE.DEVELOPER_RESOURCES_MODULE,
                ACTION.CLICK,
                'schedule_call_link',
              )
            }
            linkPath="https://calendly.com/tam-rye/onboard-with-rye"
            children={<PhoneIcon />}
          />
          <InfoBlock
            title="Sell Anything API Brands"
            text="Contact us for the list of Sell Anything API brands and commissions."
            linkTitle="Contact us"
            onHandleClick={() =>
              trackSegmentEvent(
                SOURCE.DEVELOPER_RESOURCES_MODULE,
                ACTION.CLICK,
                'view_sell_anything_spreadsheet',
              )
            }
            linkPath="mailto:support@rye.com"
            children={<UnorderedListOutlined style={{ fontSize: '24px' }} />}
          />
        </div>
      </div>
    </>
  );
};

export default Page;
