import styled from 'styled-components';

export const Title = styled.h2`
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 50;
  left: 0;
  right: 0;
`;

export const Grouped = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
