import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
`;
export const Breakdown = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
export const BreakdownItem = styled.li`
  width: 100%;
`;
export const BreakdownItemContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const BreakdownDivider = styled.div`
  flex-grow: 1;
  border-bottom: 2px dashed #bbb;
  align-self: center;
  margin: 0 10px;
`;
