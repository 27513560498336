import React, { useState } from 'react';
import './style.scss';

type DropdownOption = {
  label: string;
  value?: string;
};

type DropdownProps = {
  options: DropdownOption[];
  value: string;
  onSelect?: (label: string) => void;
};

const Dropdown = ({ options, value, onSelect }: DropdownProps) => {
  const [selectedValue, setSelectedValue] = useState<string>(value);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedValue(selectedValue);

    if (onSelect) {
      onSelect(selectedValue);
    }
  };

  return (
    <div className="dropdown">
      <select value={selectedValue} onChange={handleSelectChange}>
        {options.map((opt, index) => (
          <option key={opt.value || index} value={opt.value || index.toString()}>
            {opt.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
