import { Merchant, MerchantCommissionType } from 'app/graphql/generated/admin/graphql';
import { Avatar, AvatarImage } from 'components/ui/avatar';
import { Card, CardContent, CardHeader } from 'components/ui/card';
import { Separator } from 'components/ui/separator';
import { MerchantInfo } from '../styles';
import { useMemo } from 'react';

export function MerchantItem({ name, domain, commissionType, commissionRate, logo }: Merchant) {
  const getFallbackImage = (domain: string) => {
    if (domain.includes('.myshopify.com')) {
      return 'shopify.com';
    }
    return domain;
  };

  const commissionTypeLabel = useMemo(() => {
    if (commissionType === MerchantCommissionType.Discount) {
      return 'Discount commissions';
    }

    return 'Standard commissions';
  }, []);

  return (
    <Card>
      <CardHeader>
        <MerchantInfo>
          <Avatar className="h-20 w-20" style={{ border: '1px solid #ddd' }}>
            <AvatarImage src={logo} alt="merchantLogo" style={{ objectFit: 'contain' }} />
            <AvatarImage
              src={`https://logo.clearbit.com/${getFallbackImage(domain)}`}
              alt="shopifyLogo"
              style={{
                objectFit: 'contain',
                filter: !domain.includes('.myshopify.com')
                  ? 'sepia(100%) saturate(200%) brightness(70%) hue-rotate(330deg)'
                  : undefined,
              }}
            />
          </Avatar>
          <div className="space-y-1" style={{ width: 'calc(100% - 80px)' }}>
            <h4 className="w-full break-words text-sm font-bold leading-none">{name}</h4>
            <p className="w-full break-words text-sm text-muted-foreground">{domain}</p>
          </div>
        </MerchantInfo>
      </CardHeader>
      <CardContent>
        <Separator className="my-4" />
        <div className="flex h-5 items-center space-x-4 text-sm">
          <div className="text-muted-foreground">{commissionRate}% rate</div>
          <Separator orientation="vertical" />
          <div className="text-muted-foreground">{commissionTypeLabel}</div>
          {/* <Separator orientation="vertical" />
        <div>Source</div> */}
        </div>
      </CardContent>
    </Card>
  );
}
