import { EnvSchemaTypes, env } from 'config/env';

const authUrls: Record<EnvSchemaTypes['REACT_APP_NODE_ENV'], string> = {
  development: 'http://localhost:8093',
  staging: 'https://staging.core-v1.api.rye.com',
  production: 'https://core-v1.api.rye.com',
};

const oauthRedirectUris: Record<EnvSchemaTypes['REACT_APP_NODE_ENV'], string> = {
  development: 'https://dev.rye.com/account',
  staging: 'https://staging.console.rye.com/account',
  production: 'https://console.rye.com/account',
};

export const RYE_AUTH_URL = authUrls[env.REACT_APP_NODE_ENV];
export const AMAZON_AUTH_URL = `https://www.amazon.com/b2b/abws/oauth?state=100&redirect_uri=${
  oauthRedirectUris[env.REACT_APP_NODE_ENV]
}&applicationId=amzn1.sp.solution.51ea11eb-60aa-4205-9d91-00e67344b848`;
export const RYE_AMAZON_BUSINESS_GROUP_ID = 'rye-ordering';
export const RYE_AMAZON_BUSINESS_EMAIL = 'amazonorders@rye.com';
export const OAUTH_REDIRECT_URI = oauthRedirectUris[env.REACT_APP_NODE_ENV];

export const RYE_AMAZON_BUSINESS_GROUP_ID_STAGING = 'OrderingAPI_TestGroup7515057495';
