import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';

import { cn } from 'lib/utils';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';

import { UseProductImporterResult } from '../hooks/useProductImporter';

export function ProductImporter({
  error: errorProp,
  input,
  isLoading,
  merchandise,
  onSubmit,
}: UseProductImporterResult) {
  const [error, setError] = useState('');

  useEffect(() => {
    if (errorProp) setError(errorProp);
  }, [errorProp]);

  return (
    <div className="w-4/5">
      <form
        className="flex flex-row gap-2"
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
      >
        <Input
          className={cn('w-full flex-1', error && 'border-destructive')}
          disabled={isLoading}
          placeholder="Enter product URL (https://...)"
          value={input.value}
          onChange={(e) => input.onChange(e.target.value)}
          onFocus={() => setError('')}
        />
        <Button
          className={merchandise ? undefined : 'animate-pulsing'}
          disabled={isLoading}
          type="submit"
        >
          Import Product
          {isLoading && (
            <Loader2
              className={cn('ml-2 h-4 w-4 animate-spin', isLoading ? 'opacity-100' : 'opacity-0')}
            />
          )}
        </Button>
      </form>
      <p className={cn('text-xs', error ? 'text-destructive' : 'text-muted-foreground')}>
        {error || 'Currently only supports products from Amazon/Shopify'}
      </p>
    </div>
  );
}
