'use client';

import { ColumnDef } from '@tanstack/react-table';

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type InvoiceItem = {
  id: string;
  index: number;
  dueDate: string;
  chargeDate?: string | null;
  billingPeriod: string;
  amountDue: string;
  status: string;
  startDate: string;
  endDate: string;
};

export const columns: ColumnDef<InvoiceItem>[] = [
  {
    accessorKey: 'id',
    header: 'Invoice #',
  },
  {
    accessorKey: 'billingPeriod',
    header: 'Billing Period',
  },
  {
    accessorKey: 'amountDue',
    header: 'Amount Due',
  },
  {
    accessorKey: 'dueDate',
    header: 'Due Date',
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
];
