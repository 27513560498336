import React from 'react';

import './style.scss';

interface FormButtonProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  onMouseLeave?: (e: React.SyntheticEvent) => void;
  blank?: boolean; // use types enum instead
  contrast?: boolean; // use types enum instead
  disabled?: boolean;
  id?: string; // for heap analytics mostly
  type?: 'button' | 'submit' | 'reset';
}

const FormButton = ({
  id,
  blank,
  contrast,
  className,
  children,
  title,
  disabled = false,
  onClick = () => {
    return;
  },
  onMouseLeave = () => {
    return;
  },
  type = 'submit',
}: FormButtonProps) => {
  const classNameList = [
    'from-button',
    blank ? 'blank' : '',
    contrast ? 'contrast' : '',
    className ? className : '',
  ]
    .filter((i) => i)
    .join(' ');

  return (
    <button
      title={title}
      id={id}
      className={classNameList}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

export default FormButton;
