import { Environment } from 'generated/hypertune';
import { z } from 'zod';

// Parse env vars and fail immediately if not set properly
const envSchema = z.object({
  // We're using REACT_APP_NODE_ENV instead of NODE_ENV because when we build and deploy, NODE_ENV automatically gets set to "production"
  // We want to be able to deploy to `staging` without overwriting any of the default NODE_ENV behavior that other parts of the app or deploy might rely on
  // We have to prefix it with REACT_APP to make sure CRA doesn't eliminate it from the build https://create-react-app.dev/docs/adding-custom-environment-variables/
  REACT_APP_NODE_ENV: z.enum(['development', 'staging', 'production']),
  REACT_APP_STRIPE_KEY: z.string(),
  REACT_APP_HYPERTUNE_TOKEN: z.string(),
});

export type EnvSchemaTypes = z.infer<typeof envSchema>;

// Import `env` object from this file to use in other files rather than accessing `process.env` directly
// You'll get type safety and autocomplete for free!
export const env = envSchema.parse(process.env);

export const getHypertuneEnv = (): Environment => {
  switch (env.REACT_APP_NODE_ENV) {
    case 'development':
      return 'DEVELOPMENT';
    case 'staging':
      return 'STAGING';
    default:
      return 'PRODUCTION';
  }
};
