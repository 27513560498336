import './styles.scss';
import { getOrderStatusLabelColor, OrderStatus, OrderTracking } from 'utils/orderStatus';
import { TrackingInfoList } from '../../Tracking/TrackingInfoList';

interface StatusDetailsCardValue {
  orderTracking: OrderTracking[];
  orderStatus: string;
}

export function StatusDetailsCard(value: StatusDetailsCardValue) {
  // If the order has been cancelled, it doesn't make sense to say the tracking info isn't available yet
  const trackingFallbackMessage =
    value.orderStatus === OrderStatus.OrderCancelled ? 'N/A' : 'Not yet available';

  return (
    <div className="details-card">
      <div className="details-card-header">Status</div>
      <div className="details-row">
        <div className="details-row-title">Tracking</div>
        <div className="details-row-data">
          {value.orderTracking.length > 0 ? (
            <TrackingInfoList orderTracking={value.orderTracking} />
          ) : (
            trackingFallbackMessage
          )}
        </div>
      </div>
      <div className="details-row">
        <div className="details-row-title">Order Status</div>
        <div className="details-row-data">
          <div className={getOrderStatusLabelColor(value.orderStatus)}>{value.orderStatus}</div>
        </div>
      </div>
    </div>
  );
}
