import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import { ReactComponent as EmptyBox } from 'assets/icons/empty-box.svg';
import { Button } from 'components/ui/button';
import config, { Routes } from 'config/constants';
import { useHypertune } from 'generated/hypertune.react';
import { Link } from 'react-router-dom';

export const NoOrdersPage = () => {
  const hypertune = useHypertune();
  const { isReadOnly } = useAppSelector(selectUserInfo) || {};

  const hasCreateOrdersPage = hypertune.viewCreateOrder({ fallback: false });

  return (
    <div className="order-no-orders">
      <div className="order-no-orders-box">
        <EmptyBox className="order-details-left-arrow-icon" />
      </div>
      <div className="order-no-orders-text">
        <div className="order-no-orders-text-header">No orders yet</div>
        <div className="flex flex-row gap-2">
          <Button asChild variant={hasCreateOrdersPage ? 'link' : 'default'}>
            <a href={config.external.doc.getStartedDocs} rel="noreferrer" target="_blank">
              View docs
            </a>
          </Button>
          {hasCreateOrdersPage && !isReadOnly && (
            <Button asChild>
              <Link to={Routes.CreateOrder}>Create order</Link>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
