import { Check, Copy } from 'lucide-react';
import { useState } from 'react';
import { type VariantProps, cva } from 'class-variance-authority';

import { cn } from 'lib/utils';
import { Badge } from 'components/ui/badge';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';

import { Merchandise } from '../hooks/useProductImporter';

import { DownloadImagesButton } from './DownloadImagesButton';
import { MerchandiseCard } from './MerchandiseCard';
import { MerchandiseCarousel, MerchandiseCarouselLoading } from './MerchandiseCarousel';
import { Skeleton } from 'components/ui/skeleton';

interface MerchandiseDetailsProps {
  merchandise: Merchandise | null;
}

export function MerchandiseDetails({ merchandise }: MerchandiseDetailsProps) {
  return (
    <MerchandiseCard>
      <div className="flex flex-1 flex-row gap-x-4">
        {merchandise ? (
          <MerchandiseCarousel images={merchandise.images} />
        ) : (
          <MerchandiseCarouselLoading />
        )}
        <div className="flex-1">
          {merchandise && (
            <div className="mb-2">
              <DownloadImagesButton
                images={merchandise.images}
                merchandiseTitle={merchandise.title}
              />
            </div>
          )}
          <h2 className="group max-w-[90%] scroll-m-20 pb-2 text-2xl font-semibold tracking-tight transition-colors first:mt-0">
            {merchandise ? (
              <>
                {merchandise.title} <CopyButton className="h-5 w-5" value={merchandise.title} />
              </>
            ) : (
              <Skeleton className="h-full w-full">&nbsp;</Skeleton>
            )}
          </h2>
          <div className="flex flex-col gap-2">
            {merchandise && !merchandise.isAvailable && (
              <Badge className="w-fit" variant="destructive">
                Out of stock
              </Badge>
            )}
            {merchandise ? (
              merchandise.price && (
                <Field label="Price" size="lg">
                  {merchandise.price.displayValue}
                </Field>
              )
            ) : (
              <FieldLoading size="lg" />
            )}
            {merchandise ? (
              merchandise.vendor && <Field label="Brand">{merchandise.vendor}</Field>
            ) : (
              <FieldLoading />
            )}
            {merchandise ? (
              merchandise.description && (
                <Field label="Description">{merchandise.description}</Field>
              )
            ) : (
              <FieldLoading long />
            )}
          </div>
        </div>
      </div>
    </MerchandiseCard>
  );
}

function CopyButton({ className, value }: { className?: string; value: string }) {
  const [didCopy, setDidCopy] = useState(false);

  const Icon = didCopy ? Check : Copy;
  function handleCopy() {
    setDidCopy(true);
    navigator.clipboard.writeText(value);
    setTimeout(() => setDidCopy(false), 2_000);
  }

  return (
    <Tooltip>
      <TooltipTrigger>
        <Icon
          className={cn(
            'hidden h-3 w-3 cursor-pointer text-muted-foreground hover:text-foreground group-hover:inline-block',
            className,
          )}
          role="button"
          onClick={handleCopy}
        />
      </TooltipTrigger>
      <TooltipContent>
        <p className="font-normal normal-case tracking-normal">
          {didCopy ? 'Copied!' : 'Click to copy'}
        </p>
      </TooltipContent>
    </Tooltip>
  );
}

interface MerchandiseFieldProps extends VariantProps<typeof fieldVariants> {
  children: string;
  label: string;
}

const fieldVariants = cva('', {
  variants: {
    size: {
      default: '',
      lg: 'text-lg font-semibold',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

function Field({ children, label, ...rest }: MerchandiseFieldProps) {
  return (
    <div className="max-w-lg space-y-1">
      <p className="group flex items-center gap-1 text-sm font-medium uppercase text-muted-foreground">
        {label} <CopyButton value={children} />
      </p>
      <p className={fieldVariants(rest)}>{children}</p>
    </div>
  );
}

function FieldLoading({ long, size }: { long?: boolean } & VariantProps<typeof fieldVariants>) {
  const N_LINES = long ? 4 : 1;
  return (
    <div className="max-w-lg space-y-1">
      <Skeleton className="w-1/5">
        <p className="group flex items-center gap-1 text-sm font-medium uppercase text-muted-foreground">
          &nbsp;
        </p>
      </Skeleton>
      {Array.from({ length: N_LINES }).map((_, index) => (
        <Skeleton key={index} className={index + 1 === N_LINES ? 'w-1/2' : undefined}>
          <p className={fieldVariants({ size })}>&nbsp;</p>
        </Skeleton>
      ))}
    </div>
  );
}
