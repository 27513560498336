import { ReactElement, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store';
import {
  ITEMS_PER_PAGE,
  Pagination,
  fetchInventoryRequests,
  selectInventoryRequests,
  selectInventoryRequestsLoading,
  selectPage,
  selectPageInfo,
} from 'app/store/inventoryRequests';
import { localDateFormatter, localTimeFormatter } from 'app/utils';
import PreloaderIcon from 'components/Preloader';
import { InventoryRequestsStoreModel } from 'app/firebase/collections/inventoryRequests';

import './style.scss';
import { selectUserInfo } from 'app/store/user';

const getPageInfo = (list: InventoryRequestsStoreModel[], { total, selected }: Pagination) => {
  const pages = list.length > ITEMS_PER_PAGE ? Array.from(Array(total).keys()) : [];
  const from = ITEMS_PER_PAGE * selected;
  const to = from + ITEMS_PER_PAGE;
  const items = list.slice(from, to);
  return { pages, items };
};

const SearchResultsTable = () => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(selectInventoryRequests) || [];
  const isLoading = useAppSelector(selectInventoryRequestsLoading);
  const pageInfo = useAppSelector(selectPageInfo);
  const { pages, items } = useMemo(() => getPageInfo(list, pageInfo), [list, pageInfo]);

  // Make sure we load the Request History on page load
  const user = useAppSelector(selectUserInfo);
  useEffect(() => {
    dispatch(fetchInventoryRequests(user?.uid || ''));
  }, [user]);

  const parseURL = (url: string): string | ReactElement => {
    try {
      const parsedURL = new URL(url);
      const urlStr = parsedURL.toString();
      return (
        <a target="_blank" rel="noopener noreferrer" href={urlStr} className="search-results-url">
          {urlStr}
        </a>
      );
    } catch {
      return url;
    }
  };

  const handlePageSelect = (pageNumber: number) => {
    dispatch(selectPage(pageNumber));
  };

  return (
    <div className="search-results-wrap">
      {isLoading && (
        <div className="search-results-preloader">
          <PreloaderIcon />
        </div>
      )}
      {items.length ? (
        <>
          <table className="search-results">
            <thead>
              <tr>
                <th>Request ID</th>
                <th>Date</th>
                <th>Request Type</th>
                <th>Requested Data</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.requestedAtUTS}>
                  <td>{item.id}</td>
                  <td title={localTimeFormatter(item.requestedAtUTS)}>
                    {localDateFormatter(item.requestedAtUTS)}
                  </td>
                  <td className="no-wrap">{item.requestType}</td>
                  <td>{parseURL(item.data)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {pages.length ? (
            <div className="table-page-wrap">
              <div className="table-page-scroll">
                <ul className="table-page">
                  {pages.map((num) => (
                    <li
                      key={`table-page-${num}`}
                      onClick={() => handlePageSelect(num)}
                      className={
                        num === pageInfo.selected ? 'table-page-item selected' : 'table-page-item'
                      }
                    >
                      {num + 1}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        !isLoading && <i>Nothing here yet</i>
      )}
    </div>
  );
};

export default SearchResultsTable;
