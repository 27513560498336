import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import RyeLogo from 'components/Logo';
import useAuth from 'hooks/useAuth';
import { defaultLoginRedirectFlow, useQuery } from 'routes';
import config, { Routes } from 'config/constants';
import FormInput from 'components/FormInput';
import FormButton from 'components/FormButton';

import './style.scss';

const DEFAULT_VALUE = '';

const ResetPage = () => {
  const queryRedirect = useQuery().get('redirect');
  const [email, setEmail] = useState(DEFAULT_VALUE);
  const { user, error, loading, sendPasswordReset } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fallback = () => {
      if (user) {
        navigate(config.routeDefaultHome);
        return;
      }
    };

    defaultLoginRedirectFlow(navigate, loading, user, queryRedirect, fallback);
  }, [user, loading, navigate, queryRedirect]);

  useEffect(() => {
    error && alert(error);
  }, [error]);

  const handleOnSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    sendPasswordReset(email);
    setEmail(DEFAULT_VALUE);
  };

  if (loading || (user && queryRedirect)) return null;

  return (
    <form className="reset-auth" onSubmit={handleOnSubmit}>
      <div className="reset-auth-container">
        <RyeLogo to={Routes.Login} />
        <div className="reset-auth-header">Recover Password</div>

        <FormInput
          className="reset-auth-input"
          id="heap-input-reset-auth-email"
          placeholder="Enter Your E-mail Address"
          type="email"
          name="email"
          value={email}
          onChange={setEmail}
          required
        />
        <FormButton type="submit">Send Instructions</FormButton>

        <div className="reset-auth-back-link">
          <Link to={Routes.Login}>Back to sign in</Link>
        </div>
        <small className="reset-auth-terms">
          By clicking “Continue with Google/Email” above, you acknowledge that you have read and
          understood, and agree to Rye's{' '}
          <a href={config.external.terms} target="_blank">
            Terms and Conditions
          </a>{' '}
          and{' '}
          <a href={config.external.privacyPolicy} target="_blank">
            Privacy Policy
          </a>
          .
        </small>
      </div>
    </form>
  );
};

export default ResetPage;
