import { useEffect, useState } from 'react';

import { Tag, TagInput, TagInputProps } from 'emblor';
import { isEmail } from 'class-validator';

type Props = Omit<
  TagInputProps,
  'tags' | 'setTags' | 'setActiveTag' | 'setActiveTagIndex' | 'activeTagIndex'
> & {
  emails?: string[];
  setEmails: (emails: string[]) => void;
};

export const EmailTagInput = ({ emails, setEmails, ...otherProps }: Props) => {
  const [emailsState, setEmailStates] = useState<Tag[]>(
    emails?.map((email) => ({ id: email, text: email })) || [],
  );

  useEffect(() => {
    setEmails(emailsState.map((email) => email.text));
  }, [emailsState]);

  const [activeTag, setActiveTag] = useState<number | null>(null);

  return (
    <TagInput
      tags={emailsState}
      setTags={(updatedEmails) => {
        setEmailStates(updatedEmails);
      }}
      disabled={otherProps.disabled}
      readOnly={otherProps.readOnly}
      addTagsOnBlur={true}
      inputProps={{ inputMode: 'email' }}
      placeholder="Add email address"
      styleClasses={{
        input: 'w-full sm:max-w-[350px] shadow-none',
        tag: {
          body: 'bg-[rgba(78,213,88,35%)] text-gray-950 border-0 hover:bg-[rgba(78,213,88,35%)]',
        },
        tagList: { container: 'flex-wrap' },
      }}
      activeTagIndex={activeTag}
      setActiveTagIndex={setActiveTag}
      variant="primary"
      validateTag={(tag) => {
        return isEmail(tag);
      }}
    />
  );
};
