import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { useRecentlyViewedProducts } from '../hooks/useRecentlyViewedProducts';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel';

export function RecentlyViewedProductsCard({
  importFromUrl,
}: {
  importFromUrl: (url: string) => Promise<void>;
}) {
  const [merchandise] = useRecentlyViewedProducts();

  if (!merchandise || !merchandise.length) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Recently Viewed Products</CardTitle>
      </CardHeader>
      <CardContent>
        <Carousel className="h-74 w-full">
          <CarouselContent>
            {merchandise.map((merchandise) => (
              <CarouselItem
                key={merchandise.id}
                className="w-fit basis-full cursor-pointer overflow-hidden rounded-lg sm:basis-1/2 md:basis-1/3 lg:basis-1/4 xl:basis-1/5"
                role="button"
                onClick={() => {
                  importFromUrl(merchandise.url);
                }}
              >
                <Card className="flex flex-col overflow-hidden rounded-lg transition-all hover:border-primary">
                  <img
                    className="aspect-square w-full"
                    src={merchandise.images.find((it) => it.isPrimary)?.url}
                    alt=""
                  />
                  <div className="space-y-1 p-2">
                    <p className="text-sm font-semibold">{merchandise.title}</p>
                    <p className="text-base">
                      {merchandise.price?.displayValue ?? 'Unknown price'}
                    </p>
                  </div>
                </Card>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselNext />
          <CarouselPrevious />
        </Carousel>
      </CardContent>
    </Card>
  );
}
