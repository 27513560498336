import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useState } from 'react';

import { Download, Loader2 } from 'lucide-react';

import { Button } from 'components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { cn } from 'lib/utils';

interface DownloadImagesButtonProps {
  images: Array<{ url: string }>;
  merchandiseTitle: string;
}

export function DownloadImagesButton({ images, merchandiseTitle }: DownloadImagesButtonProps) {
  const [isLoading, setIsLoading] = useState(false);

  const Icon = isLoading ? Loader2 : Download;

  async function download() {
    setIsLoading(true);
    try {
      const zip = new JSZip();
      const blobs = await Promise.all(
        images.map((image) => fetch(image.url).then((res) => res.blob())),
      );
      blobs.forEach((blob, index) => {
        zip.file(`${index + 1}.${extname(images[index].url)}`, blob);
      });

      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, `${asSafeFileName(merchandiseTitle)}-images.zip`);
    } catch (error) {
      alert('Error saving files');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button disabled={isLoading} variant="outline" size="icon" onClick={download}>
          <Icon className={cn('h-4 w-4', isLoading && 'animate-spin')} />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <p>Download images</p>
      </TooltipContent>
    </Tooltip>
  );
}

function asSafeFileName(name: string) {
  return name.replace(/[^a-zA-Z0-9]/gi, '_').toLowerCase();
}

function extname(urlString: string) {
  // Remove query string parameters
  const url = new URL(urlString);
  const pathname = url.pathname;

  return pathname.split('.').pop();
}
