import './style.scss';

interface Item {
  title: string;
  value: string;
  selected?: boolean;
}

interface FormButtonProps {
  id?: string; // for heap analytics mostly
  listItems: Item[];
  className?: string;
  disabled?: boolean;
  selectedDefaultValue: string | number | readonly string[] | undefined;
  onChange: (value: string) => void;
}

const FormSelect = ({
  listItems,
  className = '',
  disabled = false,
  onChange = () => {
    return;
  },
  selectedDefaultValue,
}: FormButtonProps) => {
  const classNameList = ['from-select-wrapper', className].filter((i) => i).join(' ');

  return (
    <label className={classNameList}>
      <select
        className="from-select"
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        value={selectedDefaultValue}
      >
        {listItems.map(({ value, title }) => (
          <option key={value + title} value={value}>
            {title}
          </option>
        ))}
      </select>
    </label>
  );
};

export default FormSelect;
