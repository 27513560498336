import { ReactComponent as GoogleIcon } from 'assets/icons/social/google.svg';
import FormButton from 'components/FormButton';
import { RegisterFormState } from '.';
import { trackSegmentEvent } from 'app/utils';
import { SOURCE, ACTION } from 'app/analytics';
import { useState } from 'react';
import FormInput from 'components/FormInput';
import { addProtocolIfNotPresent } from 'utils/addProtocolIfNotPresent';

interface GoogleSignUpFlowProps {
  setFormState: (state: RegisterFormState) => void;
  signInOrRegisterWithGoogle: (website?: string) => Promise<void>;
}

export function GoogleSignUpFlow({
  setFormState,
  signInOrRegisterWithGoogle,
}: GoogleSignUpFlowProps) {
  const [website, setWebsite] = useState<string>('');

  // Register with Google account, or sign in if account already exists
  async function registerWithGoogle(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    // Pass the website so we can save it to the user's Firestore doc after the Google auth flow
    await signInOrRegisterWithGoogle(website);

    trackSegmentEvent(SOURCE.SIGNUP_FORM, ACTION.CLICK, 'register_with_google_button');
  }

  return (
    <>
      <form className="sign-up-form" onSubmit={registerWithGoogle}>
        <p style={{ textAlign: 'left' }}>
          Almost there! Please enter your company website to get started with Rye 👇
        </p>

        <FormInput
          className="sign-up-input"
          type="url"
          placeholder="Website"
          value={website}
          onBlur={() => setWebsite(addProtocolIfNotPresent(website))}
          onChange={setWebsite}
          required
        />

        <FormButton type="submit" className="sign-up-google">
          <GoogleIcon /> Complete Google sign-up
        </FormButton>
      </form>

      <a className="back-link" onClick={() => setFormState(RegisterFormState.INITIAL)}>
        &#8592; Back to email sign-up
      </a>
    </>
  );
}
