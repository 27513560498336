import { EnvSchemaTypes, env } from './env';

// Link to GCS with product seed data for developers to download
export const PRODUCT_CSV_URL =
  env.REACT_APP_NODE_ENV === 'production'
    ? 'https://storage.googleapis.com/rye-products/product-seed-data.csv'
    : 'https://storage.googleapis.com/staging_rye-products/product-seed-data.csv';

export enum Routes {
  Account = '/account',
  Dashboard = '/dashboard',
  Requests = '/requests',
  Graphql = '/graphql',
  Resources = '/resources',
  Affiliate = '/affiliate-disclosure',
  Login = '/login',
  Register = '/register',
  Reset = '/reset',
  Orders = '/orders',
  CreateOrder = '/orders/new',
  Billing = '/billing',
  Merchants = '/merchants',
  Order = '/orders/:orderId',
  Home = '/',
  All = '*',
}

export interface PromoNotification {
  id: string;
  title: string;
  link: string;
  startedAt: number;
  finishedBy: number;
  displayCount: number;
}

export const API_KEY_PREFIX = (
  {
    // Re-use staging env prefix in dev, as dev environment isn't public
    development: 'RYE/staging',
    production: 'RYE/production',
    staging: 'RYE/staging',
  } satisfies Record<EnvSchemaTypes['REACT_APP_NODE_ENV'], string>
)[env.REACT_APP_NODE_ENV];

const gqlEndpoints: Record<EnvSchemaTypes['REACT_APP_NODE_ENV'], string> = {
  development: 'http://localhost:3000/v1/query',
  staging: 'https://staging.graphql.api.rye.com/v1/query',
  production: 'https://graphql.api.rye.com/v1/query',
};

const adminEndpoints: Record<EnvSchemaTypes['REACT_APP_NODE_ENV'], string> = {
  development: 'http://localhost:3095/v1/query',
  staging: 'https://admin-api-l46hfxmk6q-uc.a.run.app/v1/query',
  production: 'https://admin-api-ggymj6kjkq-uc.a.run.app/v1/query',
};

const config = {
  external: {
    gql: gqlEndpoints[env.REACT_APP_NODE_ENV],
    adminGql: adminEndpoints[env.REACT_APP_NODE_ENV],
    doc: {
      home: 'https://docs.rye.com',
      tutorial: 'https://tutorial.rye.com',
      getStartedDocs: 'https://docs.rye.com/get-started/adding-products/overview',
      demo: 'https://search.rye.com',
      webhookUpdate: 'https://docs.rye.com/webhook-updates',
      quickStart: 'https://docs.rye.com/quick-start',
      backendOrdering: 'https://docs.rye.com/placing-orders-directly-from-the-backend',
      ryePay: 'https://docs.rye.com/rye-pay',
      configureAmazonGuide: 'https://docs.rye.com/amazon-business',
      jwtDocs: 'https://docs.rye.com/jwt-authentication',
    },
    terms: 'https://rye.com/terms-of-service',
    privacyPolicy: 'https://rye.com/privacy-policy',
    scheduleCall: 'https://calendly.com/d/gqr-rrb-v94/discovery-intro-call',
    ipService: 'https://api.ipify.org',
  },
  social: {
    twitter: 'https://twitter.com/rye',
    github: 'https://github.com/rye-com',
    linkedin: 'https://www.linkedin.com/company/rye/',
  },
  routesMap: [
    { title: 'Account', path: Routes.Account },
    { title: 'Requests', path: Routes.Requests },
    { title: 'GraphQL', path: Routes.Graphql },
    { title: 'Developer Resources', path: Routes.Resources },
    { title: 'Orders', path: Routes.Orders },
    { title: 'Order details', path: Routes.Order },
    { title: 'Dashboard', path: Routes.Dashboard },
    { title: 'Billing', path: Routes.Billing },
    { title: 'Merchants', path: Routes.Merchants },

    { title: 'Affiliate disclosure', path: Routes.Affiliate },

    { title: 'Login', path: Routes.Login },
    { title: 'Register', path: Routes.Register },
    { title: 'Reset', path: Routes.Reset },

    { title: 'Home', path: Routes.Home },
    { title: 'Error', path: Routes.All },
  ],
  routeDefaultHome: Routes.Dashboard,
  routesNav: [
    Routes.Dashboard,
    Routes.Graphql,
    Routes.Account,
    Routes.Requests,
    Routes.Resources,
    Routes.Orders,
    Routes.Merchants,
    Routes.Billing,
  ],
  promoNotifications: [
    {
      id: 'wehbooks',
      title: 'Please fill up Webhook URL!',
      link: '/account',
      displayCount: 0,
    },
  ] as PromoNotification[],
};

export enum IFRAME_HEADERS {
  AUTHORIZATION = 'Authorization',
  RYE_SHOPPER_IP = 'Rye-Shopper-IP',
  RYE_DEBUG = 'Rye-Debug',
}

// The REACT_APP_NODE_ENV set at build time, with first letter capitalized
export const ENV_TITLE_CASE =
  env.REACT_APP_NODE_ENV.charAt(0).toUpperCase() + env.REACT_APP_NODE_ENV.slice(1);

export const getSwitchLink = () => {
  switch (env.REACT_APP_NODE_ENV) {
    case 'staging':
    case 'development':
      return 'https://console.rye.com';
    default:
      return 'https://staging.console.rye.com';
  }
};

// Helper for our link that allows the user to switch environments
export const ENV_SWITCH_DETAILS = {
  LINK: getSwitchLink(),
  OTHER_ENV: env.REACT_APP_NODE_ENV === 'production' ? 'staging' : 'production',
};

export default config;
