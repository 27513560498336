import styled from 'styled-components';

export const BillingPeriodContent = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const BalanceContent = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const Label = styled.div`
  color: #4ed558;
  font-size: 20px;
  font-weight: 300;
`;
