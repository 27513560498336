import { useEffect, useState } from 'react';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import FormRequestBlock from 'components/FormRequestBlock';
import { useAppSelector } from 'app/store';
import { selectUserInfo, selectUserInfoLoading } from 'app/store/user';
import { getSpreedlyHeader, trackSegmentEvent } from 'app/utils';
import FormInput from 'components/FormInput';

import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';

import './style.scss';
import { ACTION, SOURCE } from 'app/analytics';
import config from 'config/constants';
import { ENV_TITLE_CASE } from 'config/constants';

const SpreedlyConfig = () => {
  const { spreedly } = useAppSelector(selectUserInfo) || {};
  const isUserInfoLoading = useAppSelector(selectUserInfoLoading);
  const [formLoading, setFormLoading] = useState(!spreedly);
  const [isCopiedToken, setIsCopiedToken] = useState(false);

  if (!spreedly?.envToken && !spreedly?.envSecret) {
    return <></>;
  }

  const handleOnClickCopyToken = () => {
    navigator.clipboard.writeText(getSpreedlyHeader(spreedly.envToken, spreedly.envSecret));
    setIsCopiedToken(true);
    setTimeout(() => setIsCopiedToken(false), 1000);
    trackSegmentEvent(SOURCE.SPREEDLY_CONFIG_MODULE, ACTION.CLICK, 'copy_spreedly_token_button');
  };

  const handleOnSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    setFormLoading(!spreedly && isUserInfoLoading);
  }, [spreedly, isUserInfoLoading]);

  return (
    <FormRequestBlock
      styles={{ form: { flexDirection: 'column', gap: '10px' } }}
      title={`${ENV_TITLE_CASE} Payment Gateway Headers`}
      text={
        <>
          <p>
            This header is used to tokenize credit cards and generate tokens for the submitCart
            mutation when following our{' '}
            <a
              href={config.external.doc.ryePay}
              target="_blank"
              aria-label="Link to backend ordering guide"
            >
              backend ordering guide
            </a>
            .
          </p>
          <p>
            <b>Note:</b> You will not need this header if you are using the{' '}
            <a href={config.external.doc.ryePay} target="_blank" aria-label="Link to Rye Pay docs">
              Rye Pay library
            </a>
            , as it looks up these gateway headers automatically.
          </p>
        </>
      }
      loading={formLoading}
      onSubmit={handleOnSubmit}
    >
      <div className="spreedly-config-form">
        <KeyIcon className="api-key-icon" title="API Key Header" />
        <FormInput
          id="heap-input-spreedly-config-env-token"
          className="spreedly-config-input"
          type="text"
          readOnly={true}
          value={getSpreedlyHeader(spreedly.envToken, spreedly.envSecret)}
        />

        <button
          title="Copy Spreedly Environment Token"
          className="spreedly-config-copy"
          onClick={handleOnClickCopyToken}
        >
          {isCopiedToken ? <CheckIcon /> : <CopyIcon />}
        </button>
      </div>
    </FormRequestBlock>
  );
};

export default SpreedlyConfig;
