import './styles.scss';

interface ShippingInfoDetailsCardValue {
  name: string;
  streetAddress: string;
  location: string;
  country: string;
}

export const ShippingInfoDetailsCard = (props: ShippingInfoDetailsCardValue) => {
  return (
    <div className="details-card">
      <div className="details-card-header">Shipping info</div>
      <div className="details-row">
        <div className="details-row-title">Name</div>
        <div className="details-row-data">{props.name}</div>
      </div>
      <div className="details-row">
        <div className="details-row-title">Street Address</div>
        <div className="details-row-data">{props.streetAddress}</div>
      </div>
      <div className="details-row">
        <div className="details-row-title">Location</div>
        <div className="details-row-data">{props.location}</div>
      </div>
      <div className="details-row">
        <div className="details-row-title">Country</div>
        <div className="details-row-data">{props.country}</div>
      </div>
    </div>
  );
};
