import { ReactComponent as PreloaderIcon } from 'assets/icons/preloader.svg';

import './style.scss';

export enum RyeLogoSizeCLass {
  small = 'small-preloader',
  micro = 'micro-preloader',
}

interface RyeLogoProps {
  size?: RyeLogoSizeCLass;
}

const RyeLogo = ({ size }: RyeLogoProps) => {
  return <PreloaderIcon className={`preloader ${size}`} />;
};

export default RyeLogo;
