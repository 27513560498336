import { useCallback, useSyncExternalStore } from 'react';

export function useLocalStorage<T>(
  key: string,
  parse: (value: string) => T,
  stringify: (value: T) => string,
): [T | null, (value: T | null) => void] {
  const currentValue = useSyncExternalStore(
    (onStoreChange) => {
      window.addEventListener('storage', onStoreChange);
      return () => window.removeEventListener('storage', onStoreChange);
    },
    () => {
      const storedValue = window.localStorage.getItem(key);
      return storedValue;
    },
    () => null,
  );

  const setState = useCallback(
    (nextValue: T | null) => {
      if (nextValue === null) {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, stringify(nextValue));
      }
    },
    [key],
  );

  return [currentValue ? parse(currentValue) : null, setState];
}
