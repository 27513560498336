import FormButton from 'components/FormButton';
import { OrderDetailsCardTable } from 'components/Orders/OrderDetailsCards/OrderDetailsCardTable/orderDetailsCardTable';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';

export const OrderDetails = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  return (
    <div className="order-details-main-div">
      <div className="order-details-header">
        <div className="order-details-buttons-row">
          <FormButton
            className="order-details-back-button order-details-buttons-row"
            onClick={() => navigate(-1)}
          >
            <LeftArrowIcon className="order-details-left-arrow-icon" />
            <h1>Order Details</h1>
          </FormButton>
        </div>
        {/*<div className="order-details-buttons-row">*/}
        {/*  <FormButton className="order-details-button">Cancel order</FormButton>*/}
        {/*  <FormButton className="order-details-button" contrast>*/}
        {/*    Create return*/}
        {/*  </FormButton>*/}
        {/*</div>*/}
      </div>
      {orderId && <OrderDetailsCardTable orderId={orderId} />}
    </div>
  );
};
