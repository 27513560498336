import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import RyeLogo from 'components/Logo';
import { defaultLoginRedirectFlow, useQuery } from 'routes';
import { ReactComponent as GoogleIcon } from 'assets/icons/social/google.svg';
import config, { Routes } from 'config/constants';
import { trackSegmentEvent } from 'app/utils';
import { ACTION, SOURCE } from 'app/analytics';
import Separator from 'components/Separator';
import FormButton from 'components/FormButton';
import FormInput from 'components/FormInput';

import './style.scss';

const LoginPage = () => {
  const navigate = useNavigate();
  const queryRedirect = useQuery().get('redirect');
  const queryEmail = useQuery().get('email');
  const [email, setEmail] = useState(queryEmail || '');
  const [password, setPassword] = useState('');
  const { user, error, loading, signInOrRegisterWithGoogle, logInWithEmailAndPassword } = useAuth();
  const { search } = useLocation();
  const registerLink = search ? `${Routes.Register}${search}` : Routes.Register;

  const onLoginWithEmail = (e: React.SyntheticEvent) => {
    e.preventDefault();
    logInWithEmailAndPassword(email, password);
    trackSegmentEvent(SOURCE.LOGIN_FORM, ACTION.CLICK, 'login_with_email_button');
  };

  const onLoginWithGoogle = (e: React.SyntheticEvent) => {
    e.preventDefault();
    signInOrRegisterWithGoogle();
    trackSegmentEvent(SOURCE.LOGIN_FORM, ACTION.CLICK, 'login_with_google_button');
  };

  useEffect(() => {
    const fallback = () => {
      if (user) {
        navigate(config.routeDefaultHome);
        return;
      }
    };

    defaultLoginRedirectFlow(navigate, loading, user, queryRedirect, fallback);
  }, [user, loading, navigate, queryRedirect]);

  useEffect(() => {
    error && alert(error);
  }, [error]);

  if (loading || (user && queryRedirect)) return null;

  return (
    <div className="sign-in">
      <div className="sign-in-container">
        <RyeLogo to={Routes.Login} />
        <div className="sign-in-register-link">
          New to Rye? <Link to={registerLink}>Sign up</Link>
        </div>
        <FormButton blank className="sign-in-google" onClick={onLoginWithGoogle}>
          <GoogleIcon /> Sign in with Google
        </FormButton>

        <Separator />

        <form className="sign-in-form" onSubmit={onLoginWithEmail}>
          <FormInput
            className="sign-in-input"
            placeholder="E-mail Address"
            type="email"
            name="email"
            value={email}
            onChange={setEmail}
            required
          />
          <FormInput
            className="sign-in-input"
            placeholder="Password"
            type="password"
            name="password"
            value={password}
            onChange={setPassword}
            required
          />
          <div className="sign-in-reset">
            <Link to={Routes.Reset}>Forgot password?</Link>
          </div>
          <FormButton type="submit">Sign in with email</FormButton>
        </form>

        <small className="sign-in-terms">
          By clicking “Continue with Google/Email” above, you acknowledge that you have read and
          understood, and agree to Rye's{' '}
          <a href={config.external.terms} target="_blank">
            Terms and Conditions
          </a>{' '}
          and{' '}
          <a href={config.external.privacyPolicy} target="_blank">
            Privacy Policy
          </a>
          .
        </small>
      </div>
    </div>
  );
};

export default LoginPage;
