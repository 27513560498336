import { cn } from 'lib/utils';
import { Image } from './styles';
import { useQuery } from '@apollo/client';

import {
  getRequestAmazonProductInputById,
  getRequestShopifyProductInputById,
  REQUEST_AMAZON_PRODUCT_BY_ID,
  REQUEST_SHOPIFY_PRODUCT_BY_ID,
} from 'app/graphql';
import { graphQLClient } from 'app/graphql/client';
import { useMemo } from 'react';
import { Skeleton } from 'components/ui/skeleton';
import {
  RequestAmazonProductByIdQuery,
  RequestShopifyProductByIdQuery,
} from 'app/graphql/generated/cart/graphql';

import AmazonLogo from 'assets/images/amazon-logo.jpg';
import ShopifyLogo from 'assets/images/shopify-logo.webp';

type Props = {
  productId: string;
  marketplace: 'amazon' | 'shopify';
  aspectRatio?: 'portrait' | 'square';
  width?: number | string;
  height?: number | string;
  className?: string;
  variantId?: string;
};

export function ProductCard({
  productId,
  className,
  width,
  height,
  marketplace,
  variantId,
}: Props) {
  const { loading, data } = useQuery<
    RequestShopifyProductByIdQuery | RequestAmazonProductByIdQuery
  >(marketplace === 'amazon' ? REQUEST_AMAZON_PRODUCT_BY_ID : REQUEST_SHOPIFY_PRODUCT_BY_ID, {
    variables:
      marketplace === 'amazon'
        ? getRequestAmazonProductInputById(productId)
        : getRequestShopifyProductInputById(productId),
    skip: !productId || graphQLClient.authToken === null,
  });

  const product = data?.productByID;

  const productUrl = useMemo(() => {
    if (product && 'storeCanonicalURL' in product) {
      return `${product.storeCanonicalURL}${product.url}`;
    }
    return product?.url;
  }, [product]);

  if (loading) {
    return (
      <div className="flex flex-col space-y-3">
        <Skeleton className="aspect-[3/4] w-[250px]" />
        <Skeleton className="h-4 w-24" />
        <Skeleton className="h-4 w-[250px]" />
      </div>
    );
  }

  let logo = ShopifyLogo;
  if (marketplace === 'amazon') {
    logo = AmazonLogo;
  }

  return (
    <div className={cn('space-y-3', className)}>
      <div className="w-full overflow-hidden rounded-md">
        <Image
          src={product?.images?.[0]?.url?.toString() ?? logo}
          alt={product?.title}
          width={width}
          height={height}
          className={cn('h-auto object-cover transition-all hover:scale-105', 'aspect-[3/4]')}
        />
      </div>
      <div className="space-y-1 text-sm">
        <h3 className="line-clamp-2 text-ellipsis whitespace-normal font-medium leading-none">
          {product?.title}{' '}
          {variantId && <span className="text-muted-foreground">({variantId})</span>}
        </h3>
        <a
          href={productUrl}
          target="_blank"
          className="line-clamp-1 whitespace-normal text-xs text-muted-foreground"
        >
          {productUrl}
        </a>
      </div>
    </div>
  );
}
