import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import AmazonBusiness from 'components/FormRequestBlock/AmazonBusiness';
import ApiKey from 'components/FormRequestBlock/ApiKey';
import MarginAmount from 'components/FormRequestBlock/MarginAmount';
import SpreedlyConfig from 'components/FormRequestBlock/SpreedlyConfig';
import WebhooksUrl from 'components/FormRequestBlock/WebhooksUrl';
import JWTValidationKey from 'components/FormRequestBlock/JWTValidationKey';

import './style.scss';
import AmazonBusinessGroup from 'components/FormRequestBlock/AmazonBusinessGroup';
import { AmazonBusinessType } from 'app/graphql/generated/admin/graphql';

const Page = () => {
  const { spreedly, amazonBusinessConfig, uid } = useAppSelector(selectUserInfo) || {};

  return (
    <div className="account-container">
      <h2 className="section-header">Rye API Authentication</h2>
      <ApiKey />
      {spreedly && <SpreedlyConfig />}

      <h2 className="section-header">JWT Authentication</h2>
      <JWTValidationKey />

      <h2 className="section-header">Webhooks</h2>
      <WebhooksUrl />

      <h2 className="section-header">Margin</h2>
      <MarginAmount />

      {amazonBusinessConfig?.businessType === AmazonBusinessType.ThirdParty && uid && (
        <>
          <h2 className="section-header">Amazon Business</h2>
          <AmazonBusiness amazonBusinessConfig={amazonBusinessConfig} userId={uid} />
        </>
      )}

      {amazonBusinessConfig?.businessType === AmazonBusinessType.GroupAccount && uid && (
        <>
          <h2 className="section-header">Amazon Business</h2>
          <AmazonBusinessGroup amazonBusinessConfig={amazonBusinessConfig} userId={uid} />
        </>
      )}
    </div>
  );
};

export default Page;
