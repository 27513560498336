import StoreUrlRequest from 'components/FormRequestBlock/StoreUrl';
import ProductUrlRequest from 'components/FormRequestBlock/ProductUrl';
import SearchResultsTable from 'components/SearchResultsTable';
import { Flex, Progress } from 'antd';

import './style.scss';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  fetchProductRequestCount,
  fetchStoreRequestCount,
  selectProductRequestCount,
  selectStoreRequestCount,
} from 'app/store/inventoryRequests';
import { selectUserInfo } from 'app/store/user';
import { useEffect, useMemo } from 'react';

const DEFAULT_UNIQUE_STORE_REQUEST_LIMIT = 10;
const DEFAULT_UNIQUE_PRODUCT_REQUEST_LIMIT = 300;
const UNLIMITED_REQUESTS = -1;

const Page = () => {
  const dispatch = useAppDispatch();

  const storeRequestCount = useAppSelector(selectStoreRequestCount) || 0;
  const productRequestCount = useAppSelector(selectProductRequestCount) || 0;

  const user = useAppSelector(selectUserInfo);

  const storeLimit = useMemo(() => {
    if (user?.inventoryRequestLimits?.uniqueStoreRequests === UNLIMITED_REQUESTS) {
      return UNLIMITED_REQUESTS;
    }
    return user?.inventoryRequestLimits?.uniqueStoreRequests ?? DEFAULT_UNIQUE_STORE_REQUEST_LIMIT;
  }, [user]);

  const storeRequestCountPercentage = (storeRequestCount / storeLimit) * 100;

  const productLimit = useMemo(() => {
    if (user?.inventoryRequestLimits?.uniqueProductRequests === UNLIMITED_REQUESTS) {
      return UNLIMITED_REQUESTS;
    }
    return (
      user?.inventoryRequestLimits?.uniqueProductRequests ?? DEFAULT_UNIQUE_PRODUCT_REQUEST_LIMIT
    );
  }, [user]);

  const productRequestCountPercentage = (productRequestCount / productLimit) * 100;

  useEffect(() => {
    dispatch(fetchStoreRequestCount(user?.uid || ''));
    dispatch(fetchProductRequestCount(user?.uid || ''));
  }, [user]);

  return (
    <div className="requests-container">
      <h2 className="section-header">Store Inventory</h2>
      <StoreUrlRequest />

      <h2 className="section-header">Product Inventory</h2>
      <ProductUrlRequest />

      <h2 className="section-header">Request History</h2>
      <Flex vertical gap="small" style={{ marginBottom: '20px' }}>
        <Flex className="request-count" gap="small">
          Stores Requested:{' '}
          <Progress
            style={{ width: '200px' }}
            strokeColor="rgb(78, 213, 88)"
            percent={storeRequestCountPercentage}
            trailColor="#f1f1f1"
            format={() => (
              <span className="progress-text">
                {storeRequestCount}/{storeLimit === UNLIMITED_REQUESTS ? '∞' : storeLimit}
              </span>
            )}
          />
        </Flex>
        <Flex className="request-count" gap="small">
          <div>Products Requested: </div>
          <Progress
            style={{ width: '200px' }}
            strokeColor="rgb(78, 213, 88)"
            percent={productRequestCountPercentage}
            trailColor="#f1f1f1"
            format={() => (
              <span className="progress-text">
                {productRequestCount}/{productLimit === UNLIMITED_REQUESTS ? '∞' : productLimit}
              </span>
            )}
          />
        </Flex>
      </Flex>
      <SearchResultsTable />
    </div>
  );
};

export default Page;
