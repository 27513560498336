import { ThemeMode } from 'providers/ThemeProvider';
import styled from 'styled-components';

export const InvoiceContent = styled.div`
  margin-top: 20px;
`;

export const InvoiceHeader = styled.header`
  padding: 16px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SummaryTitle = styled.h2<{ thememode: ThemeMode }>`
  color: ${({ thememode }) => (thememode === 'light' ? '#000000' : '#ffffff')};
  font-weight: 500;
  padding: 0;
  font-size: 20px;
`;

export const InvoiceItems = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  width: 100%;
  overflow: auto;
  max-height: 500px;
`;
export const InvoiceItem = styled.li`
  width: 100%;
`;
export const InvoiceItemContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const InvoiceItemDivider = styled.div<{ thememode?: ThemeMode }>`
  flex-grow: 1;
  border-bottom: 2px dashed
    ${({ thememode }) => (!thememode ? '#bbb' : thememode === 'light' ? '#000' : '#fff')};
  align-self: center;
  margin: 0 10px;
`;

export const TotalLabel = styled.div<{ thememode: ThemeMode }>`
  font-weight: 600;
  color: ${({ thememode }) => (thememode === 'light' ? '#000000' : '#ffffff')};
`;

export const CloseButton = styled.button<{ thememode: ThemeMode }>`
  background-color: ${({ thememode }) => (thememode === 'light' ? 'rgba(0,0,0,1)' : '#3d3e4c')};
  color: ${({ thememode }) => (thememode === 'light' ? '#ffffff' : '#ffffff')};
  border-radius: 8px;
  width: 160px;
  padding: 12px 0;
  cursor: pointer;
  &:hover {
    background-color: ${({ thememode }) => (thememode === 'light' ? 'rgba(0,0,0,0.8)' : '#4b4d5e')};
  }
`;
