import { createNextState, createSlice } from '@reduxjs/toolkit';
import { getClientIp } from '../utils';
import { AppThunk, RootState } from './index';

/**
 * Reducers
 */
type ClientIpState = {
  clientIp: string | null;
};

const initialState: ClientIpState = {
  clientIp: null,
};

const clientIpSlice = createSlice({
  name: 'clientIp',
  initialState,
  reducers: {
    setClientIp: (state, action) => {
      return createNextState(state, (draft) => {
        draft.clientIp = action.payload;
      });
    },
  },
});

/**
 * Actions
 */
const { setClientIp } = clientIpSlice.actions;

export const fetchClientIp = (): AppThunk => async (dispatch) => {
  const clientIp = await getClientIp();
  dispatch(setClientIp(clientIp));
};

/**
 * Selectors
 */
export const selectClientIp = (state: RootState) => state.clientIp.clientIp;

export default clientIpSlice.reducer;
