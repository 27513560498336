import {
  orderBy as firebaseOrderBy,
  limit as firebaseLimit,
  where as firebaseWhere,
  getCountFromServer,
  collection,
  query,
} from 'firebase/firestore';

import { CollectionId, FirebaseCollection } from 'app/firebase';

const DEFAULT_ROWS_LIMIT = 1000;

enum InventoryRequestsType {
  Product = 'Product Inventory',
  Shop = 'Store Inventory',
}

export interface FirebaseRequestedAt {
  seconds: number;
  nanoseconds: number;
}

export interface InventoryRequestsModel {
  id: string;
  data: string;
  requestType: string;
  requestedAt: FirebaseRequestedAt;
}

export interface InventoryRequestsStoreModel extends InventoryRequestsModel {
  requestedAtUTS: number;
}

class InventoryRequestsCollection extends FirebaseCollection {
  constructor() {
    super(CollectionId.InventoryRequests);
  }

  fetchHistoryByUid = async (userUid: string): Promise<InventoryRequestsModel[]> => {
    const resp =
      (await this.list<InventoryRequestsModel>({
        collectionIdAddon: `/${userUid}/requests`,
        condition: firebaseWhere('id', '!=', null),
        // @TODO: allow to use list of [orderBy(...), orderBy(...)]
        // Please add index in Firebase for this collection
        orderBy: firebaseOrderBy('id', 'desc'),
        limit: firebaseLimit(DEFAULT_ROWS_LIMIT),
      })) || [];
    return resp.map((item) => item.data);
  };

  /** @deprecated not in used (example how to set data to Firebase) */
  addInventoryRequestByUid = async (
    userUid: string,
    inventoryURL: string,
    inventoryType: InventoryRequestsType,
  ) => {
    const data = {
      id: 'tester-example-id',
      requestType: inventoryType,
      requestedAt: { seconds: 0, nanoseconds: 0 },
      data: inventoryURL,
    };
    await this.add(data, `/${userUid}/requests`);
  };

  getStoreRequestsCount = async (userId: string) => {
    const collectionRef = collection(this.firebase.db, `${this.collectionId}/${userId}/requests`);
    const count = await getCountFromServer(
      query(collectionRef, firebaseWhere('requestType', '==', 'Shopify Store')),
    );

    return count.data().count;
  };

  getProductRequestsCount = async (userId: string) => {
    const collectionRef = collection(this.firebase.db, `${this.collectionId}/${userId}/requests`);
    const count = await getCountFromServer(
      query(
        collectionRef,
        firebaseWhere('requestType', 'in', ['Shopify Product', 'Amazon Product']),
      ),
    );

    return count.data().count;
  };
}

export const inventoryRequestsCollection = new InventoryRequestsCollection();
