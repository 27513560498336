import { ReactNode, SyntheticEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectDashboardButtonsState, updateOnboardingTasksCompleteStatus } from 'app/store/user';
import FormButton from 'components/FormButton';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as Check } from 'assets/icons/check-done.svg';

import './style.scss';
import { Card } from 'components/ui/card';

export enum StepName {
  ExploreDemo = 'exploreDemo',
  Tutorial = 'tutorialStep',
  ResourceDocs = 'resourceDocs',
  BookACall = 'bookACall',
  Community = 'joinCommunity',
}

export type AccordionItemProps = {
  stepName: StepName;
  title: string[];
  subtitle?: string;
  children?: React.ReactNode;
  isOpened?: boolean;
  duplicateButton?: boolean;
};

const AccordionItem = ({
  isOpened = false,
  duplicateButton = false,
  ...props
}: AccordionItemProps) => {
  const dispatch = useAppDispatch();
  const isDone = useAppSelector(selectDashboardButtonsState(props.stepName));
  const [isCollapsed, setIsCollapsed] = useState(!isOpened || isDone);
  const [changeHoverText, setChangeHoverText] = useState(false);

  const getTitle = (): ReactNode => {
    return props.title.map((titleItem) => <span key={titleItem}>{titleItem}</span>);
  };

  const handleChevronDownClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleButtonClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    if (!isDone && !isCollapsed) {
      setIsCollapsed(!isCollapsed);
    }

    dispatch(updateOnboardingTasksCompleteStatus(props.stepName, !isDone));
  };

  const button = (
    <FormButton
      className="accordion-item-button"
      contrast={isDone}
      onClick={(e) => handleButtonClick(e)}
      onMouseLeave={() => setChangeHoverText(isDone)}
    >
      <span className="accordion-item-button-text-by-default">
        {isDone ? (
          <span className="accordion-item-button-text-done">
            <Check /> Done
          </span>
        ) : (
          'Mark done'
        )}
      </span>
      <span className="accordion-item-button-text-on-hover">
        {isDone ? (
          changeHoverText ? (
            'Mark as not done'
          ) : (
            <span className="accordion-item-button-text-done">
              <Check /> Done
            </span>
          )
        ) : (
          'Mark done'
        )}
      </span>
    </FormButton>
  );

  return (
    <Card className="accordion-item-container">
      <div className="accordion-item-panel" onClick={() => handleChevronDownClick()}>
        <div className="accordion-item-title">
          <span className="accordion-item-main-title">{getTitle()}</span>
          {props.subtitle && <p>{props.subtitle}</p>}
        </div>
        <div className="accordion-item-controls">
          {button}
          <div
            className={isCollapsed ? 'accordion-item-open-icon' : 'accordion-item-open-icon opened'}
          >
            <ChevronDown />
          </div>
        </div>
      </div>
      {!isCollapsed && (
        <div className="accordion-item-content">
          {props.children}
          {duplicateButton && <div className="accordion-item-duplicate-button">{button}</div>}
        </div>
      )}
    </Card>
  );
};

export default AccordionItem;
