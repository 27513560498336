import { ReactNode } from 'react';

import { cn } from 'lib/utils';

export function MerchandiseCard({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) {
  return (
    <div className={cn('flex flex-1 rounded-lg border border-dashed p-4 shadow-sm', className)}>
      {children}
    </div>
  );
}
