'use client';

import { Cross2Icon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';

import { DataTableViewOptions } from './data-table-view-options';

import { DataTableFacetedFilter } from './data-table-faceted-filter';
import { Input } from './input';
import { Button } from './button';

export type Facets = {
  label: string;
  value: string;
  icon?: React.ComponentType<{
    className?: string;
  }>;
};

export type FacetOptions = Record<string, { emptyLabel?: string; options: Facets[] }>;

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  facets?: Facets[];
  disableFiltering?: boolean;
  facetOptions?: FacetOptions;
  toolbarItems?: React.ReactNode;
}

export function DataTableToolbar<TData>({
  table,
  disableFiltering,
  facets,
  facetOptions,
  toolbarItems,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        {!disableFiltering && (
          <Input
            placeholder="Filter tasks..."
            value={(table.getColumn('title')?.getFilterValue() as string) ?? ''}
            onChange={(event) => table.getColumn('title')?.setFilterValue(event.target.value)}
            className="h-8 w-[150px] lg:w-[250px]"
          />
        )}
        {facetOptions &&
          facets?.map(
            (facet) =>
              table.getColumn(facet.value) && (
                <DataTableFacetedFilter
                  key={facet.value}
                  column={table.getColumn(facet.value)}
                  title={facet.label}
                  emptyLabel={facetOptions[facet.value].emptyLabel}
                  options={facetOptions[facet.value].options}
                />
              ),
          )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <div className="flex gap-2">
        {toolbarItems}
        <DataTableViewOptions table={table} />
      </div>
    </div>
  );
}
