import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import userReducer from 'app/store/user';
import themeReducer from 'app/store/theme';
import inventoryRequestsReducer from 'app/store/inventoryRequests';
import checkoutRequestsReducer from 'app/store/checkoutRequests';
import clientIpReducer from 'app/store/clientIp';
import amazonBusinessAccountsReducer from 'app/store/amazonBusinessAccounts';

export const store = configureStore({
  reducer: {
    user: userReducer,
    theme: themeReducer,
    inventoryRequests: inventoryRequestsReducer,
    checkoutRequests: checkoutRequestsReducer,
    clientIp: clientIpReducer,
    amazonBusinessAccounts: amazonBusinessAccountsReducer,
  },
});

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
